import React, { useEffect, useRef } from "react";

import { IState, Stereotype } from "store/types";
import { IRulesProps } from "./common/types";
import { RuleComponent } from "./component";
import { RuleGroup } from "./group";
import { RuleTemplate } from "./RuleTemplate";

import { RuleSection } from "./RuleSection";
import { useSelector } from "react-redux";
import { RuleWrapper } from "components/divs/ruleWrapperDiv";
import { isScrolledIntoView } from "lib/dom";

export const RuleDispatcher = (props: IRulesProps) => {
  const { rule, level, index, siblings } = props;

  // Scroll to it when it's highligthed
  const component = useRef<any>(null);
  const template = useRef<any>(null);
  const group = useRef<any>(null);
  const section = useRef<any>(null);

  const previewedRuleId = useSelector((s: IState) => s.editorState.previewedRuleId);
  const stereotype = rule?.stereotype;
  const ruleId = rule?.id;
  const isPreviewed = !!previewedRuleId && previewedRuleId === ruleId;
  useEffect(() => {
    if (isPreviewed) {
      let scroller: any = null;
      switch (stereotype) {
        case Stereotype.RuleGroup:
          scroller = group.current;
          break;
        case Stereotype.RuleTemplate:
          scroller = template.current;
          break;
        case Stereotype.RuleComponent:
          scroller = component.current;
          break;
        case Stereotype.RuleSection:
          scroller = section.current;
          break;
      }
      if (scroller && !isScrolledIntoView(scroller)) {
        scroller?.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  }, [isPreviewed, stereotype]);

  // There may not be a rule at the start ...
  if (!rule) {
    return null;
  }

  const isLastChild = siblings === index + 1;

  switch (rule.stereotype) {
    case Stereotype.Rules:
      return (
        <>
          {rule.children.map((c, i) => (
            <RuleDispatcher {...props} key={c.id} rule={c} level={level} index={i} />
          ))}
        </>
      );
    case Stereotype.RuleSection:
      return (
        <RuleWrapper isPreviewed={isPreviewed} ref={section} level={0} isLastChild={isLastChild}>
          <RuleSection {...props} index={index} />
        </RuleWrapper>
      );
    case Stereotype.RuleGroup:
      return (
        <RuleWrapper isPreviewed={isPreviewed} ref={group} level={level} isLastChild={isLastChild}>
          <RuleGroup {...props} />
        </RuleWrapper>
      );
    case Stereotype.RuleTemplate:
      return (
        <RuleWrapper isPreviewed={isPreviewed} ref={template} level={0} isLastChild={isLastChild}>
          <RuleTemplate {...props} />
        </RuleWrapper>
      );
    case Stereotype.RuleComponent:
      return (
        <RuleWrapper isPreviewed={isPreviewed} ref={component} level={level} isLastChild={isLastChild}>
          <RuleComponent {...props} />
        </RuleWrapper>
      );
    default:
      throw new Error(`Invalid stereotype ${rule.stereotype} for rule ${rule.id}`);
  }
};
