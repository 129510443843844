import React from "react";

// import styled from "styled-components";
import startCase from "lodash/startCase";
import { FormControl, Grid } from "@mui/material";
import { PaddedLabel } from "../common/forms";
import { AreaOfStudy } from "lib/searchConstants";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import isBoolean from "lodash/isBoolean";
import { featureToggles } from "config/featureToggles";
import { getOrgUnitDescription } from "lib/orgUnits";

const getDisplayValue = (name: string, val: any) => {
  switch (name) {
    case "areaOfStudy":
      return val.map((v: string) => AreaOfStudy.find((aos) => aos.code === v)?.description || v).join(", ");
    case "level":
      return featureToggles.search ? val.join(", ") : `Levels ${val.join(", ")}`;
    case "owningOrg":
      const orgs = getOrgUnitDescription(val);
      return Array.isArray(orgs) ? orgs.join(", ") : orgs;
    default:
      return String(val);
  }
};

const isEmpty = (value: any) => {
  if (isArray(value)) {
    return value.length === 0;
  }
  if (isString(value)) {
    return !!value.trim();
  }
  if (isBoolean(value)) {
    return false;
  }
  return !value;
};

interface IMemoReadOnlyTextFieldProps {
  ariaLabel?: string;
  id?: string;
  value?: string;
}

const MemoReadOnlyTextField = React.memo(({ ariaLabel, id, value }: IMemoReadOnlyTextFieldProps) => (
  <div
    id={`${id}-text-field-wrapper`}
    style={{ height: "fit-content", width: "100%", border: `1px solid rgb(215, 215, 215)`, borderRadius: 4 }}
  >
    <p id={id} aria-label={ariaLabel} style={{ padding: "6px 8px", margin: "0px" }}>
      {value}
    </p>
  </div>
));

const MemoReadFormControl = React.memo((props: IMemoReadOnlyTextFieldProps) => (
  <FormControl fullWidth={true} variant="outlined">
    <MemoReadOnlyTextField {...props} />
  </FormControl>
));

export const DynamicSubjectSetQuery = ({ query }: { query: any }) => {
  return (
    <Grid container spacing={2}>
      {Object.keys(query).map((name) => {
        const id = `dynamic-set-${name}`;

        if (isEmpty(query[name]) || name === "year") {
          return null;
        }
        const value = getDisplayValue(name, query[name]);
        return (
          <Grid key={name} item xs={12} sm={6} md={4}>
            <PaddedLabel htmlFor={id}>{startCase(name)}</PaddedLabel>
            <MemoReadFormControl ariaLabel={name} value={value} id={id} />
          </Grid>
        );
      })}
    </Grid>
  );
};
