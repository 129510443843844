import { IMoveRuleState } from "store/types";

export const SET_RULE_MOVE = "moveRuleState/SET_RULE_MOVE";

export const startMove = (move: IMoveRuleState) => {
  return { type: SET_RULE_MOVE, payload: move };
};

export const cancelMove = () => {
  return { type: SET_RULE_MOVE, payload: null };
};
