import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, FormControlLabel, Fab, AppBar, useTheme } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Level, AreaOfStudy } from "lib/searchConstants";
import { MultiSelectField, EditorWrapper } from "../common";
import { IDSSQueryEditorFormState } from "./DSSQueryEditor";
import hash from "object-hash";
import identity from "lodash/identity";
import { useDispatch, useSelector } from "react-redux";
import { executeSubjectQuery } from "actions/subjectQueryResult";
import styled from "styled-components";
import { IState, ISubjectSearchSummary, RuleTargetType } from "store/types";
import { SubjectCard } from "../../searchResults/subjectCard/SubjectCard";
import { Switch } from "formik-mui";
import Spinner from "components/spinner";
import { AutocompleteMultiSelect } from "components/autocomplete/AutocompleteMultiSelect";
import SaveIcon from "@mui/icons-material/Save";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import { ResponsiveCenter } from "components/spinner/Center";
import { Theme } from "@mui/material/styles";
import { Aligned } from "components/divs/alignedDiv";
import { featureToggles } from "config/featureToggles";
import { SubjectSearchResults } from "containers/rules/searchResults/common/common";
import { sortBy } from "lodash";
import { formatLevel, parseLevelVlues } from "lib/level";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { getOrgUnitDefinition, OrgUnits } from "lib/orgUnits";

const useStyles = (theme: Theme) => ({
  appBar: {
    position: "relative",
  },
  closeButton: {
    background: "gray",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  adornment: {},
  icon: {
    fill: theme.palette.primary.contrastText,
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
});

const CheckboxControlLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: start;
  padding-left: 0;
  margin-left: 0;
`;

// assuming query.areaOfStudy will just be an array of codes e.g. ["FNCE", "ENEN", ...]
const areaOfStudyDescription = (value: string) => AreaOfStudy.find((aos) => aos.code === value)?.description || value;
const areaOfStudyLabel = (value: string) => {
  const areaOfStudy = AreaOfStudy.find((aos) => aos.code === value);
  return areaOfStudy ? `${areaOfStudy.description} (${areaOfStudy.code})` : value;
};
const areaOfStudyCodes = AreaOfStudy.map((aos) => aos.code);
const owningOrgCodes = featureToggles.search ? (OrgUnits as any[]).map((org) => org.code) : OrgUnits;
const owningOrgLabel = (value: string) => {
  if (featureToggles.search) {
    const orgUnit = getOrgUnitDefinition(value);
    return `${orgUnit?.code ?? "⚠️ Invalid unit"} - ${orgUnit?.description ?? value}`;
  }
  return value;
};
interface IProps {
  close: () => any;
}

export const DSSQueryEditorFormContent = ({ close }: IProps) => {
  const formik = useFormikContext<IDSSQueryEditorFormState>();
  const targetType = useSelector((s: IState) => s.targetType);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const { values, isValid, dirty, setFieldValue, handleSubmit } = formik;
  const theme = useTheme();
  const styles = useStyles(theme);

  const queryHash = useMemo(() => hash(values, { ignoreUnknown: true, unorderedArrays: true }), [values]);
  const dispatch = useDispatch();

  // Whenever the query changes - fire a request
  useEffect(() => {
    setSearchInProgress(true);

    const p = dispatch(executeSubjectQuery(values)) as any;
    if (Promise.resolve(p) === p) {
      p.finally(() => setSearchInProgress(false));
    } else {
      setSearchInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryHash, setSearchInProgress]);

  const onMultiSelectChange = useCallback(
    (valuesKey: keyof IDSSQueryEditorFormState) => (newValue: string[]) => {
      setSearchInProgress(true);
      setFieldValue(valuesKey, newValue);
    },
    [setFieldValue],
  );
  const subjectQueryResult = useSelector((s: IState) => s.subjectQueryResult?.result || []);
  // Sort subject results by code, followed by name
  // NOTE: results from Caps are limited to sorting by one field, currently code
  const uiSortedSubjectQueryResult = sortBy(subjectQueryResult, ["code", "name"]);
  const disableSubmit = !isValid || !dirty || (subjectQueryResult.length === 0 && !values.breadthCourseCode);

  const isSubject = targetType === RuleTargetType.Subject;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <AppBar sx={styles.appBar}>
          <ResponsiveCenter>
            <Toolbar sx={styles.toolbar}>
              <h2>Dynamic Subject List Finder</h2>
              <Aligned>
                <Fab variant="extended" onClick={close} aria-label="Close" sx={styles.closeButton}>
                  <CloseIcon />
                  &nbsp; Cancel
                </Fab>
                &nbsp; &nbsp;
                <Fab type="submit" variant="extended" aria-label="save" disabled={disableSubmit}>
                  <SaveIcon />
                  &nbsp; Save
                </Fab>
              </Aligned>
            </Toolbar>
          </ResponsiveCenter>
        </AppBar>
        <DialogContent>
          <ResponsiveCenter>
            <EditorWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <MultiSelectField
                    id="level-select"
                    name="level"
                    formValue={parseLevelVlues(values.level)}
                    allValues={Level}
                    placeholder="Select levels"
                    formatLabel={formatLevel}
                    onClear={() => setFieldValue("level", [])}
                    renderValue={(n) => `${n} levels selected`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={5}>
                  <AutocompleteMultiSelect
                    id="area-of-study-list"
                    options={areaOfStudyCodes}
                    formValue={values.areaOfStudy}
                    formatLabel={featureToggles.search ? areaOfStudyLabel : areaOfStudyDescription}
                    onChange={onMultiSelectChange("areaOfStudy")}
                    placeholder="Area of Study"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={5}>
                  <AutocompleteMultiSelect
                    id="owning-org-unit-list"
                    options={featureToggles.search ? owningOrgCodes : OrgUnits}
                    formValue={values.owningOrg}
                    formatLabel={featureToggles.search ? owningOrgLabel : identity}
                    onChange={onMultiSelectChange("owningOrg")}
                    placeholder="Owning Org Unit"
                  />
                </Grid>
                {!isSubject && (
                  <Grid item xs={12} sm={6} md={3}>
                    <CheckboxControlLabel
                      labelPlacement="end"
                      control={<Field component={Switch} type="checkbox" name="breadthCourseCode" color="primary" />}
                      label={
                        <LabelWithHint
                          label="Breadth Subjects?"
                          helpText="Selecting this will filter to subjects available as breadth in the course you are editing."
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <SubjectSearchResults>
                {searchInProgress && <Spinner loading={searchInProgress} />}
                {values.breadthCourseCode && (
                  <p>
                    Please note while your search query may return results in the Course Planning Tool, we are unable to
                    display the search results for your query because the <strong>Available for Breadth</strong> field
                    is not presently available for searching subjects while editing a course in the Rule Editor.
                  </p>
                )}
                {!searchInProgress && subjectQueryResult.length === 0 && !values.breadthCourseCode && (
                  <p>No matching subjects were found.</p>
                )}
                {!searchInProgress && subjectQueryResult.length > 0 && (
                  <Grid container spacing={1}>
                    {(
                      (featureToggles.search
                        ? uiSortedSubjectQueryResult
                        : subjectQueryResult) as ISubjectSearchSummary[]
                    ).map((s, i) => (
                      <Grid item key={`${s.code}-${i}`} xs={12} sm={6} md={4}>
                        <SubjectCard subject={s} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </SubjectSearchResults>
            </EditorWrapper>
          </ResponsiveCenter>
        </DialogContent>
      </form>
    </>
  );
};
