import { GET_TEMPLATES } from "actions/templates";
import initialState from "store/initialState";
import { fulfilled } from "lib/promiseMiddlewareTypes";

const templates = (state = initialState.templates, action: any) => {
  switch (action.type) {
    case fulfilled(GET_TEMPLATES):
      return action.payload.templates;
    default:
      return state;
  }
};

export default templates;
