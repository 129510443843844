import React from "react";
import { ISubjectSearchSummary } from "store/types";
import { Card, CardHeader, CardContent, Chip, Tooltip, useTheme } from "@mui/material";
import { CardSubheading, CardName, OwningOrg, useCardStyles } from "../common/common";
import SchoolIcon from "@mui/icons-material/School";

export interface IProps {
  subject: ISubjectSearchSummary;
}

export const SubjectCard = (props: IProps) => {
  const { subject } = props;
  const theme = useTheme();
  const styles = useCardStyles(theme);
  const areas = subject.areaOfStudy.map((aos) => aos.description).join(",");

  // CAPS doesn't return availabilities on search
  const availabilities: string[] = [];
  // const availabilities = uniq((subject.availability || []).map((a) => a.study_period));

  return (
    <Card sx={styles.card} elevation={2}>
      <CardHeader color="primary" title={areas} titleTypographyProps={{ variant: "inherit" }} sx={styles.cardHeader} />
      <CardContent sx={styles.cardContent}>
        <CardSubheading>
          <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
        </CardSubheading>
        <div>
          <CardName>{subject.name}</CardName>
        </div>
        {subject.owningOrg && (
          <Tooltip placement="top-start" title="Owning Organisation">
            <OwningOrg>
              <SchoolIcon /> &nbsp; {subject.owningOrg}
            </OwningOrg>
          </Tooltip>
        )}
        {availabilities.map((a) => (
          <React.Fragment key={a}>
            <Chip label={a} /> &nbsp;
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
};
