import { IUsageChain } from "containers/rules/sharedRules/types";

export interface IItem {
  name: string;
}
export enum Stereotype {
  Rules = "Rules",
  RuleSection = "RuleSection",
  RuleTemplate = "RuleTemplate",
  RuleComponent = "RuleComponent",
  RuleGroup = "RuleGroup",
}

export enum Operator {
  OR = "OR",
  XOR = "XOR",
  AND = "AND",
  NOT = "NOT",
  IF = "IF",
}

export enum BreadthDisciplineScope {
  Breadth = "Only Breadth",
  Discipline = "Only Discipline",
  Breadth_and_Discipline = "Both Breadth and Discipline",
}

export enum ParameterName {
  Min = "minimum",
  Max = "maximum",
  Label = "label",
  Operator = "operator",
  Text = "text",
  LinkText = "linkText",
  Concurrency = "concurrency",
  DynamicSetQuery = "dynamicQueryAttributes",
  StaticSetSubjects = "staticSubjectReferences",
  BreadthDisciplineScope = "breadthOrDisciplineScope",
  SubjectOmissions = "subjectOmissions",
}

enum TransformType {
  Text = "text",
  Html = "html",
}

export enum SubjectSetType {
  Static = "Static",
  Dynamic = "Dynamic",
}

export interface ITag {
  name: string;
  label: string;
}

interface ITransform {
  stage: number;
  type: TransformType;
  outputField: string;
  template: string;
}

export interface IFormat {
  id: string;
  name: string;
  outputField: string;
  samples?: string[];
  transforms: ITransform[];
}

export enum ParamType {
  string = "string",
  number = "number",
  enum = "enum",
  array = "array",
  composite = "composite",
}

export interface IParameter {
  name: string;
  label: string;
  value: string | string[];
  type: ParamType;
  enumValues?: string[];
  arrayType?: string;
  compositeParameters?: any[];
}

export interface IContext {
  parameters?: IParameter[];
}
export interface ITransformOutput {
  stage: number;
  data: any;
  context: IContext;
}

export interface IRuleComponent {
  id: string;
  stereotype: Stereotype;
  tags: ITag[];
  type: string;
  formats: IFormat[];
  selectedOrDefaultFormat: string;
  valid?: boolean;
  generated?: ITransformOutput[];
  children: IRuleComponent[];
  parameters: IParameter[];
  childrenReferences: string[];
  externalReferences?: string[];
  sharedComponents: IRuleComponent[];
}

export interface IMenuRuleComponent extends IRuleComponent {
  index?: number;
}

export interface ISubjectSearchSummary {
  areaOfStudy: [{ area: string; description: string }];
  area: string;
  description: string;
  code: string;
  level: number;
  name: string;
  owningOrg: string;
  points: number;
  recordId: string;
  studyType: string;
  supportingOrg: string;
  version: string;
}

export interface IRuleText {
  section: string;
  html: string;
}

export type IGeneratedRuleText = IRuleText | string;

export interface ISubject {
  rules: IRuleComponent;
  id: string;
  recordId: string;
  version: string;
  areaOfStudy: any[];
  availability: any[];
  points: 25;
  overview: string;
  owningOrg: string;
  ruleTextIdentified: IGeneratedRuleText[];
  effectiveYear: string;
  publishedYears: string;
  code: string;
  name: string;
  level: string;
  lastUpdateTS: string;
  isLegacy?: string;
  discontinue: string;
  newlyCreatedRules?: boolean | null;
}

export interface IMMS {
  versionId: string;
  rules: string;
  code: string;
  name: string;
  version: string;
  recordId: string;
  points: string;
  type: string;
  courseParentRecordId: string;
  parentCourse: {
    code: string;
    versionId: string;
    points: number;
    owningOrg: string;
    recordId: string;
    name: string;
  };
}

export interface ICourseSummary {
  recordId: string;
  code: string;
  name: string;
}

export interface IComponentSummary {
  code: string;
  name: string;
  subjectSetId: string;
}

export interface IQualificationType {
  aqfLevel: string;
}

export enum QualificationLevel {
  DIPLOMA = "5",
  BACHELOR = "7",
  MASTERS = "9",
}

export interface ICourse {
  recordId: string;
  version: string;
  versionId: string;
  ruleTextIdentified: IGeneratedRuleText[];
  code: string;
  name: string;
  effectiveYear: string;
  qualificationType?: IQualificationType;
  rules: IRuleComponent;
  isLegacy?: string;
  newlyCreatedRules?: boolean | null;
  points: number;
  componentReferences?: IComponentSummary[];
}

export interface IComponent {
  recordId: string;
  version: string;
  ruleTextIdentified: IGeneratedRuleText[];
  code: string;
  name: string;
  effectiveYear: string;
  rules: IRuleComponent;
  isLegacy?: string;
  newlyCreatedRules?: boolean | null;
  parentCourse: {
    code: string;
    versionId: string;
    points: number;
    owningOrg: string;
    recordId: string;
    name: string;
  };
}

export interface IQueryParams {
  sys_id?: string;
  sessionId?: string;
  retoken?: string;
}

export interface IExpandedSections {
  [sectionId: string]: boolean;
}
export interface IEditorState {
  expandedSections?: IExpandedSections | null;
  menuOpenForRuleId?: string | null;
  warningDialogOpenForRuleId?: string | null;
  subjectSetUsageChains?: IUsageChain[][];
  subjectFinderOpenForRuleId?: string | null;
  editedRuleId?: string | null;
  previewedRuleId?: string | null;
  addRuleToRuleId?: string | null;
  addGroupToRuleId?: string | null;
  addGroupToSectionId?: string | null;
  addGroupToSectionType?: string | null; // for adding top level RuleGroup
  addGroupToSectionIndex?: number | null; // for adding top level RuleGroup
  addSharedStaticSubjectSet?: boolean;
  addSharedDynamicSubjectSet?: boolean;
}

export interface IToken {
  token?: string;
  sessionId?: string;
}
export interface ISnackBarState {
  type: "error" | "success";
  message: string;
  autoHideDuration: number;
}

export interface IComponentSearchSummary {
  id: string;
  code: string;
  version: string;
  versionId: string;
  recordId: string;
  name: string;
  type: string;
  publishedYears: string;
  lastUpdateTS: string;
  owningOrg: string;
  points: number;
  isLegacy: boolean;
  courseParentRecordId: string;
  parentCourse: {
    versionId: string;
    code: string;
    name: string;
    recordId: string;
    points: string;
    owningOrg: string;
  };
}
export interface ISearchResult {
  result: ISubjectSearchSummary[] | IComponentSearchSummary[];
  searchTimestamp: number;
}

export interface IMoveRuleState {
  ruleId: string;
  label: string;
  stereotype: Stereotype;
  copy?: boolean; // Do we copy or cut
}

export interface ISessionError {
  code: number;
  description?: string;
  statusText?: string;
}

export interface IPreviewDrawerState {
  open: boolean;
  width: string;
}

export interface IDependent {
  code: string;
  isCoreCompulsory: boolean;
  ruleId: string;
  ruleStereoType: string;
  ruleType: string;
  sys_id: string;
  title: string;
  version: string;
}

export interface IState {
  loading: boolean;
  submittingChanges: boolean;
  capsObject: null | ICourse | ISubject | IComponent;
  parentSharedComponents: IRuleComponent[];
  dependents: IDependent[];

  moveRuleState: null | IMoveRuleState;
  subjectDetails: { [id: string]: ISubject };
  mmsDetails: { [id: string]: IMMS };
  courses: { [id: string]: ICourseSummary };
  tokenValidated: null | IToken;
  editorState: IEditorState;
  sysId: string | null;
  targetType: RuleTargetType | null;
  templates: IRuleComponent[];
  snackBarState: ISnackBarState | null;
  optimisticUpdate: IRuleComponent | null;
  subjectQueryResult: ISearchResult | null;
  mmsQueryResult: ISearchResult | null;
  sessionError?: ISessionError | null;
  previewDrawerState: IPreviewDrawerState;
}

export interface IRulesMove {
  ruleId: string;
  movedRuleId: string;
  movedToRuleId: string;
  position: number;
  copy?: boolean;
}

export interface IWorkspaceItem {
  id?: string;
  name: string;
  code: string;
  type: string;
  recordId: string;
  version: string;
  year: string;
  rules: any;
}

export interface IWorkspace {
  baseUrl?: string;
  id?: string;
  name: string;
  owner: string;
  items?: IWorkspaceItem[];
}

export enum RuleComponentTypeForRuleEdit {
  // TODO: Add more as they become available/known
  PointsConstraint = "Points Constraint",
  Information = "Information",
  CountConstraint = "Count Constraint",
  CountConstraintMMS = "Count Constraint MMS",
  Admission = "Admission",
  Progression = "Progression",
}

export enum RuleComponentType {
  TotalCoursePointsConstraint = "Total Course Points Constraint",
  PointsConstraint = "Points Constraint",
  SubjectSet = "Subject Set",
  LogicConstruct = "Logic Construct",
  MMSSet = "MMS Set",
  CourseMMSSet = "Course MMS Set",
  CountConstraint = "Count Constraint",
  CountConstraintMMS = "Count Constraint MMS",
  Availability = "Availability",
  Information = "Information",
  Duration = "Duration",
  Progression = "Progression",
  Admission = "Admission",
}

export enum RuleTargetType {
  Course = "course",
  Subject = "subject",
  Component = "component",
}

export enum SubjectRuleSectionType {
  Prerequisites = "Prerequisites",
  Corequisites = "Corequisites",
  NonAllowedSubjects = "Non-allowed subjects",
}

export enum ConcurrencyType {
  None = "None",
  Corequisite = "Corequisite",
  Prerequisite = "Prerequisite",
  ConcurrentPrerequisite = "Concurrent Prerequisite",
}

export enum RuleSectionType {
  CourseRules = "Course Rules",
  CourseStructure = "Course Structure",
  MMSGroups = "MMS Groups",
  ComponentStructure = "Component Structure",
  MMSPage = "MMS Page",
  Prerequisites = "Prerequisites",
  Corequisites = "Corequisites",
  NonAllowedSubjects = "Non-allowed subjects",
}
