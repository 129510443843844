import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_VALIDATETOKEN } from "actions/token";

const tokenValidated = (state = initialState.tokenValidated, action: any) => {
  switch (action.type) {
    case fulfilled(GET_VALIDATETOKEN):
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default tokenValidated;
