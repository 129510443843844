import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import { getLabel, getOperator } from "lib/parameters";
import { validateValuePresence } from "lib/validation";
import { IRuleComponent, Operator, RuleComponentType, IState } from "store/types";
import { IRulesUpdateObj, IRulesCreateObj, updateCAPSObject, postRuleGroupCAPSObject } from "actions/capsObject";
import { useDispatch, useSelector } from "react-redux";
import { getTagLabel } from "lib/tags";
import { RuleGroupEditorFields } from "./RuleGroupEditorFields";

export interface IGroupEditorFormState {
  label: string;
  tags: string[];
  operator: Operator | "";
  selectedOrDefaultFormat: string;
}

const validateFormState = (values: IGroupEditorFormState) => {
  const errors: any = {};
  validateValuePresence(errors, values, "operator");
  return errors;
};

export interface IProps {
  rule?: IRuleComponent | null;
  parentSection?: IRuleComponent | null;
  level: number;
}

export const RuleGroupEditor = (props: IProps) => {
  const { rule } = props;
  //const [selectedOrDefaultFormat, setSelectedOrDefaultFormat] = useState("");
  const { addGroupToRuleId, addGroupToSectionType, addGroupToSectionIndex, addGroupToSectionId } = useSelector(
    (state: IState) => state.editorState,
  );

  const addingTopLevelRuleGroup = addGroupToSectionId && addGroupToSectionType && addGroupToSectionIndex !== null;

  const updateRuleGroup = rule ? true : false;
  const defaultOperator = Operator.AND;
  const initialState: IGroupEditorFormState = useMemo(
    () => ({
      label: (rule && getLabel(rule)) || "",
      operator: (rule && getOperator(rule)) || defaultOperator,
      selectedOrDefaultFormat: rule?.selectedOrDefaultFormat || "",
      tags: (rule?.tags || []).map((t) => t.name),
    }),
    [defaultOperator, rule],
  );

  const getRulesPayload = useCallback(
    (values: IGroupEditorFormState): IRulesUpdateObj | IRulesCreateObj | null => {
      const { tags, ...paramVals } = values;

      const commonRulesCreateObj: IRulesCreateObj = {
        ruleType: RuleComponentType.LogicConstruct,
        parameters: paramVals,
        tags: tags.map((name) => ({ name, label: getTagLabel(name) })),
      };
      if (rule) {
        return {
          ...commonRulesCreateObj,
          ruleId: rule?.id,
          ruleType: rule?.type || RuleComponentType.LogicConstruct,
          parameters: paramVals,
          selectedOrDefaultFormat: paramVals.selectedOrDefaultFormat,
        } as IRulesUpdateObj;
      } else if (addGroupToRuleId) {
        return {
          ...commonRulesCreateObj,
          parentRuleId: addGroupToRuleId,
          selectedOrDefaultFormat: paramVals.selectedOrDefaultFormat,
        };
      } else if (addingTopLevelRuleGroup) {
        return {
          ...commonRulesCreateObj,
          parentIndex: addGroupToSectionIndex!,
          parentRuleSectionType: addGroupToSectionType!,
          selectedOrDefaultFormat: paramVals.selectedOrDefaultFormat,
        } as IRulesCreateObj;
      }
      return null;
    },
    [rule, addGroupToRuleId, addingTopLevelRuleGroup, addGroupToSectionType, addGroupToSectionIndex],
  );

  const dispatch = useDispatch();
  const callOnSubmit = useCallback(
    (values: IGroupEditorFormState, helpers: FormikHelpers<IGroupEditorFormState>) => {
      const rulesPayload: IRulesUpdateObj | IRulesCreateObj | null = getRulesPayload(values);
      if (rulesPayload) {
        if (updateRuleGroup) {
          const p = dispatch(updateCAPSObject(rulesPayload)) as any;
          if (Promise.resolve(p) === p) {
            p.catch(() => helpers.setSubmitting(false));
          }
        } else if (addGroupToRuleId || addGroupToSectionId) {
          const p = dispatch(postRuleGroupCAPSObject(rulesPayload)) as any;
          if (Promise.resolve(p) === p) {
            p.catch(() => helpers.setSubmitting(false));
          }
        }
      }
    },
    [dispatch, updateRuleGroup, addGroupToRuleId, addGroupToSectionId, getRulesPayload],
  );

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      <RuleGroupEditorFields {...props} />
    </Formik>
  );
};
