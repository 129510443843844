import store from "../store";
import { $delete } from "../lib/fetch";
import { IRuleComponent } from "store/types";
import { IUsageChain } from "containers/rules/sharedRules/types";
export const FLIP_MENU = "editorState/FLIP_MENU";
export const FLIP_EXPAND_RULE_SECTION = "editorState/FLIP_EXPAND_RULE_SECTION";
export const OPEN_EDITOR = "editorState/OPEN_EDITOR";
export const CLOSE_EDITOR = "editorState/CLOSE_EDITOR";
export const ADD_EDITOR = "editorState/ADD_EDITOR";
export const ADD_GROUP_EDITOR = "editorState/ADD_GROUP_EDITOR";
export const DELETE_RULE_EDITOR = "editorState/DELETE_RULE_EDITOR";
export const CLOSE_SUBJECT_FINDER = "editorState/CLOSE_SUBJECT_FINDER";

export const ADD_SHARED_STATIC_SET = "editorState/ADD_SHARED_STATIC_SET";
export const ADD_DYNAMIC_STATIC_SET = "editorState/ADD_DYNAMIC_STATIC_SET";

export const FLIP_WARNING_DIALOG = "editorState/FLIP_WARNING_DIALOG";
export const DISPLAY_OPTION_IN_WARNING_DIALOG = "editorState/DISPLAY_OPTION_IN_WARNING_DIALOG";
export const SET_SUBJECT_SET_USAGE_CHAINS = "editorState/SET_SUBJECT_SET_USAGE_CHAIN";

export const FLIP_PREVIEW_RULE = "editorState/FLIP_PREVIEW_RULE";

export const flipPreviewRule = (id?: string | null) => {
  return {
    type: FLIP_PREVIEW_RULE,
    payload: { id: id || null },
  };
};

export const flipExpandRuleSection = (id?: string | null) => {
  return {
    type: FLIP_EXPAND_RULE_SECTION,
    payload: { id: id || null },
  };
};
export const flipExpansion = (id?: string | null) => {
  return {
    type: FLIP_MENU,
    payload: { id: id || null },
  };
};

export const flipWarningDialog = (id?: string | null) => ({
  type: FLIP_WARNING_DIALOG,
  payload: { id: id || null },
});

export const setSubjectSetUsageChains = (usageChains: IUsageChain[][] | null) => ({
  type: SET_SUBJECT_SET_USAGE_CHAINS,
  payload: { usageChains: usageChains || null },
});

export const openEditor = (id?: string | null) => ({
  type: OPEN_EDITOR,
  payload: { id: id || null },
});

export const closeEditor = () => ({
  type: CLOSE_EDITOR,
  payload: {},
});

export const openAddEditor = (id?: string | null) => ({
  type: ADD_EDITOR,
  payload: { id: id || null },
});

export const closeSubjectFinder = () => ({
  type: CLOSE_SUBJECT_FINDER,
  payload: {},
});

export const openAddGroupEditor = (rule?: IRuleComponent | null) => ({
  type: ADD_GROUP_EDITOR,
  payload: { rule: rule || null },
});

export const deleteOneRule = (ruleId?: string) => {
  const { sysId, tokenValidated, targetType } = store.getState();
  const urlQueryParams = `sessionId=${tokenValidated.sessionId}&sysId=${sysId}`;
  return {
    type: DELETE_RULE_EDITOR,
    payload: $delete(`/v1/${targetType}rules/${ruleId}?${urlQueryParams}`),
  };
};

export const addSharedStaticSubjectSet = () => ({
  type: ADD_SHARED_STATIC_SET,
  payload: {},
});
export const addSharedDynamicSubjectSet = () => ({
  type: ADD_DYNAMIC_STATIC_SET,
  payload: {},
});
