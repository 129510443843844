import { Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { beige } from "theme/colors";

const Container = styled.div`
  width: 100%;
  border: ${(props: any) => (props.hideBorder ? "inherit" : "2px dashed gray")};
  background: ${beige.toString()};
` as any;

interface IProps {
  beingMoved: boolean;
  children: any;
  hideBorder?: boolean;
}
export const MovingWrapper = ({ beingMoved, children, hideBorder }: IProps) => {
  if (!beingMoved) {
    return children;
  } else {
    return (
      <Tooltip title="Select where this rule will be pasted" placement="top">
        <Container hideBorder={hideBorder}>{children}</Container>
      </Tooltip>
    );
  }
};
