import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import keyBy from "lodash/keyBy";
import pick from "lodash/pick";
import { GET_COURSES_AND_MMSES, SEARCH_COURSE_BY_NAME } from "actions/courses";

const toState = (arr: any[]) => {
  const whatWeNeed = arr.map((e) => pick(e, ["recordId", "code", "name"]));
  return keyBy(whatWeNeed, (e) => e.recordId);
};

const courses = (state = initialState.courses, action: any) => {
  switch (action.type) {
    case fulfilled(SEARCH_COURSE_BY_NAME):
      return {
        ...state,
        ...toState(action.payload),
      };
    case fulfilled(GET_COURSES_AND_MMSES):
      return {
        ...state,
        ...toState(action.payload.courses),
      };
    default:
      return state;
  }
};

export default courses;
