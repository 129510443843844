import { MOVE_RULE } from "actions/capsObject";
import { MOVE_RULE_OPTIMISTICALLY } from "actions/optimisticUpdate";
import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const optimisticUpdate = (state = initialState.optimisticUpdate, action: any) => {
  switch (action.type) {
    case MOVE_RULE_OPTIMISTICALLY:
      return action.payload;
    case fulfilled(MOVE_RULE):
    case failed(MOVE_RULE):
      return null;
    default:
      return state;
  }
};

export default optimisticUpdate;
