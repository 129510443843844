import React, { useCallback } from "react";
import { IMenuRuleComponent, IRuleComponent, Stereotype } from "store/types";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { openAddEditor, openAddGroupEditor, openEditor, closeEditor } from "actions/editorState";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { featureToggles } from "config/featureToggles";

interface IProps {
  rule: IMenuRuleComponent;
  parentTemplate?: IRuleComponent;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

const defaultOnClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const RuleShortcuts = (props: IProps) => {
  const { rule, ruleBeingUsed } = props;
  const dispatch = useDispatch();
  //const targetType = useSelector((state: IState) => state.targetType);
  // Show different logic for groups and what not
  const isGroup = rule.stereotype === Stereotype.RuleGroup;
  const isRuleSection = rule.stereotype === Stereotype.RuleSection;
  const isRoot = rule.stereotype === Stereotype.Rules;
  //const isSubjectLegacy = RuleTargetType.Subject === targetType && capsObject && capsObject.isLegacy;
  const newlyCreatedRules = useSelector((s: IState) => s.capsObject?.newlyCreatedRules);

  const onEditClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(closeEditor());
      if (ruleBeingUsed !== undefined) {
        ruleBeingUsed(rule.id);
      }
      dispatch(openEditor(rule.id));
    },
    [dispatch, rule.id, ruleBeingUsed],
  );

  const onAddRuleClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(openAddEditor(rule.id));
    },
    [dispatch, rule.id],
  );

  const onAddGroupRuleClick = useCallback(
    (e: any) => {
      defaultOnClick(e);
      dispatch(openAddGroupEditor(rule));
    },
    [dispatch, rule],
  );

  if (newlyCreatedRules || !featureToggles.shortcutMenuItems) {
    return null;
  }

  return (
    <>
      {!isRuleSection && !isRoot && (
        <Tooltip title="Edit" arrow placement="top">
          <IconButton size="small" onClick={onEditClick} className="reveal-on-hover">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {isGroup && !isRuleSection && (
        <Tooltip title="Add rule" arrow placement="top">
          <IconButton size="small" onClick={onAddRuleClick} className="reveal-on-hover">
            <AddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {(isGroup || isRuleSection) && (
        <Tooltip title="Add a rule group" arrow placement="top">
          <IconButton size="small" onClick={onAddGroupRuleClick} className="reveal-on-hover">
            <PlaylistAddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
