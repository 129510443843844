import React, { useCallback, useEffect } from "react";

import { IRuleEditorProps } from "../common/types";
import { Formik, FormikHelpers } from "formik";
import { getLabel, getMax, getMin, toNumber, getMMSReferences } from "lib/parameters";
import { assertThat } from "lib/assert";
import { RuleComponentType, SubjectRuleSectionType } from "store/types";
import { useDispatch } from "react-redux";
import { IRulesUpdateObj, updateCAPSObject } from "actions/capsObject";
import { getByRuleId } from "lib/rules";
import { getMMSDetails } from "actions/mmsDetails";
import { MMSNumConstraintEditorFields } from "./MMSNumConstraintEditorFields";
import { featureToggles } from "config/featureToggles";
import { validateValuePresence } from "lib/validation";

export interface IMMSNumEditorFormState {
  type: RuleComponentType;
  selectedOrDefaultFormat?: string;
  minimum?: number | null | "";
  maximum?: number | null | "";
  label?: string | null;
  mmsReferences: string[];
}

const validateFormState = (values: IMMSNumEditorFormState) => {
  const errors: any = {};
  const min = toNumber(values.minimum);
  const max = toNumber(values.maximum);
  if (isNaN(min) && isNaN(max)) {
    errors["minimum"] = errors["maximum"] = "Specify at least one of min or max";
  }
  if (!isNaN(min) && !isNaN(max) && min > max) {
    errors["maximum"] = "Max must be greater or equal to min";
    errors["minimum"] = "Min must be less or equal to max";
  }
  if (values.mmsReferences.length === 0) {
    errors["mmsReferences"] = "MMS list may not be empty";
  }
  validateValuePresence(errors, values, "selectedOrDefaultFormat");
  return errors;
};

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const MMSNumConstraintEditor = (props: Props) => {
  const { rule, onSubmit, ruleType, parentSection } = props;

  const mmsSetChild = (rule?.children || []).find((c) => c.type === RuleComponentType.MMSSet);
  const mmsSetChildId = mmsSetChild?.id;
  const mmsReferences = mmsSetChild ? getMMSReferences(mmsSetChild) : [];
  const parentSectionType = parentSection?.type;
  const useNonAllowedDefaults =
    SubjectRuleSectionType.NonAllowedSubjects === parentSectionType && !!featureToggles.nonAllowedDefaults;

  const dispatch = useDispatch();
  useEffect(() => {
    if (mmsReferences.length > 0) {
      dispatch(getMMSDetails(mmsReferences));
    }
    // eslint-disable-next-line
  }, [mmsReferences.join("-"), dispatch]);

  const useInitialValue = rule && rule.type === ruleType;
  const defaultMin = useNonAllowedDefaults ? 0 : "";
  const defaultMax = useNonAllowedDefaults ? 0 : "";
  const initialState: IMMSNumEditorFormState = useInitialValue
    ? {
        minimum: getMin(rule!) ?? defaultMin,
        maximum: getMax(rule!) ?? defaultMax,
        label: getLabel(rule!),
        mmsReferences,
        type: RuleComponentType.CountConstraintMMS,
        selectedOrDefaultFormat: props.parentTemplate?.selectedOrDefaultFormat ?? "Subject list - unlabelled",
      }
    : {
        minimum: defaultMin,
        maximum: defaultMax,
        label: "",
        mmsReferences,
        type: RuleComponentType.CountConstraintMMS,
        selectedOrDefaultFormat: "Subject list - unlabelled",
      };

  const callOnSubmit = useCallback(
    (values: IMMSNumEditorFormState, helpers: FormikHelpers<IMMSNumEditorFormState>) => {
      const { mmsReferences, label, ...params } = values;
      const parentParams = { label, ...params };
      const p = onSubmit(parentParams).then((response: any) => {
        let ruleId = mmsSetChildId;
        if (!ruleId) {
          const parent = getByRuleId(response.value.rules, response.value.newlyCreatedRuleId);
          const mmsSetChild = (parent?.children || []).find((c) => c.type === RuleComponentType.MMSSet);
          ruleId = mmsSetChild?.id;
        }

        if (!ruleId) {
          throw new Error(`Could not locate parent of MMS Set`);
        }

        const courseRulesUpdateObj: IRulesUpdateObj = {
          ruleId,
          ruleType: RuleComponentType.MMSSet,
          parameters: {
            label,
            mmsReferences,
          },
        };
        dispatch(updateCAPSObject(courseRulesUpdateObj));
      });

      if (Promise.resolve(p) === p) {
        p.catch(() => helpers.setSubmitting(false));
      }
    },
    [dispatch, onSubmit, mmsSetChildId],
  );

  assertThat([RuleComponentType.CountConstraintMMS].indexOf(ruleType) >= 0, `Invalid rule type ${ruleType}`);

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      <MMSNumConstraintEditorFields {...props} />
    </Formik>
  );
};
