import React, { useCallback } from "react";

import { Button, useTheme } from "@mui/material";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { closeEditor } from "actions/editorState";
import { commonStyles } from "theme/styles";
import { deleteOneRule } from "../../../actions/editorState";

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export interface IRuleFormActionsProps {
  submitDisabled?: boolean;
  submitting?: boolean;
  ruleId?: string | null;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

export const RuleFormActions = (props: IRuleFormActionsProps) => {
  const dispatch = useDispatch();

  const cancelEdit = useCallback(() => {
    dispatch(closeEditor());
  }, [dispatch]);

  const performDeletion = useCallback(() => {
    if (props && props.ruleId) {
      if (props.ruleBeingUsed !== undefined && !props.ruleBeingUsed(props.ruleId, true))
        dispatch(deleteOneRule(props.ruleId));
      else if (props.ruleBeingUsed === undefined) {
        dispatch(deleteOneRule(props.ruleId));
      }
    }
  }, [dispatch, props]);

  const theme = useTheme();
  const classes = commonStyles(theme);

  return (
    <ButtonsContainer>
      <div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={classes.saveButton}
          disableElevation
          disabled={props.submitDisabled || props.submitting}
        >
          Save
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="outlined"
          color="primary"
          sx={classes.cancelButton}
          type="reset"
          onClick={cancelEdit}
          disabled={props.submitting}
        >
          Cancel
        </Button>
      </div>

      {props.ruleId && (
        <Button
          variant="outlined"
          color="primary"
          sx={classes.deleteButton}
          disabled={props.submitting}
          onClick={performDeletion}
        >
          Delete Rule
        </Button>
      )}
    </ButtonsContainer>
  );
};
