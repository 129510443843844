import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import keyBy from "lodash/keyBy";
import { GET_MMS_BY_CODE, GET_MMS_DETAILS } from "actions/mmsDetails";
import { EXECUTE_MMS_QUERY } from "actions/mmsQueryResult";
import { GET_COURSES_AND_MMSES } from "actions/courses";
import pick from "lodash/pick";

const toState = (arr: any[]) => {
  const whatWeNeed = arr
    .map((e) =>
      pick(e, [
        "versionId",
        "rules",
        "code",
        "name",
        "version",
        "recordId",
        "points",
        "type",
        "courseParentRecordId",
        "parentCourse",
      ]),
    )
    .map((e) => ({
      ...e,
      parentCourse: pick(e.parentCourse, ["code", "versionId", "points", "owningOrg", "recordId", "name"]),
    }));
  return keyBy(whatWeNeed, (e) => e.recordId);
};

const mmsDetails = (state = initialState.mmsDetails, action: any) => {
  switch (action.type) {
    case fulfilled(GET_MMS_DETAILS):
    case fulfilled(GET_MMS_BY_CODE):
      return {
        ...state,
        ...toState(action.payload),
      };
    case fulfilled(EXECUTE_MMS_QUERY):
      return {
        ...state,
        ...toState(action.payload.result),
      };
    case fulfilled(GET_COURSES_AND_MMSES):
      return {
        ...state,
        ...toState(action.payload.mms),
      };
    default:
      return state;
  }
};

export default mmsDetails;
