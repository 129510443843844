import { Tooltip } from "@mui/material";
import React from "react";

interface ISuperScriptIconProps {
  icon: any;
  superScriptIcon: React.ElementType | null;
  helpText?: any;
}

// Sample usage:
{
  /* <SuperscriptIcon
            icon={<InfoOutlined />}
            superScriptIcon={selfEval ? CheckCircleOutlineIcon : null}
            helpText={selfEval ? "Information rule (Self Evaluated)" : "Information Rule"}
          /> */
}

export const SuperscriptIcon = (props: ISuperScriptIconProps) => {
  const icons = props.superScriptIcon ? (
    <div style={{ position: "relative" }}>
      {props.icon}
      <props.superScriptIcon
        style={{
          position: "absolute",
          fontSize: "12px",
          top: "-2px",
          right: "-2px",
          zIndex: 100,
          fill: "black",
          background: "white",
          borderRadius: "50%",
        }}
      />
    </div>
  ) : (
    props.icon
  );

  return props.helpText ? (
    <Tooltip title={props.helpText} placement="top" arrow>
      {icons}
    </Tooltip>
  ) : (
    icons
  );
};
