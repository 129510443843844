import React, { useCallback, useState } from "react";

import { IRuleComponent, RuleComponentType, Stereotype } from "store/types";
import { assertThat } from "lib/assert";
import { FormControl, Grid, MenuItem, Select, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { InformationEditor } from "./InformationEditor";

import { PaddedLabel, SelectMenuProps } from "../common/forms";
import { NumConstraintEditor } from "./NumConstraintEditor";
import { useDispatch } from "react-redux";
import { IRulesUpdateObj, updateCAPSObject, IRulesAddObj, addCAPSObject } from "../../../actions/capsObject";

import { getTagLabel } from "../../../lib/tags";
import { EditorGrid } from "../common/ruleComponent";
import { commonStyles } from "theme/styles";
import { MMSNumConstraintEditor } from "./MMSNumConstraintEditor";
import { AdmissionEditor } from "./AdmissionEditor";
import { getRuleUILabels } from "lib/rules";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { ProgressionEditor } from "./ProgressionEditor";
import { useAllRuleTypes } from "../editor/editorHooks";
import { closeEditor } from "actions/editorState";

export interface IEditorRulesProps {
  rule?: IRuleComponent;
  parentSection?: IRuleComponent;
  parentTemplate?: IRuleComponent;
  level: number;
}

const renderEditor = (
  ruleType: RuleComponentType,
  props: IEditorRulesProps,
  onSubmit: (vals: any) => any,
  changeRuleType: any,
) => {
  if (!ruleType) {
    return null;
  }
  switch (ruleType) {
    case RuleComponentType.Information:
      return <InformationEditor {...props} onSubmit={onSubmit} changeRuleType={changeRuleType} ruleType={ruleType} />;
    case RuleComponentType.PointsConstraint:
    case RuleComponentType.CountConstraint:
      return <NumConstraintEditor {...props} onSubmit={onSubmit} ruleType={ruleType} changeRuleType={changeRuleType} />;
    case RuleComponentType.CountConstraintMMS:
      return (
        <MMSNumConstraintEditor {...props} onSubmit={onSubmit} ruleType={ruleType} changeRuleType={changeRuleType} />
      );
    case RuleComponentType.Admission:
      return <AdmissionEditor {...props} onSubmit={onSubmit} ruleType={ruleType} changeRuleType={changeRuleType} />;
    case RuleComponentType.Progression:
      return <ProgressionEditor {...props} onSubmit={onSubmit} ruleType={ruleType} changeRuleType={changeRuleType} />;
    default:
      throw new Error(`No editor for rule type ${ruleType}`);
  }
};

export const EditorDispatcher = (props: IEditorRulesProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule } = props;
  const [ruleType, setRuleType] = useState(props.rule?.type || "");
  const allRuleTypes = useAllRuleTypes({ ...props, ruleType: ruleType as any });

  const handleTypeChange = useCallback(
    (event: any) => {
      const selectedValue = event.target.value;
      setRuleType(selectedValue);
    },
    [setRuleType],
  );

  const dispatch = useDispatch();

  const cancelEdit = useCallback(() => {
    dispatch(closeEditor());
  }, [dispatch]);

  const onSubmit = async (values: any, childrenReferences?: string[], externalReferences?: string[]) => {
    const { type: ruleType, selectedOrDefaultFormat, tags, ...parameters } = values;
    const ruleTags = tags ?? [];
    const editedCourseRulesObj = {
      ruleType,
      parameters,
      childrenReferences,
      externalReferences,
    };
    if (!childrenReferences) {
      delete editedCourseRulesObj.childrenReferences;
    }
    if (!externalReferences) {
      delete editedCourseRulesObj.externalReferences;
    }
    if (rule) {
      const courseRulesUpdateObj: IRulesUpdateObj = {
        ...editedCourseRulesObj,
        ruleId: rule?.id,
        selectedOrDefaultFormat: selectedOrDefaultFormat,
        tags: ruleTags.map((name: any) => ({ name, label: getTagLabel(name) })),
      };
      return dispatch(updateCAPSObject(courseRulesUpdateObj));
    } else {
      const courseRulesAddObj: IRulesAddObj = {
        ...editedCourseRulesObj,
        parentRuleId: "", // parentRuleId will be added in addCAPSObject();
        selectedOrDefaultFormat: selectedOrDefaultFormat || "",
        tags: ruleTags.map((name: any) => ({ name, label: getTagLabel(name) })),
      };
      return dispatch(addCAPSObject(courseRulesAddObj));
    }
  };

  assertThat(!rule || rule.stereotype === Stereotype.RuleComponent, `Invalid group stereotype ${rule?.stereotype}`);

  return (
    <EditorGrid container spacing={2}>
      {!rule && !ruleType && (
        <>
          <Grid item xs={10}>
            <PaddedLabel htmlFor="rule-type-select" sx={styles.label}>
              Rule type
            </PaddedLabel>
            <FormControl fullWidth={true}>
              <Select
                name="type"
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps as any}
                inputProps={{ id: "rule-type-select" }}
                value={ruleType || ""}
                disabled={!!rule}
                onChange={handleTypeChange}
              >
                {allRuleTypes.map((t) => {
                  const uiLabels = getRuleUILabels(t);
                  return (
                    <MenuItem key={t} value={t}>
                      <LabelWithHint
                        label={
                          <div>
                            {uiLabels?.title}
                            {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                          </div>
                        }
                        helpText={uiLabels.helpText}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} style={{ display: "flex" }}>
            <IconButton type="reset" onClick={cancelEdit} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </>
      )}

      {(ruleType || rule) && renderEditor(ruleType as any, props, onSubmit, setRuleType)}
    </EditorGrid>
  );
};
