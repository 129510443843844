import {
  CLOSE_EDITOR,
  FLIP_MENU,
  FLIP_EXPAND_RULE_SECTION,
  OPEN_EDITOR,
  DELETE_RULE_EDITOR,
  ADD_EDITOR,
  ADD_GROUP_EDITOR,
  ADD_SHARED_STATIC_SET,
  ADD_DYNAMIC_STATIC_SET,
  CLOSE_SUBJECT_FINDER,
  FLIP_WARNING_DIALOG,
  SET_SUBJECT_SET_USAGE_CHAINS,
  FLIP_PREVIEW_RULE,
} from "actions/editorState";
import {
  POST_RULEGROUP_CAPS_OBJ,
  ADD_CAPS_OBJ,
  MOVE_RULE,
  ADD_SHARED_SUBJECT_SET,
  GET_CAPS_OBJ,
  RESAVE_CAPS_OBJ,
} from "actions/capsObject";
import initialState from "store/initialState";
import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import { UPDATE_CAPS_OBJ } from "actions/capsObject";
import { IEditorState, Stereotype } from "store/types";
import { SET_RULE_MOVE } from "actions/moveRuleState";
import { getRuleSectionIds } from "lib/rules";

const editorState = (state = initialState.editorState, action: any): IEditorState => {
  switch (action.type) {
    case FLIP_EXPAND_RULE_SECTION:
      return {
        ...state,
        menuOpenForRuleId: state.menuOpenForRuleId ? null : action.payload.id || null,
        expandedSections: {
          ...state.expandedSections,
          [action.payload.id]: state.expandedSections ? !state.expandedSections[action.payload.id] : false,
        },
      };
    case FLIP_MENU:
      return {
        ...state,
        menuOpenForRuleId: state.menuOpenForRuleId ? null : action.payload.id || null,
      };
    case FLIP_WARNING_DIALOG:
      return {
        ...(state.warningDialogOpenForRuleId ? initialState.editorState : state),
        warningDialogOpenForRuleId: state.warningDialogOpenForRuleId ? null : action.payload.id || null,
      };

    case SET_SUBJECT_SET_USAGE_CHAINS:
      return {
        ...state,
        subjectSetUsageChains: action.payload.usageChains,
      };
    case FLIP_PREVIEW_RULE:
      return {
        ...initialState.editorState,
        previewedRuleId: action.payload.id === state.previewedRuleId ? null : action.payload.id,
      };
    case fulfilled(UPDATE_CAPS_OBJ):
      return {
        ...initialState.editorState,
        previewedRuleId: action.payload.updateRequest?.ruleId ?? null,
      };

    case OPEN_EDITOR:
      return {
        ...initialState.editorState,
        subjectSetUsageChains: state.subjectSetUsageChains,
        editedRuleId: action.payload.id,
      };
    case ADD_EDITOR:
      return {
        ...initialState.editorState,
        addRuleToRuleId: action.payload.id,
      };
    case ADD_GROUP_EDITOR:
      return {
        ...initialState.editorState,
        addGroupToRuleId: action.payload.rule.stereotype !== Stereotype.RuleSection ? action.payload.rule.id : null,
        addGroupToSectionId: action.payload.rule.stereotype === Stereotype.RuleSection ? action.payload.rule.id : null,
        addGroupToSectionType:
          action.payload.rule.stereotype === Stereotype.RuleSection ? action.payload.rule.type : null,
        addGroupToSectionIndex:
          action.payload.rule.stereotype === Stereotype.RuleSection ? action.payload.rule.index : null,
      };
    case ADD_SHARED_STATIC_SET:
      return {
        ...initialState.editorState,
        addSharedStaticSubjectSet: true,
      };
    case ADD_DYNAMIC_STATIC_SET:
      return {
        ...initialState.editorState,
        addSharedDynamicSubjectSet: true,
      };
    case fulfilled(GET_CAPS_OBJ):
      const obj = action.payload.courses || action.payload.subject || action.payload.component || {};
      return {
        ...state,
        expandedSections: getRuleSectionIds(obj.rules).reduce((prev, key) => ({ ...prev, [key]: false }), {}),
      };
    case CLOSE_SUBJECT_FINDER:
    case CLOSE_EDITOR:
    case SET_RULE_MOVE:
    case fulfilled(DELETE_RULE_EDITOR):
    case failed(DELETE_RULE_EDITOR):
    case failed(UPDATE_CAPS_OBJ):
    case failed(RESAVE_CAPS_OBJ):
    case fulfilled(POST_RULEGROUP_CAPS_OBJ):
    case failed(POST_RULEGROUP_CAPS_OBJ):
    case fulfilled(ADD_CAPS_OBJ):
    case failed(ADD_CAPS_OBJ):
    case failed(ADD_SHARED_SUBJECT_SET):
    case fulfilled(ADD_SHARED_SUBJECT_SET):
    case fulfilled(MOVE_RULE):
    case failed(MOVE_RULE):
      return { ...initialState.editorState };
    default:
      return state;
  }
};

export default editorState;
