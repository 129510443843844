import React from "react";
import {
  getBreadthDisciplineScope,
  getConcurrency,
  getLabel,
  getStaticSubjects,
  isBreadthDynamicSubjectSet,
  isDynamicSubjectSet,
  isStaticSubjectSet,
  numericLabelSuffix,
} from "lib/parameters";
import { IRulesProps } from "../common/types";
import { BreadthDisciplineScope, ConcurrencyType, IState, RuleComponentType } from "store/types";
import { Collapse, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { NextLevelContainer } from "../common/ruleComponent";
import { StaticSubjectSet } from "./StaticSubjectSet";
import { DynamicSubjectSet } from "./DynamicSubjectSet";
import styled from "styled-components";
import { DraggableConstraintHeader } from "../common/draggableConstraintHeader";
import { BreadthDisciplineScopeIcons, IconWrapper } from "components/icons/breadthDisciplineScope";
import AllOutOutlinedIcon from "@mui/icons-material/AllOutOutlined";
import { CommonTag } from "lib/tags";
import { brandBlue } from "theme/colors";

const Strong = styled.strong`
  margin-left: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 95%;
`;

export const NumConstraint = (props: IRulesProps) => {
  const { rule, level, dragHandle, parentSection, parentTemplate } = props;

  const sharedComponents = useSelector((s: IState) => s.capsObject!.rules.sharedComponents || []);
  const parentSharedComponents = useSelector((s: IState) => s.parentSharedComponents || []);
  const expandedSections = useSelector((s: IState) => s.editorState.expandedSections);
  const targetType = useSelector((s: IState) => s.targetType);
  const expandParentSection = (expandedSections && parentSection && expandedSections[parentSection.id]) || false;

  // Construct the rule's display label
  const ruleLabel = getLabel(rule);
  const suffix = numericLabelSuffix(rule, rule.type === RuleComponentType.PointsConstraint ? "pts" : "");
  const label = [ruleLabel, suffix].filter(Boolean).join(" / ");

  const embeddedSubjectSet = rule.children[0];
  const isEmbeddedSubjectSetEmpty = getStaticSubjects(embeddedSubjectSet).length === 0;
  const referredSubjectSets = [...(rule.childrenReferences || []), ...(rule.externalReferences || [])]
    .map((r) => sharedComponents.find((c) => c.id === r)! || parentSharedComponents.find((c) => c.id === r)!)
    .filter(Boolean);

  // For concurrency icon
  const concurrency = rule && getConcurrency(rule);

  // For unassigned subjects included icon
  const unassignedSubjectsIncluded = !!(rule?.tags ?? []).find((t) => t.name === CommonTag.COUNT_ALL_SUBJECTS);

  /** set expanded to false on initial render, and then use value from expandParentSection or handleExpansion */
  const [expanded, setExpanded] = React.useState(false);
  React.useEffect(() => {
    setExpanded(expandParentSection);
  }, [expandParentSection]);
  // reset to false on initial render
  React.useEffect(() => {
    setExpanded(false);
  }, []);
  const handleExpansion = React.useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  // The warning
  const breadthSets = referredSubjectSets.filter(isBreadthDynamicSubjectSet);
  const scope = getBreadthDisciplineScope(rule, targetType);
  const error =
    scope === BreadthDisciplineScope.Discipline && breadthSets.length
      ? `This rule is marked as discipline subjects only, but uses breadth dynamic sets: \n\n${breadthSets
          .map(getLabel)
          .join(", ")}`
      : null;

  const draggableConstraintHeaderProps = {
    expanded: expanded && !isBeingMoved,
    dragHandle,
    handleExpansion: isBeingMoved ? undefined : handleExpansion,
    label: (
      <>
        {label}
        {concurrency === ConcurrencyType.ConcurrentPrerequisite && (
          <>
            &nbsp;&nbsp;
            <Tooltip title="Concurrent prerequisite" placement="top" arrow>
              <IconWrapper>C</IconWrapper>
            </Tooltip>
          </>
        )}
        {unassignedSubjectsIncluded && (
          <>
            &nbsp;&nbsp;
            <Tooltip title="Counts all subjets on plan (assigned & unassigned to the MMS)" placement="top" arrow>
              <AllOutOutlinedIcon fontSize="small" style={{ fill: brandBlue.toString() }} />
            </Tooltip>
          </>
        )}
        <BreadthDisciplineScopeIcons rule={rule} targetType={targetType} />
        {/* <div style={{ display: "inline" }}>D</div> */}
      </>
    ),
    level,
    rule,
    parentTemplate,
    tagPreview: true,
    error,
  };

  return (
    <>
      <DraggableConstraintHeader {...draggableConstraintHeaderProps} />
      <NextLevelContainer>
        <Collapse in={expanded && !isBeingMoved} timeout={0} mountOnEnter style={{ width: "-webkit-fill-available" }}>
          {!isEmbeddedSubjectSetEmpty && (
            <>
              <Strong>Included Subjects:</Strong>
              <NextLevelContainer>
                <StaticSubjectSet rule={embeddedSubjectSet} level={level} index={0} siblings={1} />
              </NextLevelContainer>
            </>
          )}

          {referredSubjectSets.map((c, i) => (
            <React.Fragment key={c.id}>
              <Strong>{getLabel(c)}</Strong>
              {isStaticSubjectSet(c) && (
                <NextLevelContainer>
                  <StaticSubjectSet
                    rule={c}
                    level={level}
                    index={i + (isEmbeddedSubjectSetEmpty ? 0 : 1)}
                    siblings={1}
                  />
                </NextLevelContainer>
              )}
              {isDynamicSubjectSet(c) && (
                <NextLevelContainer>
                  <DynamicSubjectSet
                    rule={c}
                    level={level}
                    index={i + (isEmbeddedSubjectSetEmpty ? 0 : 1)}
                    siblings={1}
                  />
                </NextLevelContainer>
              )}
            </React.Fragment>
          ))}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
