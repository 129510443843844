import React from "react";

import { assertThat } from "lib/assert";
import { IRulesProps } from "../common/types";
import { dynamicSetQueryAsFlatObj } from "lib/parameters";
import { DynamicSubjectSetQuery } from "./DynamicSubjectSetQuery";

export const DynamicSubjectSet = (props: IRulesProps) => {
  const { rule } = props;
  assertThat(rule.children.length === 0, "Subject sets should not have children");

  const query = dynamicSetQueryAsFlatObj(props.rule);

  return <DynamicSubjectSetQuery query={query} />;
};
