import React from "react";
import { useSelector } from "react-redux";

import { IState } from "store/types";
import { SharedSubjectSet } from "./SharedSubjectSet";
import { ParentSharedSetsHeading } from "./ParentSharedSetsHeading";

export const ParentSharedSubjectSets = () => {
  const parentSharedComponents = useSelector((s: IState) => s.parentSharedComponents || []);

  if (parentSharedComponents.length === 0) {
    return null;
  }

  return (
    <>
      <ParentSharedSetsHeading>
        {parentSharedComponents.map((r, i) => (
          <SharedSubjectSet key={r.id} rule={r} level={1} index={i} readOnly={true} />
        ))}
      </ParentSharedSetsHeading>
    </>
  );
};
