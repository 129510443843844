import { featureToggles } from "config/featureToggles";
import styled from "styled-components";

interface IProps {
  isPreviewed?: boolean;
  level: number;
  isLastChild?: boolean;
}

const borderColor = (l: number) => {
  switch (l) {
    case 1:
    case 2:
      return "rgba(20, 20, 20, 0.8)";
    case 3:
      return "rgba(40, 40, 40, 0.4)";
    case 4:
      return "rgba(60, 60, 60, 0.3)";
    default:
      return "rgba(80, 80, 80, 0.3)";
  }
};

// TODO - use this for different connector sizes
const borderSize = (l: number) => {
  switch (l) {
    default:
      return 1;
  }
};

const h = "1rem";
const w = "0.75rem";
export const RuleWrapper = styled.div<IProps>`
  z-index: ${(props: IProps) => (props.isPreviewed ? "100" : "inherit")};
  position: ${(props: IProps) => (props.isPreviewed ? "relative" : "inherit")};
  outline: ${(props: IProps) => (props.isPreviewed ? "3px dashed green" : "none")};
  ${({ level }: IProps) =>
    level > 1 &&
    featureToggles.bracketConnectors &&
    `
      position: relative;
      padding-left: 6px;
      margin-left: ${level <= 2 ? "2rem" : "4px"};
      &:before {
        border-left: ${borderSize(level)}px solid ${borderColor(level)};
        border-bottom: ${borderSize(level)}px solid ${borderColor(level)};
        height: ${h};
        content: "";
        width: ${w};
        position: absolute;
        left: 0;
        bottom: calc(100% - ${h});
      }
  `}

  ${({ level, isLastChild }: IProps) =>
    level > 1 &&
    featureToggles.bracketConnectors &&
    !isLastChild &&
    `
      &:after {
        // border-top: 1px solid black;
        border-left: ${borderSize(level)}px solid ${borderColor(level)};
        height: calc(100% - ${h});
        content: "";
        width: ${w};
        position: absolute;
        left: 0;
        top: ${h};
      }
  `}
`;
