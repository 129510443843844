import React, { useCallback } from "react";
import { DialogContent, Typography, Slide, Dialog, Button, DialogActions, DialogTitle, useTheme } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { commonStyles } from "theme/styles";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/types";
import { clearSessionError } from "actions/sessionError";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const goBack = () => window.history.back();

export const SessionWarningModal = () => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const sessionError = !!useSelector((s: IState) => s.sessionError);
  const dispatch = useDispatch();
  const onCancel = useCallback(() => dispatch(clearSessionError()), [dispatch]);

  if (!sessionError) {
    return null;
  }

  return (
    <Dialog
      open={!!sessionError}
      onClose={onCancel}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle id="warning-dialog-title">
        <Typography color="primary" variant="h6">
          Session Expired
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Your session has expired. Please return to CAPS and re-launch the Editor to return to editing the record.
        </Typography>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onCancel} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<NavigateBeforeIcon sx={styles.headerButtonIcon} />}
            onClick={goBack}
            sx={styles.saveButton}
          >
            Back to CAPS
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};
