import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DialogContent, Grid, IconButton } from "@mui/material";
import { SSSQueryEditor } from "./SSSQueryEditor";
import { SubjectTable } from "containers/rules/common/subjectTable";
import { Delete } from "@mui/icons-material";
import { featureToggles } from "config/featureToggles";
import { SearchModalHeader } from "containers/rules/common/searchModalHeader";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  subjectRecordIds: string[];
  addSubject: (id: string) => any;
  deleteSubject: (id: string) => any;
  close: () => any;
}

export function SSSSearchModal(props: IProps) {
  const { addSubject, deleteSubject, close, subjectRecordIds } = props;
  const noSubjects = subjectRecordIds.length === 0;

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={close}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
    >
      <SearchModalHeader
        title={featureToggles.search ? `Subject Finder` : `Static Subject List Finder`}
        onClose={close}
        onSave={close}
      />

      <DialogContent>
        <Grid container spacing={0} alignItems="center" justifyContent="center" direction="column">
          {!noSubjects && (
            <Grid item xs={12} sm={10} md={9} lg={8} xl={6} style={{ width: "inherit" }}>
              <div style={{ margin: "1rem" }}>
                <strong>{featureToggles.search ? `Subject Scope` : `Included subjects`}</strong>
                <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
                  <SubjectTable
                    subjectRecordIds={subjectRecordIds}
                    rowEndAction={(id: string) => (
                      <td align="center">
                        <IconButton size="small" color="primary" onClick={() => deleteSubject(id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </td>
                    )}
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={10} md={9} lg={8} xl={6} style={{ width: "inherit" }}>
            <SSSQueryEditor subjectRecordIds={subjectRecordIds} addSubject={addSubject} close={close} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
