import React, { useCallback } from "react";
import logo from "../../assets/images/logo-with-padding.svg";
import { ResponsiveCenter } from "components/spinner/Center";
import styled from "styled-components";
import { AppBar, IconButton, Toolbar, Tooltip, useTheme } from "@mui/material";
import { Aligned } from "components/divs/alignedDiv";
import { Button } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { commonStyles } from "theme/styles";
import { useDispatch, useSelector } from "react-redux";
import "./app-bar.css";
import { resaveCAPSObject } from "actions/capsObject";
import { IState } from "store/types";
import { featureToggles } from "config/featureToggles";

const StyledToolbar = styled(Toolbar)`
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  height: 115px;
`;

const Img = styled.img`
  max-height: 5rem;
`;

const goBack = () => window.history.back();

const AppHeader = () => {
  const theme = useTheme();
  const styles = commonStyles(theme);

  const capsObject = useSelector((state: IState) => state.capsObject);
  const loading = useSelector((state: IState) => state.loading);
  const newlyCreatedRules = capsObject && capsObject.newlyCreatedRules;

  const dispatch = useDispatch();
  const resave = useCallback(() => dispatch(resaveCAPSObject()), [dispatch]);

  return (
    <AppBar position="static">
      <ResponsiveCenter>
        <StyledToolbar>
          <Aligned style={{ width: "100%", justifyContent: "space-between" }}>
            <Img src={logo} alt="Univerity Logo" />
            <a href="/" className="page-title">
              Rule Editor
            </a>
            {featureToggles.resave && !newlyCreatedRules && !loading && (
              <>
                <Tooltip title="Resave/Refresh CAPS record" arrow>
                  <IconButton onClick={resave} size="small" color="inherit">
                    <SyncIcon sx={styles.headerButtonIcon} />
                  </IconButton>
                </Tooltip>
                &nbsp; &nbsp; &nbsp; &nbsp;
              </>
            )}
            <Button
              onClick={goBack}
              variant="outlined"
              size="small"
              color="inherit"
              sx={styles.headerButton}
              startIcon={<NavigateBeforeIcon sx={styles.headerButtonIcon} />}
            >
              Back to CAPS
            </Button>
          </Aligned>
        </StyledToolbar>
      </ResponsiveCenter>
    </AppBar>
  );
};

export default AppHeader;
