import { useMemo } from "react";
import { IRuleComponent, IState, RuleComponentType } from "store/types";
import { getRuleComponentTypes } from "lib/rules";
import { useSelector } from "react-redux";

interface IHookProps {
  rule?: IRuleComponent;
  ruleType: RuleComponentType;
  parentTemplate?: IRuleComponent;
  parentSection?: IRuleComponent;
}

export const useAllowedFormats = (props: IHookProps) => {
  const { rule, parentTemplate, ruleType } = props;
  const templates = useSelector((state: IState) => state.templates);

  const editedRuleType = rule?.type;
  const allowedFormats = useMemo(
    () =>
      editedRuleType === ruleType && parentTemplate
        ? parentTemplate.formats
        : templates.find((t) => t.type === ruleType)?.formats || [],
    [editedRuleType, ruleType, parentTemplate, templates],
  );
  return allowedFormats;
};

export const useAllRuleTypes = (props: IHookProps) => {
  const { parentSection } = props;
  const parentSectionType = parentSection?.type;

  const templates = useSelector((state: IState) => state.templates);
  const targetType = useSelector((state: IState) => state.targetType);

  const allRuleTypes = useMemo(() => getRuleComponentTypes(templates, targetType, parentSectionType as any), [
    templates,
    targetType,
    parentSectionType,
  ]);
  return allRuleTypes;
};

export const useFormatsSamplesAndTypes = (props: IHookProps, selectedOrDefaultFormat?: string) => {
  const { ruleType } = props;
  const templates = useSelector((state: IState) => state.templates);

  const allowedFormats = useAllowedFormats(props);

  const samples = useMemo(
    () =>
      (templates.find((t) => t.type === ruleType)?.formats || []).find((f) => f.name === selectedOrDefaultFormat)
        ?.samples || [],
    [ruleType, selectedOrDefaultFormat, templates],
  );

  const allRuleTypes = useAllRuleTypes(props);
  return { allowedFormats, samples, allRuleTypes };
};
