import React, { useCallback, useState } from "react";
import { ResponsiveBottomAlignedCenter } from "components/spinner/Center";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { brandDarkBlue } from "theme/colors";
import { IState, RuleTargetType } from "store/types";
import { Button, useTheme } from "@mui/material";
import { commonStyles } from "theme/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { flipPreviewDrawer } from "actions/previewDrawerState";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { featureToggles } from "config/featureToggles";
import { get, post } from "lib/fetch";
import { openSnackBar } from "actions/snackBar";
import { CourseListPopover } from "./workspace/CourseListPopover";

const Background = styled.div`
  background-color: ${brandDarkBlue.toString()};
  width: 100%;
  display: flex;
  height: 176px;
  margin-bottom: 5px;
`;
const H1 = styled.h1`
  margin-top: 0rem;
  color: white;
`;
const H3 = styled.h3`
  color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0rem;
  justify-content: space-between;
  align-items: center;
  // padding-right: 24px;
`;

const DontBreak = styled.span`
  display: inline-block;
`;

export const AppSubheading = () => {
  // TODO: get title based on type of component in url path
  // const type = getTypeFromUrlPath(window.location.pathname);
  // const dispatch = useDispatch();
  // const classes = useStyles();
  const theme = useTheme();
  const styles = commonStyles(theme);
  const capsObject = useSelector((state: IState) => state.capsObject);
  const title = capsObject?.name;
  const code = capsObject?.code && `| ${capsObject.code}`;
  const version = capsObject?.version && `| v${capsObject.version}`;

  const dispatch = useDispatch();
  const handleShowPreview = useCallback(() => dispatch(flipPreviewDrawer()), [dispatch]);
  const showPreview = useSelector((state: IState) => !state.previewDrawerState.open);

  /** CPT preview **/
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const createWorkspaceAndSpawn = useCallback(
    async (recordId: string, year: string) => {
      try {
        const workspace = await post("/v1/workspaces", {
          name: "CPT Preview",
          owner: "staff",
          item: {
            name: capsObject?.name,
            code: capsObject?.code,
            type: capsObject?.rules.type,
            recordId: capsObject?.recordId,
            version: capsObject?.version,
            year: capsObject?.effectiveYear,
            rules: capsObject?.rules,
          },
        });
        window.open(`${workspace.baseUrl}/preview-plan/${recordId}/${year}/${workspace.id}`, "_blank");
      } catch (e) {
        console.log(e);
        dispatch(openSnackBar({ type: "error", message: "Could not start CPT Preview", autoHideDuration: 30000 }));
      }
    },
    [capsObject, dispatch],
  );

  const spawnCPTPreview = useCallback(
    async (event: any) => {
      if (capsObject?.rules.type.toLowerCase() === RuleTargetType.Course) {
        await createWorkspaceAndSpawn(capsObject?.recordId, capsObject?.effectiveYear);
      } else if (capsObject?.rules.type.toLowerCase() === RuleTargetType.Component) {
        try {
          const courses = await get(
            `/v1/courserules/coursesFor?sysId=${(capsObject as any).versionId}&type=${capsObject.rules.type}`,
          );
          if (courses.length === 1) {
            await createWorkspaceAndSpawn(courses[0].recordId, courses[0].year);
          } else {
            dispatch(
              openSnackBar({
                type: "error",
                message: "This component is not referenced by a course",
                autoHideDuration: 30000,
              }),
            );
          }
        } catch (e) {
          console.log(e);
          dispatch(openSnackBar({ type: "error", message: "Could not start CPT Preview", autoHideDuration: 30000 }));
        }
      } else if (capsObject?.rules.type.toLowerCase() == RuleTargetType.Subject) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      }
    },
    [capsObject, createWorkspaceAndSpawn, dispatch],
  );

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{capsObject === null ? "Rule Editor" : `${capsObject.code} - Rule Editor`}</title>
        </Helmet>
      </HelmetProvider>
      <Background>
        <ResponsiveBottomAlignedCenter>
          <Row>
            <H3>University of Melbourne</H3>
            <Button
              onClick={handleShowPreview}
              variant="outlined"
              size="small"
              color="inherit"
              sx={styles.headerButton}
              startIcon={
                showPreview ? (
                  <Visibility sx={styles.headerButtonIcon} />
                ) : (
                  <VisibilityOff sx={styles.headerButtonIcon} />
                )
              }
            >
              {showPreview ? "Preview" : "Close Preview"}
            </Button>
          </Row>
          <H1 style={{ display: "flex", alignItems: "center" }}>
            <DontBreak>
              {title} {code} {version}
            </DontBreak>
            {featureToggles.previewWorkspaces && (
              <div style={{ display: "flex", alignContent: "flex-end", marginLeft: "auto" }}>
                <Button
                  aria-controls="cpt-preview"
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={styles.headerButton}
                  onClick={(e) => spawnCPTPreview(e)}
                  startIcon={<WorkspacesIcon sx={styles.headerButtonIcon} />}
                >
                  CPT Preview
                </Button>
              </div>
            )}
          </H1>
        </ResponsiveBottomAlignedCenter>
      </Background>
      {anchorEl !== null && (
        <CourseListPopover
          anchorEl={anchorEl}
          onClose={closePopover}
          spawnPreview={createWorkspaceAndSpawn}
          type={capsObject?.rules.type}
          sysId={(capsObject as any)?.versionId}
        />
      )}
    </div>
  );
};
