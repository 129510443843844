import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { ActionType } from "redux-promise-middleware";
import {
  ADD_CAPS_OBJ,
  GET_CAPS_OBJ,
  MOVE_RULE,
  UPDATE_CAPS_OBJ,
  ADD_SHARED_SUBJECT_SET,
  RESAVE_CAPS_OBJ,
} from "actions/capsObject";
import { GET_SUBJECT_BY_CODE, GET_SUBJECT_DETAILS } from "actions/subjectDetails";
import { GET_TEMPLATES } from "actions/templates";
import { GET_MMS_DETAILS, GET_MMS_BY_CODE } from "actions/mmsDetails";
import { GET_COURSES_AND_MMSES, SEARCH_COURSE_BY_NAME } from "actions/courses";
import { CLEAR_SESSION_ERROR } from "actions/sessionError";

const sessionError = (state = initialState.sessionError || null, action: any) => {
  switch (action.type) {
    case fulfilled(GET_CAPS_OBJ):
    case fulfilled(UPDATE_CAPS_OBJ):
    case fulfilled(RESAVE_CAPS_OBJ):
    case fulfilled(ADD_CAPS_OBJ):
    case fulfilled(GET_SUBJECT_DETAILS):
    case fulfilled(GET_SUBJECT_BY_CODE):
    case fulfilled(GET_MMS_DETAILS):
    case fulfilled(GET_MMS_BY_CODE):
    case fulfilled(GET_COURSES_AND_MMSES):
    case fulfilled(SEARCH_COURSE_BY_NAME):
    case fulfilled(GET_TEMPLATES):
    case fulfilled(MOVE_RULE):
    case fulfilled(ADD_SHARED_SUBJECT_SET):
      // If an API worked - the session is still alive
      return null;
    case failed(GET_CAPS_OBJ):
    case failed(UPDATE_CAPS_OBJ):
    case failed(RESAVE_CAPS_OBJ):
    case failed(ADD_CAPS_OBJ):
    case failed(GET_SUBJECT_DETAILS):
    case failed(GET_SUBJECT_BY_CODE):
    case failed(GET_MMS_DETAILS):
    case failed(GET_MMS_BY_CODE):
    case failed(GET_COURSES_AND_MMSES):
    case failed(SEARCH_COURSE_BY_NAME):
    case failed(GET_TEMPLATES):
    case failed(MOVE_RULE):
    case failed(ADD_SHARED_SUBJECT_SET):
      return action.payload;
    case CLEAR_SESSION_ERROR:
      return null;
    default:
      // TODO look to specific CAPS error messages in the future (none for now)
      if (action.type && (action.type as string).endsWith(ActionType.Rejected)) {
        return action.payload;
      } else if (action.type && (action.type as string).endsWith(ActionType.Fulfilled)) {
        return null;
      } else {
        return state;
      }
  }
};

export default sessionError;
