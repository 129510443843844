import { Theme } from "@mui/material";
import styled from "styled-components";
import { validationGreen } from "theme/colors";

export const useCardStyles = (theme: Theme) => ({
  card: {
    borderRadius: "5px",
    padding: "0px",
    marginTop: "10px",
    height: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingTop: "0.15rem",
    paddingBottom: "0.15rem",
    fontSize: "1rem",
    minHeight: "1rem",
    // fontWeight: "bold",
  },
  cardContent: {
    paddingTop: "0.15rem",
    paddingBottom: "0.5rem !important",
  },
  successIcon: {
    fill: validationGreen.toString(),
  },
});

export const CardName = styled.h4`
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
`;

export const CardSubheading = styled.p`
  margin-top: 0.2rem;
  margin-bottom: 0.75rem;
  font-size: 0.8rem;
`;

export const OwningOrg = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 0.85rem;
`;
export const SubjectSearchResults = styled.div`
  margin-top: 1rem;
  eight: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
