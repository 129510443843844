import { get, patch, post, put } from "lib/fetch";
import { IRulesMove, ITag } from "store/types";
import store from "../store";
export const GET_CAPS_OBJ = "capsObject/GET_CAPS_OBJ";
export const RESAVE_CAPS_OBJ = "capsObject/RESAVE_CAPS_OBJ";
export const UPDATE_CAPS_OBJ = "capsObject/UPDATE_CAPS_OBJ";
export const ADD_CAPS_OBJ = "capsObject/ADD_CAPS_OBJ";
export const MOVE_RULE = "capsObject/MOVE_RULE";
export const UPDATE_CAPS_OBJ_RAW = "capsObject/UPDATE_CAPS_OBJ_RAW";
export const POST_RULEGROUP_CAPS_OBJ = "capsObject/POST_RULEGROUP_CAPS_OBJ";
export const CANCEL_MIGRATED_RULES = "capsObject/CANCEL_MIGRATED_RULES";
export const ADD_SHARED_SUBJECT_SET = "capsObject/ADD_SHARED_SUBJECT_SET";

export interface IRulesCreateObj {
  ruleId?: string;
  parentRuleId?: string;
  ruleType: string;
  selectedOrDefaultFormat?: string;
  parameters?: any;
  parentIndex?: number; // for top level RuleGroup
  parentRuleSectionType?: string; // for top level RuleGroup
  tags?: ITag[];
}
export interface IRulesUpdateObj {
  ruleId?: string;
  ruleType: string;
  selectedOrDefaultFormat?: string;
  parameters?: any;
  childrenReferences?: string[];
  externalReferences?: string[];
  tags?: ITag[];
}

export interface IRulesAddObj {
  parentRuleId: string;
  ruleType: string;
  selectedOrDefaultFormat: string;
  parameters?: any;
  childrenReferences?: string[];
  externalReferences?: string[];
  tags?: ITag[];
}

export const getCAPSObject = () => {
  const { sysId, tokenValidated, targetType } = store.getState();
  const urlQueryParams = `sysId=${sysId}&sessionId=${tokenValidated.sessionId}&attemptToExtractRules=${true}`;

  return {
    type: GET_CAPS_OBJ,
    payload: get(`/v1/${targetType}rules?${urlQueryParams}`),
  };
};

export const postRuleGroupCAPSObject = (newRuleGroup?: IRulesCreateObj) => {
  const { sysId, tokenValidated, targetType } = store.getState();
  return {
    type: POST_RULEGROUP_CAPS_OBJ,
    payload: post(`/v1/${targetType}rules/rule`, {
      ...newRuleGroup,
      sysId: sysId,
      sessionId: tokenValidated.sessionId,
    }),
  };
};

export const resaveCAPSObject = () => {
  const { sysId, tokenValidated, targetType } = store.getState();
  return {
    type: RESAVE_CAPS_OBJ,
    payload: post(`/v1/${targetType}rules/resave`, {
      sysId: sysId,
      sessionId: tokenValidated.sessionId,
    }),
  };
};

export const updateCAPSObject = (courserules?: IRulesUpdateObj) => {
  const { sysId, tokenValidated, targetType } = store.getState();
  return {
    type: UPDATE_CAPS_OBJ,
    payload: patch(`/v1/${targetType}rules/update-rule`, {
      ...courserules,
      sysId: sysId,
      sessionId: tokenValidated.sessionId,
    }),
  };
};

export const addCAPSObject = (courserules?: IRulesAddObj) => {
  const { sysId, tokenValidated, editorState, targetType } = store.getState();
  return {
    type: ADD_CAPS_OBJ,
    payload: post(`/v1/${targetType}rules/rule`, {
      ...courserules,
      parentRuleId: editorState.addRuleToRuleId || "",
      sysId: sysId,
      sessionId: tokenValidated.sessionId,
    }),
  };
};

// TODO: define interface for params
export const addSharedSubjectSet = (params: any, tags?: ITag[]) => {
  const { sysId, targetType, tokenValidated } = store.getState();
  return {
    type: ADD_SHARED_SUBJECT_SET,
    payload: post(`/v1/${targetType}rules/shared-subject-set`, {
      sessionId: tokenValidated.sessionId,
      sysId,
      parameters: params,
      tags: tags,
    }),
  };
};

export const moveRule = (params: IRulesMove) => {
  const { sysId, tokenValidated, editorState, targetType } = store.getState();
  return {
    type: MOVE_RULE,
    payload: put(`/v1/${targetType}rules/move-rule`, {
      ...params,
      parentRuleId: editorState.addRuleToRuleId || "",
      sysId: sysId,
      sessionId: tokenValidated.sessionId,
      copy: params.copy,
    }),
  };
};

export const updateRawObject = (rules?: any) => {
  const { sysId, tokenValidated, targetType } = store.getState();
  const urlQueryParams = `sysId=${sysId}&sessionId=${tokenValidated.sessionId}&retoken=${tokenValidated.token}`;
  return {
    type: UPDATE_CAPS_OBJ_RAW,
    payload: put(`/v1/${targetType}rules/update?${urlQueryParams}`, {
      rules,
    }),
  };
};

export const cancelMigratedSubjectRules = (rule?: any | null) => ({
  type: CANCEL_MIGRATED_RULES,
  payload: { rule: rule || null },
});
