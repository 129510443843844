import { EXECUTE_MMS_QUERY } from "actions/mmsQueryResult";
import { failed, fulfilled, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const mmsQueryResult = (state = initialState.mmsQueryResult, action: any) => {
  switch (action.type) {
    case fulfilled(EXECUTE_MMS_QUERY):
      // If this result is earlier than the last one - ignore it
      if (state && action.payload.searchTimestamp < state.searchTimestamp) {
        return state;
      }
      return action.payload;
    case failed(EXECUTE_MMS_QUERY):
    case pending(EXECUTE_MMS_QUERY):
      return null;
    default:
      return state;
  }
};

export default mmsQueryResult;
