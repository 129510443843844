import React from "react";
import { MemoTableCell, MemoTableCellBlueText } from "lib/memoTable";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { brandLightBlue } from "theme/colors";
import { commonStyles } from "theme/styles";
import { useTheme } from "@mui/material";

interface IProps {
  courseRecordId: string[];
}
export const CoursesTableComponent = ({ courseRecordId: mmsRecordIds }: IProps) => {
  const theme = useTheme();
  const classes = commonStyles(theme);
  const courses = useSelector((s: IState) => s.courses);
  return (
    <table style={classes.subjectTable}>
      <tbody>
        {mmsRecordIds.map((id, i) => {
          const course = courses[id];
          if (course) {
            return (
              <tr
                key={id}
                style={{ ...classes.subjectTableRow, background: i % 2 === 0 ? "white" : brandLightBlue.toString() }}
              >
                <MemoTableCellBlueText bold={true} alignment="left" value={course.name} />
                <MemoTableCell alignment="center" width="220px" value={course.code} />
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};
