import React from "react";
import { Formik } from "formik";
import { SSSQueryEditorFormContent } from "./SSSQueryEditorFormContent";
import { featureToggles } from "config/featureToggles";

export interface ISSSQueryEditorFormState {
  nameOrCode: string;
  type: string[];
  level: string[];
  areaOfStudy: string[];
  owningOrg: string[];
  points: string[];
  discontinue: string;
}

interface IProps {
  subjectRecordIds: string[];
  addSubject: (s: string) => any;
  close: () => any;
}

export const SSSQueryEditor = (props: IProps) => {
  const initialValues: ISSSQueryEditorFormState = {
    nameOrCode: "",
    type: [],
    level: [],
    areaOfStudy: [],
    owningOrg: [],
    points: [],
    discontinue: featureToggles.search ? "false" : "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={props.close}>
      <SSSQueryEditorFormContent {...props} />
    </Formik>
  );
};
