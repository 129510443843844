import React from "react";

import { assertThat } from "lib/assert";
import { IRulesProps } from "../common/types";
import { getMMSReferences } from "lib/parameters";
import { MMSTable } from "../common/mmsTable";

export const StaticMMSSet = (props: IRulesProps) => {
  const { rule } = props;
  assertThat(rule.children.length === 0, "MMS sets should not have children");

  const mmsRecordIds = getMMSReferences(rule);

  return <MMSTable mmsRecordIds={mmsRecordIds} />;
};
