import React from "react";

import { assertThat } from "lib/assert";
import { Aligned } from "components/divs/alignedDiv";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { getBooleanValue, getText } from "lib/parameters";
import styled from "styled-components";
import { HeaderActionContainer } from "../common/ruleComponent";
import { ActionMenu } from "../menu";
import { IRulesProps } from "../common/types";
import { MoveOptions } from "../moveOptions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Tooltip } from "@mui/material";
import { SuperscriptIcon } from "components/icons";

export const InfoContainer = styled.div`
  width: calc(100% - 1rem);
  margin-top: 0rem;
`;

export const P = styled.p`
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  margin-bottom: 2px;
  white-space: pre-wrap;
`;

export const Information = (props: IRulesProps) => {
  const { rule, parentTemplate } = props;

  assertThat(rule.children.length === 0, "Information should not have children");

  const selfEval = !!(rule && getBooleanValue("selfEvaluated")(rule));

  const text = getText(rule);

  return (
    <InfoContainer>
      <HeaderActionContainer>
        <Aligned style={{ alignItems: "start" }}>
          {props.dragHandle}
          {selfEval ? (
            <SuperscriptIcon
              icon={<InfoOutlined />}
              superScriptIcon={CheckCircleOutlineIcon}
              helpText={"Information rule (Self Evaluated)"}
            />
          ) : (
            <Tooltip title={"Information Rule"} placement="top" arrow>
              <InfoOutlined />
            </Tooltip>
          )}
          <P>{text}</P>
          &nbsp; &nbsp;
          <ActionMenu rule={rule} parentTemplate={parentTemplate} />
          <MoveOptions rule={rule} />
        </Aligned>
      </HeaderActionContainer>
    </InfoContainer>
  );
};
