import React from "react";

interface ITableCellProps {
  value?: string | number;
  alignment?: "left" | "center" | "right";
  bold?: boolean;
  width?: string | number;
}

export const MemoTableCell = React.memo(({ value, alignment, width }: ITableCellProps) => (
  <td style={{ width }} align={alignment}>
    {value}
  </td>
));
export const MemoTableCellBlueText = React.memo(({ value, alignment, bold, width }: ITableCellProps) => (
  <td align={alignment} style={{ width }}>
    <p className={bold ? "td-blue__bold" : "td-blue"}>{value}</p>
  </td>
));
