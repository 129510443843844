import React, { useEffect } from "react";
import { IRuleEditorProps } from "../common/types";
import { Field, useFormikContext } from "formik";
import { TextField, Switch, Select } from "formik-mui";
import { FormControl, Grid, FormControlLabel, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { commonStyles } from "theme/styles";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { IInfoEditorFormState } from "./InformationEditor";
import { Stereotype } from "store/types";
import { EditorForm } from "../common/ruleComponent";
import { getRuleUILabels } from "lib/rules";
import { featureToggles } from "config/featureToggles";
import { RulePreview } from "../common/rulePreview";
import { useFormatsSamplesAndTypes } from "../editor/editorHooks";

export const InformationEditorFields = (props: IRuleEditorProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule, changeRuleType, ruleType } = props;

  const formik = useFormikContext<IInfoEditorFormState>();

  // Notify the dispatcher that we need to render a new rule type form
  useEffect(() => {
    if (formik.values.type !== ruleType) {
      changeRuleType(formik.values.type);
    }
  }, [changeRuleType, formik.values.type, ruleType]);

  const { allowedFormats, samples, allRuleTypes } = useFormatsSamplesAndTypes(
    props,
    formik.values.selectedOrDefaultFormat,
  );

  return (
    <EditorForm onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-type-select" sx={styles.label}>
            Rule type
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="type"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps as any}
              inputProps={{ id: "rule-type-select" }}
              disabled={!!rule}
            >
              {allRuleTypes.map((t) => {
                const uiLabels = getRuleUILabels(t);
                return (
                  <MenuItem key={t} value={t}>
                    <LabelWithHint
                      label={
                        <div>
                          {uiLabels?.title}
                          {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                        </div>
                      }
                      helpText={uiLabels.helpText}
                    />
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="info-text" sx={styles.label}>
            <LabelWithHint label="Information details" helpText="Enter the rule or supporting text" />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="text"
              label=""
              fullWidth={true}
              variant="outlined"
              multiline
              rowsMax={10}
              rows={2}
              inputProps={{
                "aria-label": "Plan Name",
                id: "info-text",
              }}
            />
          </FormControl>
        </Grid>

        {featureToggles.linkText && (
          <Grid item xs={12}>
            <PaddedLabel htmlFor="info-link" sx={styles.label}>
              <LabelWithHint label="Published Text" helpText="Enter the Web hyperlink support text" />
            </PaddedLabel>
            <FormControl fullWidth={true} variant="outlined">
              <Field
                component={TextField}
                name="linkText"
                label=""
                fullWidth={true}
                variant="outlined"
                multiline
                rowsMax={10}
                rows={2}
                inputProps={{
                  "aria-label": "Published Text",
                  id: "info-link",
                }}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="end"
            control={<Field component={Switch} type="checkbox" name="selfEvaluated" color="primary" />}
            label={
              <LabelWithHint
                label="Is it self evaluated?"
                helpText="Selecting this will ensure students need to validate if they meet this requirement in My Course Planner. Use this if you are creating a rule, not supporting text. Do not use this in Non-allowed subjects."
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-presentation-format-select" sx={styles.label}>
            <LabelWithHint
              label="Published format"
              helpText="Choose the way this rule is presented in CAPS, the Handbook and My Course Planner, by selecting what information is displayed and how. This format will also interact with the parent (Rule Group) format."
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Published format",
                id: "rule-presentation-format-select",
              }}
            >
              {allowedFormats.map((f) => (
                <MenuItem style={{ whiteSpace: "normal" }} key={f.name} value={f.name}>
                  {f.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {samples.length > 0 && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={{
                ruleProps: {
                  stereotype: Stereotype.RuleComponent,
                  ruleType,
                  parameters: formik.values,
                },
                isValid: formik.isValid,
              }}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleComponent}
              ruleType={ruleType}
              ruleId={rule?.id}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <RuleFormActions
            ruleId={rule?.id}
            submitDisabled={!formik.isValid || !formik.dirty}
            submitting={formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </EditorForm>
  );
};
