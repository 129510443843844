import React from "react";

import { IRuleComponent, IState } from "store/types";
import { Aligned } from "components/divs/alignedDiv";
import { Collapse, IconButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { HeaderActionContainer, NextLevelContainer } from "../common/ruleComponent";
import styled from "styled-components";
import { ActionMenu } from "../menu";
import { useSelector } from "react-redux";
import { MoveOptions } from "../moveOptions";
import { LabelWithHint } from "components/help-hint/HelpHint";

interface IProps {
  children: any;
  rule: IRuleComponent;
}

const StyledHeaderActionContainer = styled(HeaderActionContainer)`
  // margin-top: 1rem;
  // border-top-style: groove;
`;

const H2 = styled.h2`
  padding-top: 0.25rem;
  margin: 0.25rem;
`;

export const SharedSetsHeading = (props: IProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  // const capsObject = useSelector((state: IState) => state.capsObject);
  const handleExpansion = React.useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);

  const { addSharedStaticSubjectSet, addSharedDynamicSubjectSet } = useSelector((s: IState) => s.editorState);
  const beingAddedTo = addSharedDynamicSubjectSet || addSharedStaticSubjectSet;

  React.useEffect(() => {
    if (beingAddedTo) {
      setExpanded(true);
    }
  }, [beingAddedTo, setExpanded]);

  const { rule, children } = props;

  return (
    <>
      <StyledHeaderActionContainer>
        <Aligned>
          <H2>
            <LabelWithHint
              label={
                <Aligned>
                  <BookmarkIcon /> Subject Lists
                </Aligned>
              }
              helpText="Create lists of subjects to use in rules. These lists can be used in as many rules as you like, and can contain individual subjects (Static subject list) or subjects grouped by linked parameters (Dynamic Subject List)."
            />
          </H2>
          <IconButton
            color="primary"
            onClick={handleExpansion}
            aria-label={expanded ? "expand less button" : "expand more button"}
            size="large"
          >
            {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
          <ActionMenu rule={rule} />
          <MoveOptions rule={rule} />
        </Aligned>
      </StyledHeaderActionContainer>
      <NextLevelContainer>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: "-webkit-fill-available" }}>
          {children}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
