import React from "react";
import { getSubjectDetails } from "actions/subjectDetails";
import { featureToggles } from "config/featureToggles";
import { MemoTableCell, MemoTableCellBlueText } from "lib/memoTable";
import { groupBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IParameter, IState } from "store/types";
import { brandLightBlue } from "theme/colors";
import { commonStyles } from "theme/styles";
import { useDeepCompareEffect } from "react-use";
import { useTheme } from "@mui/material";

interface IProps {
  subjectRecordIds: string[];
  rowEndAction?: (id: string) => any;
}
export const SubjectTable = ({ subjectRecordIds, rowEndAction }: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const dispatch = useDispatch();
  useDeepCompareEffect(() => {
    dispatch(getSubjectDetails(subjectRecordIds));
  }, [subjectRecordIds, dispatch]);

  const subjectDetails = useSelector((s: IState) => s.subjectDetails);
  const sharedComponentDetails = groupBy(
    useSelector((s: IState) => s.capsObject?.rules.sharedComponents || []),
    "id",
  );
  // console.log("sharedComponentDetails >>", sharedComponentDetails);

  return (
    <table style={styles.subjectTable}>
      <tbody>
        {subjectRecordIds.map((id, i) => {
          let s;
          let reference = "";
          let name = "";
          let points = "";
          let publishedYears = "";
          let discontinued = "";

          // FIXME legacy - do not use id to determine type
          if (featureToggles.search && id.match(/^SubjectSet/)) {
            s = sharedComponentDetails[id][0];
            reference = `${
              (s.parameters.find((p: IParameter) => p.name === "ssType")?.value as string) || "Subject"
            } Set`;
            name = s.parameters.find((p: IParameter) => p.name === "label")?.value as string;
          } else {
            s = subjectDetails[id];
            reference = s?.code || id;
            name = s?.name;
            points = s?.points.toString();
            publishedYears = s?.publishedYears ? `Published in ${s?.publishedYears}` : "";
            discontinued =
              s?.discontinue && (typeof s.discontinue === "string" ? s.discontinue === "true" : s.discontinue)
                ? `Discontinued`
                : "";
          }
          return (
            <tr
              key={id}
              style={{ ...styles.subjectTableRow, background: i % 2 === 0 ? "white" : brandLightBlue.toString() }}
            >
              <MemoTableCell alignment="left" width="80px" value={reference} />
              <MemoTableCellBlueText bold={true} alignment="left" value={name} />
              <MemoTableCell alignment="center" width="20px" value={points} />
              <MemoTableCell
                alignment="center"
                width="220px"
                value={featureToggles.search ? discontinued : publishedYears}
              />
              {rowEndAction !== undefined && rowEndAction(id)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
