import { isDescendant, isScrolledIntoView } from "lib/dom";
import { uniq, flatten } from "lodash";

const getElements = (ruleId?: string | null, templateRuleId?: string | null) => {
  if (!ruleId && !templateRuleId) {
    return [];
  }

  return uniq(
    [
      document.getElementById(ruleId ?? ""),
      document.getElementById(templateRuleId ?? ""),
      ...Array.from(ruleId ? document.querySelectorAll(`[data-parent='${ruleId}']`) : []),
      ...Array.from(ruleId ? document.querySelectorAll(`[data-rule-group='${ruleId}']`) : []),
      ...Array.from(templateRuleId ? document.querySelectorAll(`[data-parent='${templateRuleId}']`) : []),
      ...Array.from(templateRuleId ? document.querySelectorAll(`[data-rule-template='${templateRuleId}']`) : []),
    ].filter(Boolean) as HTMLElement[],
  );
};

const isUnrelatedTo = (e: HTMLElement, sharedRuleIds: string[]) => {
  return !sharedRuleIds.find(
    (id) =>
      id === toId(e?.id) ||
      id === toId(e?.dataset?.parent) ||
      id === toId(e?.dataset?.ruleTemplate) ||
      id === toId(e?.dataset?.ruleGroup),
  );
};

const getRelevantElements = (
  ruleId?: string | null,
  templateRuleId?: string | null,
  childrenIds: string[] = [],
  sharedRuleIds: string[] = [],
) => {
  let elements = uniq(
    [...getElements(ruleId, templateRuleId), ...flatten(childrenIds.map((cid) => getElements(cid)))].filter(
      Boolean,
    ) as HTMLElement[],
  ).filter((e) => isUnrelatedTo(e, sharedRuleIds));

  // Remove nodes, if their parent is in the list
  const hasNoParent = (e: any, ei: number) => !elements.find((o, oi) => oi !== ei && isDescendant(o, e));
  elements = elements.filter(hasNoParent);
  return elements;
};

export const higligth = (
  ruleId: string | undefined | null,
  templateId: string | undefined | null,
  childrenIds: string[],
  sharedRuleIds: string[],
) => {
  // Remove the highligthed class from elsewhere
  const elementsWithClass = Array.from(document.getElementsByClassName("higligth-rule-preview"));

  for (let i = 0; i < elementsWithClass.length; i++) {
    // debugger;
    elementsWithClass[i].classList.remove("higligth-rule-preview");
    // debugger;
  }

  const elements = getRelevantElements(ruleId, templateId, childrenIds, sharedRuleIds);

  // Scroll to the element
  if (elements.length > 0 && elements[0]) {
    if (!isScrolledIntoView(elements[0])) {
      elements[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  // Higligth all elements, except for the empty ones
  elements.forEach((e) => {
    if (e && !!e.innerHTML) {
      e.classList.add("higligth-rule-preview");
    }
  });
};

const toId = (id?: string | null) => (id && id.length > 5 ? id : null);
const getRuleId = (e?: HTMLElement | null) =>
  toId(e?.id) || toId(e?.dataset?.parent) || toId(e?.dataset?.ruleTemplate) || toId(e?.dataset?.ruleGroup);

export const getClickedRuleId = (e: MouseEvent) => {
  let target = e.target as HTMLElement | null;

  while (target && target.id !== "preview-drawer-container") {
    const ruleId = getRuleId(target);
    if (ruleId) {
      return ruleId;
    }
    target = target.parentElement;
  }
  return null;
};
