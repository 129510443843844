import React from "react";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Aligned } from "components/divs/alignedDiv";

interface ILabelledHintProps {
  helpText?: string;
  label: any;
  align?: boolean;
}

export const LabelWithHint = ({ label, helpText, align }: ILabelledHintProps) =>
  helpText ? (
    <Aligned style={{ alignItems: align ? "center" : "inherit" }}>
      {label}
      &nbsp;
      <Tooltip arrow title={helpText} placement="top">
        <sup>
          <HelpOutlineIcon style={{ fontSize: "16px", marginBottom: 0 }} />
        </sup>
      </Tooltip>
      &nbsp; &nbsp;
    </Aligned>
  ) : (
    label
  );
