import React, { useCallback, useMemo } from "react";
import { DialogContent, Typography, Slide, Dialog, Button, DialogActions, DialogTitle, useTheme } from "@mui/material";
import { ResponsiveCenter } from "components/spinner/Center";
import { IUsageChain } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { IComponentSummary, ICourse, IRuleComponent, IState } from "store/types";
import { commonStyles } from "theme/styles";
import { ruleLocationText } from "lib/rules";
import pluralize from "pluralize";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  flipWarningDialog: (ruleId: string | null) => any;
  rule: IRuleComponent;
}

const rulesAndComponents = (ruleNum: number, componentsNum: number) => {
  if (ruleNum <= 0 && componentsNum <= 0) {
    return "";
  }
  if (ruleNum <= 0) {
    return pluralize("component", componentsNum);
  }
  if (componentsNum <= 0) {
    return pluralize("rule", ruleNum);
  }
  return `${pluralize("rule", ruleNum)} and ${pluralize("component", componentsNum)}`;
};

const following = (ruleNum: number, componentsNum: number) => {
  if (ruleNum <= 0 && componentsNum <= 0) {
    return "";
  }
  return ruleNum + componentsNum === 1 ? "the following" : "all of the following";
};

export function SharedSubjectSetWarningModal(props: IProps) {
  const { flipWarningDialog, rule } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const commonStyling = commonStyles(theme);
  const usageChains: IUsageChain[][] | undefined = useSelector(
    (state: IState) => state.editorState.subjectSetUsageChains,
  );
  const allComponentReferences = useSelector((s: IState) => (s.capsObject as ICourse)?.componentReferences ?? []);
  const warningDialogOpenForRuleId = useSelector((s: IState) => s.editorState.warningDialogOpenForRuleId);
  const componentReferences = useMemo(
    () => allComponentReferences.filter((cr) => cr.subjectSetId === warningDialogOpenForRuleId),
    [allComponentReferences, warningDialogOpenForRuleId],
  );

  const handleClose = useCallback(() => {
    dispatch(flipWarningDialog(rule.id));
    // dispatch(setSubjectSetUsageChains(null));
  }, [dispatch, flipWarningDialog, rule]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle id="warning-dialog-title">
        <ResponsiveCenter>
          <Typography color="primary" variant="h6">
            Warning
          </Typography>
        </ResponsiveCenter>
      </DialogTitle>
      <DialogContent>
        <ResponsiveCenter>
          <Typography>
            Please note, this Shared Subject Set cannot be deleted as it is being used in the following{" "}
            {rulesAndComponents(usageChains?.length ?? 0, componentReferences?.length ?? 0)}:
          </Typography>
          <ul>
            {usageChains &&
              usageChains.map((chain: IUsageChain[], index: number) => (
                <li key={`dependent-rule-${index}`}>
                  <Typography>{ruleLocationText(chain)}</Typography>
                </li>
              ))}
            {componentReferences &&
              componentReferences.map((c) => (
                <li key={`dependent-component-${c.code}`}>
                  <Typography>
                    {c.name} ({c.code})
                  </Typography>
                </li>
              ))}
          </ul>
          <React.Fragment>
            <Typography>If you wish to delete, please remove all references to this Shared Subject Set.</Typography>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose} sx={commonStyling.saveButton}>
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        </ResponsiveCenter>
      </DialogContent>
    </Dialog>
  );
}

interface IUsageWarningProps {
  subjectSetUsageChains: IUsageChain[][];
  componentReferences?: IComponentSummary[];
}
export const SharedSubjectSetInlineWarning = ({ subjectSetUsageChains, componentReferences }: IUsageWarningProps) => (
  <React.Fragment>
    {subjectSetUsageChains && (
      <>
        <Typography>
          Please note, this Shared Subject Set is being used in the following{" "}
          {rulesAndComponents(subjectSetUsageChains.length, componentReferences?.length ?? 0)}. Any changes applied to{" "}
          this Shared Subject Set will be applied to{" "}
          {following(subjectSetUsageChains.length, componentReferences?.length ?? 0)} below.
        </Typography>
        <ul>
          {subjectSetUsageChains &&
            subjectSetUsageChains.map((chain: IUsageChain[], index: number) => (
              <li key={`dependent-rule-${index}`}>
                <Typography>{ruleLocationText(chain)}</Typography>
              </li>
            ))}
          {componentReferences &&
            componentReferences.map((c) => (
              <li key={`dependent-component-${c.code}`}>
                <Typography>
                  {c.name} ({c.code})
                </Typography>
              </li>
            ))}
        </ul>
      </>
    )}
  </React.Fragment>
);
