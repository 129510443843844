import React from "react";
import { IRulesProps } from "./common/types";
import { Stereotype } from "store/types";
import { getLabel } from "lib/parameters";
import { Droppable } from "react-beautiful-dnd";
import { RuleDispatcher } from "./RuleDispatcher";
import { DroppableFullWidth } from "./common/dnd";
import { ActionMenu } from "containers/rules/menu";
import { RuleGroupEditor } from "../rules/group/RuleGroupEditor";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { Aligned } from "components/divs/alignedDiv";
import { assertThat } from "lib/assert";
import { MoveOptions } from "./moveOptions";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { HeaderActionContainer } from "./common/ruleComponent";

export const RuleSection = (props: IRulesProps) => {
  const { rule, index } = props;
  assertThat(rule.stereotype === Stereotype.RuleSection, `Invalid section stereotype ${rule.stereotype}`);

  const label = getLabel(rule)?.trim();
  const headerText = label || rule.type;

  const addGroupToSectionId = useSelector((s: IState) => s.editorState.addGroupToSectionId);
  const beingAddedSectionTo = rule.id === addGroupToSectionId;
  const rulewithIndex = { ...rule, index };

  return (
    <Droppable droppableId={rule.id}>
      {(provided, snapshot) => (
        <DroppableFullWidth
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.draggingOverWith}
          tabIndex={-1}
        >
          <HeaderActionContainer>
            <Aligned>
              <h2>
                <LabelWithHint
                  label={headerText}
                  helpText="This is the CAPS field you are updating. Everything created in this section will appear in this field in CAPS."
                />
              </h2>
              <ActionMenu rule={rulewithIndex} />
              <MoveOptions rule={rule} />
            </Aligned>
          </HeaderActionContainer>
          {rule.children.map((c, i) => {
            return (
              <RuleDispatcher
                key={c.id}
                rule={c}
                level={props.level + 1}
                index={i}
                parentSection={rule}
                siblings={rule.children.length}
              />
            );
          })}
          {provided.placeholder}
          {beingAddedSectionTo ? <RuleGroupEditor {...props} parentSection={rule} rule={null} /> : null}{" "}
        </DroppableFullWidth>
      )}
    </Droppable>
  );
};
