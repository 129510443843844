import React, { useCallback, useEffect } from "react";

import { IRuleEditorProps } from "../common/types";
import { Formik, FormikHelpers } from "formik";
import {
  getLabel,
  getMax,
  getMin,
  getStaticSubjects,
  toNumber,
  getConcurrency,
  getBreadthDisciplineScope,
  isBreadthDynamicSubjectSet,
  getSubjectOmissions,
} from "lib/parameters";
import { assertThat } from "lib/assert";
import {
  BreadthDisciplineScope,
  ConcurrencyType,
  ICourse,
  IRuleComponent,
  IState,
  QualificationLevel,
  RuleComponentType,
  SubjectRuleSectionType,
} from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectDetails } from "actions/subjectDetails";
import { IRulesUpdateObj, updateCAPSObject } from "actions/capsObject";
import { getByRuleId } from "lib/rules";
import includes from "lodash/includes";
import { NumConstraintEditorFields } from "./NumConstraintEditorFields";
import { featureToggles } from "config/featureToggles";
import { validateValuePresence } from "lib/validation";

export interface INumEditorFormState {
  type: RuleComponentType;
  selectedOrDefaultFormat?: string;
  minimum?: number | null | "";
  maximum?: number | null | "";
  label?: string | null;
  breadthOrDisciplineScope: BreadthDisciplineScope;
  concurrency?: string | "" | null;
  subjectsAndSets: string[];
  subjectOmissions: string[] | [];
  tags: string[];
}

const validateFormState = (sharedComponents: IRuleComponent[]) => (values: INumEditorFormState) => {
  const errors: any = {};
  const min = toNumber(values.minimum);
  const max = toNumber(values.maximum);
  if (isNaN(min) && isNaN(max)) {
    errors["minimum"] = errors["maximum"] = "Specify at least one of min or max";
  }
  if (!isNaN(min) && !isNaN(max) && min > max) {
    errors["maximum"] = "Max must be greater or equal to min";
    errors["minimum"] = "Min must be less or equal to max";
  }
  if (values.subjectsAndSets.length === 0) {
    errors["subjectsAndSets"] = "Subject list may not be empty";
  }
  validateValuePresence(errors, values, "selectedOrDefaultFormat");

  // The warning
  const breadthSets = sharedComponents
    .filter((sc) => values.subjectsAndSets.find((ss) => ss === sc.id))
    .filter(isBreadthDynamicSubjectSet);
  if (values.breadthOrDisciplineScope === BreadthDisciplineScope.Discipline && breadthSets.length) {
    errors["subjectsAndSets"] = `Discipline only subject rules should not uses breadth dynamic sets: ${breadthSets
      .map(getLabel)
      .join(",")}`;
  }

  return errors;
};

export const NumConstraintEditor = (props: IRuleEditorProps) => {
  const { rule, onSubmit, ruleType, parentSection } = props;
  const parentSectionType = parentSection?.type;
  const useNonAllowedDefaults =
    SubjectRuleSectionType.NonAllowedSubjects === parentSectionType && !!featureToggles.nonAllowedDefaults;

  const subjectSetChild = (rule?.children || []).find((c) => c.type === RuleComponentType.SubjectSet);
  const subjectSetChildId = subjectSetChild?.id;
  const sharedComponents = useSelector((s: IState) => s.capsObject!.rules.sharedComponents || []);
  const parentSharedComponents = useSelector((s: IState) => s.parentSharedComponents || []);
  const validate = validateFormState(sharedComponents);
  const targetType = useSelector((s: IState) => s.targetType);
  const sharedComponentsIds = sharedComponents.map((s) => s.id);
  const parentSharedComponentsIds = parentSharedComponents.map((s) => s.id);
  const subjects = getStaticSubjects(subjectSetChild);
  const subjectsAndSets = subjectSetChild
    ? [...subjects, ...(rule?.childrenReferences || []), ...(rule?.externalReferences || [])]
    : [];
  const subjectOmissions = (rule && getSubjectOmissions(rule)) ?? [];

  // TODO Extract these constants somewhere ...
  const initialSelectedOrDefaultFormat =
    ruleType === RuleComponentType.CountConstraint ? "Subject List - No label or rule" : "Subject list - unlabelled";
  const selectedOrDefaultFormat = props.parentTemplate?.selectedOrDefaultFormat ?? initialSelectedOrDefaultFormat;

  const dispatch = useDispatch();
  useEffect(() => {
    if (subjects.length > 0) {
      dispatch(getSubjectDetails(subjects));
    }
    if (subjectOmissions.length > 0) {
      dispatch(getSubjectDetails(subjectOmissions));
    }
    // eslint-disable-next-line
  }, [subjects.join("-"), dispatch]);

  const defaultMin = useNonAllowedDefaults ? 0 : "";
  const defaultMax = useNonAllowedDefaults ? 0 : "";

  const qualificationType = useSelector((s: IState) => (s.capsObject! as ICourse).qualificationType || undefined);
  const isSecondaryCourse = !!qualificationType ? QualificationLevel.DIPLOMA === qualificationType.aqfLevel : false;

  const breadthOrDisciplineScope = getBreadthDisciplineScope(rule, targetType, isSecondaryCourse)!;
  const initialState: INumEditorFormState = {
    minimum: (rule && getMin(rule)) ?? defaultMin,
    maximum: (rule && getMax(rule)) ?? defaultMax,
    label: (rule && getLabel(rule)) ?? "",
    concurrency: (rule && getConcurrency(rule)) ?? ConcurrencyType.None,
    tags: (rule?.tags || []).map((t) => t.name),
    breadthOrDisciplineScope,
    subjectsAndSets,
    subjectOmissions,
    type: ruleType,
    selectedOrDefaultFormat,
  };

  const callOnSubmit = useCallback(
    (values: INumEditorFormState, helpers: FormikHelpers<INumEditorFormState>) => {
      const { subjectsAndSets, label, tags, ...params } = values;
      const childrenReferences = subjectsAndSets.filter((i) => includes(sharedComponentsIds, i));
      const externalReferences = subjectsAndSets.filter((i) => includes(parentSharedComponentsIds, i));

      const parentParams = { label, tags, ...params };
      const p = onSubmit(parentParams, childrenReferences, externalReferences).then((response: any) => {
        let ruleId = subjectSetChildId;
        if (!ruleId) {
          const parent = getByRuleId(response.value.rules, response.value.newlyCreatedRuleId);
          const subjectSetChild = (parent?.children || []).find((c) => c.type === RuleComponentType.SubjectSet);
          ruleId = subjectSetChild?.id;
        }

        if (!ruleId) {
          throw new Error(`Could not locate parent of Subject Set`);
        }

        const courseRulesUpdateObj: IRulesUpdateObj = {
          ruleId,
          ruleType: RuleComponentType.SubjectSet,
          parameters: {
            label,
            staticSubjectReferences: subjectsAndSets.filter(
              (i) => !includes(sharedComponentsIds, i) && !includes(parentSharedComponentsIds, i),
            ),
          },
        };

        dispatch(updateCAPSObject(courseRulesUpdateObj));
      });

      if (Promise.resolve(p) === p) {
        p.catch(() => helpers.setSubmitting(false));
      }
    },
    [dispatch, onSubmit, sharedComponentsIds, subjectSetChildId, parentSharedComponentsIds],
  );

  assertThat(
    [RuleComponentType.CountConstraint, RuleComponentType.PointsConstraint].indexOf(ruleType) >= 0,
    `Invalid rule type ${ruleType}`,
  );

  return (
    <Formik initialValues={initialState} validate={validate} onSubmit={callOnSubmit} enableReinitialize>
      <NumConstraintEditorFields {...props} />
    </Formik>
  );
};
