import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ICourse, IRuleComponent, IState, Stereotype } from "store/types";
import { SharedSetsHeading } from "./SharedSetsHeading";
import { assertThat } from "lib/assert";
import { SharedSubjectSet } from "./SharedSubjectSet";
import { DSSEditor } from "./dynamic/DSSEditor";
import { SharedSubjectSetWarningModal } from "./SharedSubjectSetWarningModal";
import { flipWarningDialog, setSubjectSetUsageChains } from "actions/editorState";
import { validateSharedSubjectSetUsage } from "lib/validation";
import { SSSEditor } from "./static/SSSEditor";

interface IProps {
  rule?: IRuleComponent | null;
}

export const SharedSubjectSets = ({ rule }: IProps) => {
  const dispatch = useDispatch();
  const sharedComponents = rule?.sharedComponents || [];
  const newlyCreatedRules = useSelector((s: IState) => s.capsObject?.newlyCreatedRules);
  const warningDialogOpenForRuleId = useSelector((s: IState) => s.editorState.warningDialogOpenForRuleId);
  const allComponentReferences = useSelector((s: IState) => (s.capsObject as ICourse)?.componentReferences ?? []);

  const ruleBeingUsed = (ruleId: string, showDialog?: boolean) => {
    if (rule) {
      const { used: usedInRules, usageChains } = validateSharedSubjectSetUsage(rule, ruleId);
      dispatch(setSubjectSetUsageChains(usageChains.length > 0 ? usageChains : null));
      const usedInComponents = !!allComponentReferences.find((cr) => cr.subjectSetId === ruleId);
      const used = usedInRules || usedInComponents;
      if (used && showDialog) {
        dispatch(flipWarningDialog(ruleId));
      }
      return used;
    }
    return false;
  };

  // Handle the editing
  const { addSharedStaticSubjectSet, addSharedDynamicSubjectSet } = useSelector((s: IState) => s.editorState);
  const beingAddedTo = addSharedDynamicSubjectSet || addSharedStaticSubjectSet;

  if (newlyCreatedRules || !rule) {
    return null;
  }
  assertThat(rule.stereotype === Stereotype.Rules, `Doesn't look like a root rule ${rule.stereotype}`);

  return (
    <>
      <SharedSetsHeading rule={rule}>
        {sharedComponents.map((r, i) => (
          <SharedSubjectSet key={r.id} rule={r} level={1} index={i} ruleBeingUsed={ruleBeingUsed} />
        ))}
        {sharedComponents.length === 0 && !beingAddedTo && <p>There are no shared subject sets.</p>}
        {addSharedDynamicSubjectSet && <DSSEditor />}

        {warningDialogOpenForRuleId && (
          <SharedSubjectSetWarningModal flipWarningDialog={flipWarningDialog} rule={rule} />
        )}
        {addSharedStaticSubjectSet && <SSSEditor />}
      </SharedSetsHeading>
    </>
  );
};
