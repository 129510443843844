import * as React from "react";
import Collapse from "@mui/material/Collapse";
import { RuleDispatcher } from "containers/rules/RuleDispatcher";
import { NextLevelContainer } from "containers/rules/common/ruleComponent";
import { IRulesProps } from "containers/rules/common/types";
import { IRuleComponent, IState } from "store/types";
import { useSelector } from "react-redux";
import { DraggableConstraintHeader } from "containers/rules/common/draggableConstraintHeader";
import { OperatorHeader, OperatorLink } from "containers/rules/group/operator";
import { getLabel, getOperator } from "lib/parameters";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { EditorDispatcher } from "../component/EditorDispatcher";
import { RuleGroupEditor } from "./RuleGroupEditor";
import { RuleBracketLabel } from "./RuleBracketLabel";
import { groupHelpHint } from "./groupConst";
import { useSize } from "lib/useSize";

interface IProps extends IRulesProps {
  controlledExpansion: {
    expanded: boolean;
    handleExpansion: () => void;
  };
  dndPlaceholder?: any;
  parentSection?: IRuleComponent;
}

export const NestedGroup = (props: IProps) => {
  const sharedComponents = useSelector((s: IState) => s.capsObject!.rules.sharedComponents || []);
  const { rule, level, controlledExpansion, parentSection, parentTemplate } = props;

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const moveInProgress = !!moveRuleState;
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  // Whether we're using local state or delegated from parent
  const expanded = controlledExpansion.expanded;
  const handleExpansion = controlledExpansion.handleExpansion;

  // Compute the size of the header
  const headerRef = React.useRef<any | null>(null);
  const headerSize = useSize(headerRef);

  // Handle the editing
  const editedRuleId = useSelector((s: IState) => s.editorState.editedRuleId);
  const editMode = rule.id === editedRuleId && !isBeingMoved;

  const addRuleToRuleId = useSelector((s: IState) => s.editorState.addRuleToRuleId);
  const beingAddedToMode = rule.id === addRuleToRuleId && !isBeingMoved;

  const addGroupToRuleId = useSelector((s: IState) => s.editorState.addGroupToRuleId);
  const beingAddedGroupTo = rule.id === addGroupToRuleId && !isBeingMoved;

  const operator = getOperator(rule);
  const label = (<LabelWithHint label={getLabel(rule) || ""} helpText={groupHelpHint} />) as any;

  // For groups we'll need an operator
  // const operator = getOperator(rule);
  const nChildren = rule.children.length;

  const open = expanded && !isBeingMoved;
  const draggableConstraintHeaderProps = {
    expanded: open,
    dragHandle: !moveInProgress && props.dragHandle,
    handleExpansion,
    label,
    level,
    rule,
    parentTemplate,
    tagPreview: true,
  };
  return (
    <>
      <DraggableConstraintHeader ref={headerRef} {...draggableConstraintHeaderProps} />
      <NextLevelContainer>
        <Collapse in={open} timeout={0} mountOnEnter style={{ width: "-webkit-fill-available" }}>
          {editMode ? <RuleGroupEditor {...props} /> : undefined}
          <OperatorHeader operator={operator} level={level} numOperands={nChildren} />
          {rule.children.map((c, i) => (
            <React.Fragment key={c.id}>
              <RuleDispatcher rule={c} level={level + 1} index={i} parentSection={parentSection} siblings={nChildren} />
              {i < nChildren - 1 && <OperatorLink operator={operator} level={level} index={i} />}
            </React.Fragment>
          ))}

          {(rule.childrenReferences || []).map((cr, i) => {
            const component = sharedComponents.find((sc) => sc.id === cr);
            return !component ? null : (
              <React.Fragment key={cr}>
                <RuleDispatcher
                  rule={component}
                  level={level + 1}
                  index={i + rule.children.length}
                  parentSection={parentSection}
                  siblings={nChildren}
                />
                {i < nChildren - 1 && <OperatorLink operator={operator} level={level} index={i} />}
              </React.Fragment>
            );
          })}

          {expanded && !editMode && <RuleBracketLabel group={rule} level={level} topOffset={headerSize?.height ?? 0} />}

          {beingAddedToMode ? (
            <EditorDispatcher level={level} parentSection={parentSection} />
          ) : beingAddedGroupTo ? (
            <RuleGroupEditor {...props} rule={null} parentSection={parentSection} />
          ) : undefined}
        </Collapse>
        {props.dndPlaceholder}
      </NextLevelContainer>
    </>
  );
};
