import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, FormControl, MenuItem, Tooltip } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Level, AreaOfStudy, Discontinue, StudyType, CreditPoints } from "lib/searchConstants";
import hash from "object-hash";
import identity from "lodash/identity";
import { useDispatch, useSelector } from "react-redux";
import { executeSubjectQuery } from "actions/subjectQueryResult";
import { IState, ISubjectSearchSummary } from "store/types";
import { useDebounce } from "lib/useDebounce";
import { ISSSQueryEditorFormState } from "./SSSQueryEditor";
import { Select, TextField } from "formik-mui";
import { SelectMenuProps } from "../../common/forms";
import { EditorWrapper, MultiSelectField } from "../common";
import { ActionSubjectCard } from "../../searchResults/subjectCard/ActionSubjectCard";
import Spinner from "components/spinner";
import { AutocompleteMultiSelect } from "components/autocomplete/AutocompleteMultiSelect";
import { featureToggles } from "config/featureToggles";
import { SubjectSearchResults } from "containers/rules/searchResults/common/common";
import { sortBy } from "lodash";
import { formatLevel, parseLevelVlues } from "lib/level";
import { getOrgUnitDefinition, OrgUnits } from "lib/orgUnits";

const areaOfStudyDescription = (value: string) => AreaOfStudy.find((aos) => aos.code === value)?.description || value;
const areaOfStudyCodes = AreaOfStudy.map((aos) => aos.code);

const areaOfStudyLabel = (value: string) => {
  const areaOfStudy = AreaOfStudy.find((aos) => aos.code === value);
  return areaOfStudy ? `${areaOfStudy.description} (${areaOfStudy.code})` : value;
};

const owningOrgCodes = featureToggles.search ? (OrgUnits as any[]).map((org) => org.code) : OrgUnits;
const owningOrgLabel = (value: string) => {
  if (featureToggles.search) {
    const orgUnit = getOrgUnitDefinition(value);
    return `${orgUnit?.code ?? "⚠️ Invalid unit"} - ${orgUnit?.description ?? value}`;
  }
  return value;
};

interface IProps {
  subjectRecordIds: string[];
  addSubject: (s: string) => any;
}

export const SSSQueryEditorFormContent = ({ subjectRecordIds, addSubject }: IProps) => {
  const formik = useFormikContext<ISSSQueryEditorFormState>();
  const { values, setFieldValue, handleSubmit } = formik;
  const [searchInProgress, setSearchInProgress] = useState(false);
  const debouncedNameOrCode = useDebounce(values.nameOrCode, 250).trim();

  const queryHash = useMemo(
    () => hash({ ...values, nameOrCode: debouncedNameOrCode }, { ignoreUnknown: true, unorderedArrays: true }),
    [values, debouncedNameOrCode],
  );
  const dispatch = useDispatch();

  // Whenever the query changes - fire a request
  useEffect(() => {
    setSearchInProgress(true);

    const p = dispatch(executeSubjectQuery(values)) as any;
    if (Promise.resolve(p) === p) {
      p.finally(() => setSearchInProgress(false));
    } else {
      setSearchInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryHash, setSearchInProgress]);

  const onMultiSelectChange = useCallback(
    (valuesKey: keyof ISSSQueryEditorFormState) => (newValue: string[]) => {
      setFieldValue(valuesKey, newValue);
    },
    [setFieldValue],
  );

  const onCreditPointsChange = (e: any) => {
    const value = e.target.value;
    if (value.match(/\./)) {
      const arr = value.split(".");
      if (arr.length <= 2 && arr[1].length <= 2) setFieldValue("points", value);
    } else {
      setFieldValue("points", value);
    }
  };

  const subjectQueryResult = useSelector((s: IState) => s.subjectQueryResult?.result || []);
  // Sort subject results by code, followed by name
  // NOTE: results from Caps are limited to sorting by one field, currently code
  const uiSortedSubjectQueryResult = sortBy(subjectQueryResult, ["code", "name"]);

  return (
    <EditorWrapper>
      <form onSubmit={handleSubmit}>
        {featureToggles.search ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth={true}>
                <Tooltip
                  arrow
                  placement="top"
                  title="When typing a subject code, ensure it is an exact match (with no spaces before or after). Alternatively, use the criteria provided below."
                >
                  <Field
                    component={TextField}
                    name="nameOrCode"
                    label=""
                    fullWidth={true}
                    variant="outlined"
                    placeholder={"Subject codes or name"}
                    inputProps={{
                      "aria-label": "Subject name or code",
                      id: "nameOrCode-course-code-text-field",
                    }}
                  />
                </Tooltip>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteMultiSelect
                id="area-of-study-list"
                options={areaOfStudyCodes}
                formValue={values.areaOfStudy}
                formatLabel={areaOfStudyLabel}
                onChange={onMultiSelectChange("areaOfStudy")}
                placeholder="Area of Study"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteMultiSelect
                id="owning-org-unit-list"
                options={owningOrgCodes}
                formValue={values.owningOrg}
                formatLabel={owningOrgLabel}
                onChange={onMultiSelectChange("owningOrg")}
                placeholder="Owning Org Unit"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultiSelectField
                id="level-select"
                name="level"
                formValue={parseLevelVlues(values.level)}
                allValues={Level}
                placeholder="Select levels"
                formatLabel={formatLevel}
                onClear={() => setFieldValue("level", [])}
                renderValue={(n) => `${n} levels selected`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                component={TextField}
                name="points"
                label=""
                type="number"
                fullWidth={true}
                onChange={onCreditPointsChange}
                variant="outlined"
                placeholder="Select subject points"
                inputProps={{
                  "aria-label": "Subject credit points",
                  id: "points-text-field",
                  step: 0.01,
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultiSelectField
                id="type-select"
                name="type"
                formValue={values.type}
                allValues={StudyType}
                placeholder="Study Type"
                formatLabel={identity}
                onClear={() => setFieldValue("type", [])}
                renderValue={(n) => `${n} study types selected`}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth={true}>
                <Field
                  component={Select}
                  name="discontinue"
                  fullWidth={true}
                  variant="outlined"
                  MenuProps={SelectMenuProps}
                  displayEmpty={true}
                >
                  {Discontinue.map((d) => (
                    <MenuItem key={d} value={d}>
                      {d === "" ? "Both discontinued and not" : d === "true" ? "Discontinued" : "Not Discontinued"}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth={true}>
                <Field
                  component={TextField}
                  name="nameOrCode"
                  label=""
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Subject codes or name"
                  inputProps={{
                    "aria-label": "Subject name or code",
                    id: "nameOrCode-course-code-text-field",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultiSelectField
                id="type-select"
                name="type"
                formValue={values.type}
                allValues={StudyType}
                placeholder="Study Type"
                formatLabel={identity}
                onClear={() => setFieldValue("type", [])}
                renderValue={(n) => `${n} study types selected`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultiSelectField
                id="level-select"
                name="level"
                formValue={parseLevelVlues(values.level)}
                allValues={Level}
                placeholder="Select levels"
                formatLabel={formatLevel}
                onClear={() => setFieldValue("level", [])}
                renderValue={(n) => `${n} levels selected`}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <AutocompleteMultiSelect
                id="area-of-study-list"
                options={areaOfStudyCodes}
                formValue={values.areaOfStudy}
                formatLabel={areaOfStudyDescription}
                onChange={onMultiSelectChange("areaOfStudy")}
                placeholder="Area of Study"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteMultiSelect
                id="owning-org-unit-list"
                options={OrgUnits}
                formValue={values.owningOrg}
                formatLabel={identity}
                onChange={onMultiSelectChange("owningOrg")}
                placeholder="Owning Org Unit"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <MultiSelectField
                id="points-select"
                name="points"
                formValue={values.points}
                allValues={CreditPoints.map(String)}
                placeholder="Select subject points"
                formatLabel={(l: string) => `${l} pts`}
                onClear={() => setFieldValue("points", [])}
                renderValue={(n) => `${n} points options selected`}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth={true}>
                <Field
                  component={Select}
                  name="discontinue"
                  fullWidth={true}
                  variant="outlined"
                  MenuProps={SelectMenuProps}
                  displayEmpty={true}
                >
                  <MenuItem value={""}>
                    <em>Both discontinued and not</em>
                  </MenuItem>
                  {Discontinue.map((d) => (
                    <MenuItem key={d} value={d}>
                      {d === "true" ? "Discontinued" : "Not Discontinued"}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </form>
      <SubjectSearchResults>
        {searchInProgress && <Spinner loading={searchInProgress} />}
        {!searchInProgress && subjectQueryResult.length === 0 && <p>No matching subjects were found.</p>}
        {!searchInProgress && subjectQueryResult.length > 0 && (
          <Grid container spacing={1}>
            {((featureToggles.search ? uiSortedSubjectQueryResult : subjectQueryResult) as ISubjectSearchSummary[]).map(
              (s, i) => (
                <Grid item key={`${s.code}-${i}`} xs={12} sm={6} md={4}>
                  <ActionSubjectCard subject={s} selectedRecordIds={subjectRecordIds} addSubject={addSubject} />
                </Grid>
              ),
            )}
          </Grid>
        )}
      </SubjectSearchResults>
    </EditorWrapper>
  );
};
