import React, { useState, useEffect, useCallback } from "react";
import { getLabel, getMMSReferences, getCourseReferences } from "lib/parameters";
import { IRulesProps } from "../common/types";
import { IState } from "store/types";
import { Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NextLevelContainer } from "../common/ruleComponent";
import styled from "styled-components";
import { DraggableConstraintHeader } from "../common/draggableConstraintHeader";
import { getCoursesAndMMS } from "actions/courses";
import { MMSTableComponent } from "../common/mmsTable";
import { CoursesTableComponent } from "../common/coursesTable";
import { Aligned } from "components/divs/alignedDiv";
import HelpIcon from "@mui/icons-material/HelpOutline";

const Strong = styled.strong`
  margin-left: 1.5rem;
  font-size: 95%;
`;

export const AdmissionWarning = React.memo(() => (
  <Aligned style={{ width: "100%", alignItems: "top" }}>
    <HelpIcon fontSize="small" />
    <p style={{ marginLeft: "0.5rem", marginTop: 0, paddingTop: 0 }}>
      Please select one or more courses and/or components that apply to this rule. The rule will be valid if the student
      is enrolled in{" "}
      <u>
        <strong>any</strong>
      </u>{" "}
      of the selected courses and components listed in either of the scopes below.
    </p>
  </Aligned>
));

export const Admission = (props: IRulesProps) => {
  const { rule, level, dragHandle, parentSection, parentTemplate } = props;

  const label = getLabel(rule);
  // const suffix = numericLabelSuffix(rule, rule.type === RuleComponentType.PointsConstraint ? "pts" : "");
  // const label = [ruleLabel, suffix].filter(Boolean).join(" / ");

  const embeddedSet = rule.children[0];
  const mmsRecordIds = getMMSReferences(embeddedSet);
  const isEmbeddedMMSSetEmpty = mmsRecordIds.length === 0;
  const courseRecordIds = getCourseReferences(embeddedSet);
  const isEmbeddedCourseSetEmpty = courseRecordIds.length === 0;

  const expandedSections = useSelector((s: IState) => s.editorState.expandedSections);
  const expandParentSection = (expandedSections && parentSection && expandedSections[parentSection.id]) || false;

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  /** set expanded to false on initial render, and then use value from expandParentSection or handleExpansion */
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(expandParentSection);
  }, [expandParentSection]);

  // reset to false on initial render
  useEffect(() => {
    setExpanded(false);
  }, []);
  const handleExpansion = useCallback(() => {
    if (!isBeingMoved) {
      setExpanded((oldValue) => !oldValue);
    }
  }, [isBeingMoved]);

  // Load the details
  const dispatch = useDispatch();
  useEffect(() => {
    if (expanded) {
      dispatch(getCoursesAndMMS(courseRecordIds, mmsRecordIds));
    }
  }, [courseRecordIds, dispatch, mmsRecordIds, expanded]);

  const draggableConstraintHeaderProps = {
    expanded: expanded && !isBeingMoved,
    dragHandle,
    handleExpansion,
    label: label ? `${label} / Admission Rule` : "Admission Rule",
    level,
    rule,
    parentTemplate,
  };

  return (
    <>
      <DraggableConstraintHeader {...draggableConstraintHeaderProps} />
      <NextLevelContainer>
        <Collapse in={expanded} timeout={0} mountOnEnter style={{ width: "-webkit-fill-available" }}>
          {!isEmbeddedCourseSetEmpty && !isEmbeddedMMSSetEmpty && (
            <NextLevelContainer>
              <AdmissionWarning />
            </NextLevelContainer>
          )}

          {!isEmbeddedMMSSetEmpty && (
            <>
              <Strong>Included MMS:</Strong>
              <NextLevelContainer>
                <MMSTableComponent mmsRecordIds={mmsRecordIds} />
              </NextLevelContainer>
            </>
          )}
          {!isEmbeddedCourseSetEmpty && (
            <>
              <Strong>Included Courses:</Strong>
              <NextLevelContainer>
                <CoursesTableComponent courseRecordId={courseRecordIds} />
              </NextLevelContainer>
            </>
          )}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
