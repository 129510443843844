import { GET_CAPS_OBJ } from "actions/capsObject";
import { failed, fulfilled, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const loading = (state = initialState.loading, action: any) => {
  switch (action.type) {
    case pending(GET_CAPS_OBJ):
      return true;
    case failed(GET_CAPS_OBJ):
    case fulfilled(GET_CAPS_OBJ):
      return false;
  }
  return state;
};

export default loading;
