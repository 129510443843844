import { featureToggles } from "config/featureToggles";
import { IState } from "./types";

const initialState: IState = {
  loading: false,
  submittingChanges: false,
  capsObject: null,
  parentSharedComponents: [],
  dependents: [],
  moveRuleState: null,
  subjectDetails: {},
  mmsDetails: {},
  courses: {},
  tokenValidated: null,
  editorState: {},
  sysId: null,
  targetType: null,
  templates: [],
  snackBarState: null,
  optimisticUpdate: null,
  subjectQueryResult: null,
  mmsQueryResult: null,
  sessionError: null,
  previewDrawerState: {
    open: !!featureToggles.previewOpenByDefault,
    width: "max(300px, 30%)",
  },
};

export default initialState;
