import React from "react";
import { DialogContent, Typography, Slide, Dialog, Button, DialogActions, DialogTitle, useTheme } from "@mui/material";

import { commonStyles } from "theme/styles";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  onCancel: (e: any) => void;
  onDelete: (e: any) => void;
  open: boolean;
}

export function DeleteConfirmationModal(props: IProps) {
  const { onDelete, onCancel, open } = props;
  const theme = useTheme();
  const styles = commonStyles(theme);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle id="warning-dialog-title">
        <Typography color="primary" variant="h6">
          Confirm action
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Deleting cannot be undone. Would you like to proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onCancel} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onDelete} sx={styles.deleteButton}>
            Delete
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}
