import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, FormControl, Theme, Button, useTheme } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { ComponentTypes } from "lib/searchConstants";
import hash from "object-hash";
import { useDispatch, useSelector } from "react-redux";
import { IComponentSearchSummary, IState, RuleTargetType } from "store/types";
import { useDebounce } from "lib/useDebounce";
import { IMMSQueryEditorFormState } from "./MMSQueryEditor";
import { TextField } from "formik-mui";
import { EditorWrapper } from "../../../rules/sharedRules/common";
import { AutocompleteMultiSelect } from "components/autocomplete/AutocompleteMultiSelect";
import { executeMMSQuery } from "actions/mmsQueryResult";
import { SubjectSearchResults, useCardStyles } from "containers/rules/searchResults/common/common";
import Spinner from "components/spinner";
import { MMSTable } from "containers/rules/common/mmsTable";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { sortBy } from "lodash";

const componentTypeLabel = (value: string) => {
  const component = ComponentTypes.find((type) => type.value === value);
  return component ? component.label : value;
};

const componentTypeValues: string[] = (ComponentTypes as any[]).map((type) => type.value);

interface IProps {
  mmsRecordIds: string[];
  addMMS: (s: string) => any;
}

const useStyles = (theme: Theme) => ({
  customWidthTextField: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});

export const MMSQueryEditorFormContent = ({ mmsRecordIds, addMMS }: IProps) => {
  const formik = useFormikContext<IMMSQueryEditorFormState>();
  const { values, setFieldValue, handleSubmit } = formik;
  const theme = useTheme();
  const styles = useStyles(theme);
  const cardStyles = useCardStyles(theme);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const targetType = useSelector((s: IState) => s.targetType);

  // targetType is course or component
  const debouncedNameOrCode = useDebounce(values?.nameOrCode || "", 250).trim();

  // targetType is subject
  const debouncedParentCourseNameOrCode = useDebounce(values?.parentCourseNameOrCode || "", 250).trim();

  const queryHash = useMemo(
    () =>
      hash(
        { ...values, nameOrCode: debouncedNameOrCode, parentCourseNameOrCode: debouncedParentCourseNameOrCode },
        { ignoreUnknown: true, unorderedArrays: true },
      ),
    [values, debouncedNameOrCode, debouncedParentCourseNameOrCode],
  );
  const dispatch = useDispatch();

  // Whenever the query changes - fire a request
  useEffect(() => {
    setSearchInProgress(true);

    const p = dispatch(executeMMSQuery(values)) as any;
    if (Promise.resolve(p) === p) {
      p.finally(() => setSearchInProgress(false));
    } else {
      setSearchInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryHash, setSearchInProgress]);

  const onMultiSelectChange = useCallback(
    (valuesKey: keyof IMMSQueryEditorFormState) => (newValue: string[]) => {
      setFieldValue(valuesKey, newValue);
    },
    [setFieldValue],
  );

  const onCreditPointsChange = (e: any) => {
    const value = e.target.value;
    if (value.match(/\./)) {
      const arr = value.split(".");
      if (arr.length <= 2 && arr[1].length <= 2) setFieldValue("points", value);
    } else {
      setFieldValue("points", value);
    }
  };

  const mmsQueryResult = useSelector((s: IState) => s.mmsQueryResult?.result || []);
  // Sort mms results by component_name, followed by component_type, and then by parent_course.course_code
  // NOTE: results from Caps are limited to sorting by one field, currently name
  const uiSortedMMSQueryResult = sortBy(mmsQueryResult, ["name", "type", "parentCourse.code"]);
  const mmsQueryResultRecordIds = (uiSortedMMSQueryResult as IComponentSearchSummary[]).map((mms) => mms.recordId);
  const renderTypeAndPoints = () => (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <AutocompleteMultiSelect
          id="type"
          options={componentTypeValues}
          formValue={values.type}
          formatLabel={componentTypeLabel}
          onChange={onMultiSelectChange("type")}
          placeholder="Component Type"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          component={TextField}
          name="points"
          label=""
          type="number"
          fullWidth={true}
          onChange={onCreditPointsChange}
          variant="outlined"
          placeholder="Select component points"
          inputProps={{
            "aria-label": "Component credit points",
            id: "points-text-field",
            step: 0.01,
          }}
        />
      </Grid>
    </>
  );
  return (
    <EditorWrapper>
      <form onSubmit={handleSubmit}>
        {targetType === RuleTargetType.Subject ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl sx={styles.customWidthTextField}>
                <Field
                  component={TextField}
                  name="code"
                  label=""
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Component code"
                  inputProps={{
                    "aria-label": "Component code",
                    id: "code-component-code-text-field",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              Or
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth={true}>
                <Field
                  component={TextField}
                  name="name"
                  label=""
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Component name"
                  inputProps={{
                    "aria-label": "Component name",
                    id: "name-component-name-text-field",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth={true}>
                <Field
                  component={TextField}
                  name="parentCourseNameOrCode"
                  label=""
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Parent course code or name"
                  inputProps={{
                    "aria-label": "Parent course name or code",
                    id: "parentCourseNameOrCode-component-code-text-field",
                  }}
                />
              </FormControl>
            </Grid>
            {renderTypeAndPoints()}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth={true}>
                <Field
                  component={TextField}
                  name="nameOrCode"
                  label=""
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Component code or name"
                  inputProps={{
                    "aria-label": "Component name or code",
                    id: "nameOrCode-component-code-text-field",
                  }}
                />
              </FormControl>
            </Grid>
            {renderTypeAndPoints()}
          </Grid>
        )}
      </form>
      <SubjectSearchResults>
        {searchInProgress && <Spinner loading={searchInProgress} />}
        {!searchInProgress && mmsQueryResult.length === 0 && <p>No matching components were found.</p>}
        {!searchInProgress && mmsQueryResult.length > 0 && (
          <Grid container spacing={0}>
            <MMSTable
              mmsRecordIds={mmsQueryResultRecordIds}
              rowEndAction={(id: string) =>
                mmsRecordIds.includes(id) ? (
                  <td>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<CheckCircleOutline sx={cardStyles.successIcon} />}
                    >
                      Added
                    </Button>
                  </td>
                ) : (
                  <td align="center">
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={() => addMMS(id)}
                    >
                      Add
                    </Button>
                  </td>
                )
              }
            />
          </Grid>
        )}
      </SubjectSearchResults>
    </EditorWrapper>
  );
};
