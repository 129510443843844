import React, { useCallback } from "react";
import { Formik } from "formik";
import { IAreaOfStudy } from "lib/searchConstants";
import { DSSQueryEditorFormContent } from "./DSSQueryEditorFormContent";
import { useSelector } from "react-redux";
import { IState, RuleTargetType } from "store/types";
import { parseLevelVlues } from "lib/level";
import { getOrgUnitCode } from "lib/orgUnits";

export interface IDSSQueryEditorFormState {
  level: string[];
  areaOfStudy: IAreaOfStudy[];
  owningOrg: string[];
  breadthCourseCode?: boolean;
}

interface IProps {
  query: any;
  changeQuery: (newQuery: any) => any;
  close: () => any;
}

export const DSSQueryEditor = ({ query, changeQuery, close }: IProps) => {
  const initialValues: IDSSQueryEditorFormState = {
    level: (query?.level ?? []).map(String),
    areaOfStudy: query?.areaOfStudy ?? [],
    owningOrg: getOrgUnitCode(query?.owningOrg ?? []) as string[],
  };
  const targetType = useSelector((s: IState) => s.targetType);
  if (targetType !== RuleTargetType.Subject) {
    initialValues.breadthCourseCode = !!query?.breadthCourseCode;
  }

  const callOnSubmit = useCallback(
    (values: IDSSQueryEditorFormState) => {
      const mappedValues = {
        ...values,
        level: parseLevelVlues(values.level),
      };
      changeQuery(mappedValues);
    },

    [changeQuery],
  );
  return (
    <Formik initialValues={initialValues} onSubmit={callOnSubmit}>
      <DSSQueryEditorFormContent close={close} />
    </Formik>
  );
};
