import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./containers/App";
import store from "./store";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme/material";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById("root") as HTMLElement,
);
