import { applyMoveOptimistically } from "lib/rules";
import { IRulesMove } from "store/types";
// import { get, patch, post, put } from "lib/fetch";
import store from "../store";

export const MOVE_RULE_OPTIMISTICALLY = "optimisticUpdate/MOVE_RULE";

export const moveRuleOptimistically = (params: IRulesMove) => {
  const {
    capsObject: { rules },
  } = store.getState();
  return {
    type: MOVE_RULE_OPTIMISTICALLY,
    payload: rules && applyMoveOptimistically(rules, params),
  };
};
