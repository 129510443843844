export const isDescendant = (parent: HTMLElement | null, child: HTMLElement | null) => {
  if (!child || !parent) {
    return false;
  }

  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }
    // Traverse up to the parent
    node = node.parentNode;
  }
  // Go up until the root but couldn't find the `parent`
  return false;
};

export const isScrolledIntoView = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Only completely visible elements return true:
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};
