import React from "react";
import { IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { updateRawObject, cancelMigratedSubjectRules } from "actions/capsObject";
import { BannerComponent } from "./common/banner";

/** Deprecated: [CPT-604] No longer require user input, automatically save rules with default Rule Groups */
export const RuleSubjectActionBar = () => {
  const capsObject = useSelector((state: IState) => state.capsObject);
  const dispatch = useDispatch();
  const updateSubject = () => {
    dispatch(updateRawObject(capsObject?.rules));
  };

  const cancelRules = () => {
    dispatch(cancelMigratedSubjectRules());
  };

  const body = (
    <p>The rules below have been generated automatically. Please review and decide if you wish to apply them or not.</p>
  );

  const buttons = [
    {
      label: "Apply Rules",
      onClick: updateSubject,
    },
    { label: "Cancel", onClick: cancelRules },
  ];
  return <BannerComponent heading="Review rules" body={body} buttons={buttons} />;
};
