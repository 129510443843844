import React from "react";

import { Stereotype } from "store/types";
import { assertThat } from "lib/assert";
import { RuleDispatcher } from "./RuleDispatcher";
import { IRulesProps } from "./common/types";

export const RuleTemplate = (props: IRulesProps) => {
  const { rule, level, parentSection } = props;
  assertThat(rule.stereotype === Stereotype.RuleTemplate, `Invalid template stereotype ${rule.stereotype}`);

  const type = rule.type.trim();

  const isPtsConstraint = type.match(/points constraint$/gi);
  const isCountConstraint = type.match(/count constraint( mms)?$/gi);
  const isInformation = type.match(/Information$/gi);
  const isAdmission = type.match(/Admission$/gi);
  const isProgression = type.match(/Progression$/gi);
  const isTCR = type.match(/Total Course Points$/gi);

  if (isPtsConstraint || isInformation || isCountConstraint || isAdmission || isProgression || isTCR) {
    return (
      <>
        {rule.children.map((c, i) => (
          <RuleDispatcher
            parentSection={parentSection}
            parentTemplate={rule}
            key={c.id}
            rule={c}
            level={level}
            index={rule.children.length === 1 ? props.index : i}
            siblings={rule.children.length === 1 ? props.siblings : rule.children.length}
          />
        ))}
      </>
    );
  }

  throw new Error(`Unknown template type ${type}`);
};
