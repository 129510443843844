import React, { useCallback } from "react";
import { ISubjectSearchSummary } from "store/types";
import { Card, CardHeader, CardContent, Chip, Button, Tooltip, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CardSubheading, CardName, useCardStyles, OwningOrg } from "../common/common";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import SchoolIcon from "@mui/icons-material/School";

export interface IProps {
  subject: ISubjectSearchSummary;
  selectedRecordIds: string[];
  addSubject: (s: string) => any;
}

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ActionSubjectCard = (props: IProps) => {
  const { subject, addSubject } = props;
  const theme = useTheme();
  const styles = useCardStyles(theme);
  const areas = subject.areaOfStudy.map((aos) => aos.description).join(",");

  // CAPS doesn't return availabilities on search
  const availabilities: string[] = [];
  // const availabilities = uniq((subject.availability || []).map((a) => a.study_period));

  const isSelected = !!props.selectedRecordIds.find((r) => subject.recordId === r);

  const recordId = subject.recordId;
  const onAdd = useCallback(() => addSubject(recordId), [recordId, addSubject]);

  return (
    <Card sx={styles.card} elevation={2}>
      <CardHeader color="primary" title={areas} titleTypographyProps={{ variant: "inherit" }} sx={styles.cardHeader} />
      <CardContent sx={styles.cardContent}>
        <CardSubheading>
          <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
        </CardSubheading>
        <NameContainer>
          <CardName>{subject.name}</CardName>

          {!isSelected && (
            <Button variant="text" color="primary" startIcon={<AddIcon />} onClick={onAdd}>
              Add
            </Button>
          )}

          {isSelected && (
            <Button variant="text" color="primary" startIcon={<CheckCircleOutline sx={styles.successIcon} />}>
              Added
            </Button>
          )}
        </NameContainer>
        {subject.owningOrg && (
          <Tooltip placement="top-start" title="Owning Organisation">
            <OwningOrg>
              <SchoolIcon /> &nbsp; {subject.owningOrg}
            </OwningOrg>
          </Tooltip>
        )}
        {availabilities.map((a) => (
          <React.Fragment key={a}>
            <Chip label={a} /> &nbsp;
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
};
