import { fulfilled, failed } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_CAPS_OBJ, RESAVE_CAPS_OBJ } from "actions/capsObject";
import { featureToggles } from "config/featureToggles";
import { IRuleComponent } from "store/types";
import { cloneDeep } from "lodash";
import { getLabel, isStaticSubjectSet, setLabel } from "lib/parameters";

const isVersionless = (rule: IRuleComponent) => isStaticSubjectSet(rule) && rule.id.endsWith(":Versionless");

const versionlessSubjectSets = (sharedComponents: IRuleComponent[]) => {
  const result: IRuleComponent[] = [];
  for (const sharedComponent of sharedComponents) {
    result.push(sharedComponent);
    if (isStaticSubjectSet(sharedComponent) && !isVersionless(sharedComponent)) {
      const versionlessSharedComponent = cloneDeep(sharedComponent);
      versionlessSharedComponent.id = `${sharedComponent.id}:Versionless`;
      const label = getLabel(versionlessSharedComponent);
      setLabel(versionlessSharedComponent, `${label} (Versionless)`);
      result.push(versionlessSharedComponent);
    }
  }

  return result;
};

const parentSharedComponents = (state = initialState.parentSharedComponents, action: any = {}) => {
  const { payload } = action;
  switch (action.type) {
    case fulfilled(GET_CAPS_OBJ):
    case fulfilled(RESAVE_CAPS_OBJ):
      let sharedComponents = payload.parentSharedComponents ?? state ?? [];
      if (featureToggles.versionLess) {
        sharedComponents = versionlessSubjectSets(sharedComponents);
      }

      return sharedComponents;
    case failed(GET_CAPS_OBJ):
      return [];
    default:
      return state;
  }
};

export default parentSharedComponents;
