import React, { useCallback } from "react";

import { IRuleEditorProps } from "../common/types";
import { Formik, FormikHelpers } from "formik";
import { getNumValue } from "lib/parameters";
import { assertThat } from "lib/assert";
import { RuleComponentType } from "store/types";
import { validateValuePresence } from "lib/validation";
import { ProgressionEditorFields } from "./ProgressionEditorFields";
import { useAllowedFormats } from "../editor/editorHooks";

export interface IProgressionEditorFormState {
  type: RuleComponentType;
  selectedOrDefaultFormat?: string;
  points?: number;
  level?: number;
}

const validateFormState = (values: IProgressionEditorFormState) => {
  const errors: any = {};
  validateValuePresence(errors, values, "level");
  validateValuePresence(errors, values, "points");
  return errors;
};

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const ProgressionEditor = (props: Props) => {
  const { rule, onSubmit, ruleType } = props;
  const allowedFormats = useAllowedFormats(props);

  const initialState: IProgressionEditorFormState = {
    // label: (rule && getLabel(rule)) ?? "",
    points: (rule && getNumValue("points")(rule)) ?? 50,
    level: (rule && getNumValue("level")(rule)) || 1,
    type: RuleComponentType.Progression,
    selectedOrDefaultFormat: props.parentTemplate?.selectedOrDefaultFormat ?? allowedFormats[0]?.name,
  };

  const callOnSubmit = useCallback(
    (values: IProgressionEditorFormState, helpers: FormikHelpers<IProgressionEditorFormState>) => {
      const p = onSubmit({ ...values, label: "" });
      if (Promise.resolve(p) === p) {
        p.catch(() => helpers.setSubmitting(false));
      }
    },
    [onSubmit],
  );

  assertThat([RuleComponentType.Progression].indexOf(ruleType) >= 0, `Invalid rule type ${ruleType}`);

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      <ProgressionEditorFields {...props} />
    </Formik>
  );
};
