import React, { useCallback, useMemo } from "react";
import { IMenuRuleComponent, IRulesMove, IState, Stereotype } from "store/types";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { getByRuleId, getChildrenIdsByStereotype, getParentContainerId } from "lib/rules";
import { moveRuleOptimistically } from "actions/optimisticUpdate";
import { moveRule } from "actions/capsObject";
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { beige } from "theme/colors";

interface IProps {
  rule: IMenuRuleComponent;
}

const defaultOnClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const MoveOptions = (props: IProps) => {
  // Controls when menu is open/closed
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    defaultOnClick(event);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { rule } = props;
  const ruleId = rule.id;
  const numChildren = rule.children.length;
  const isSelection = rule.stereotype === Stereotype.RuleSection;
  const isGroup = rule.stereotype === Stereotype.RuleGroup;

  const moveRuleState = useSelector((state: IState) => state.moveRuleState);
  const movedRuleId = moveRuleState?.ruleId;
  const movedIsCopy = moveRuleState?.copy;
  const isMovedRuleGroup = moveRuleState?.stereotype === Stereotype.RuleGroup;
  const rootRules = useSelector((state: IState) => state.capsObject?.rules);
  const capsObjectRuleId = useSelector((state: IState) => state.capsObject?.rules?.id);

  // Compute the parent, siblings, index etc
  const parentId = useMemo(() => getParentContainerId(rootRules, ruleId), [rootRules, ruleId]);
  const parent = useMemo(() => getByRuleId(rootRules, parentId), [parentId, rootRules]);
  const isRootGroup = !parent || parent.stereotype !== Stereotype.RuleGroup;
  const siblings = useMemo(
    () => getChildrenIdsByStereotype(rootRules, parentId, [Stereotype.RuleGroup, Stereotype.RuleComponent]),
    [parentId, rootRules],
  );
  const ruleIndex = siblings.indexOf(ruleId);
  const movedRuleIndex = movedRuleId ? siblings.indexOf(movedRuleId) : null;
  const movedRuleIsPriorSibling = movedRuleIndex !== null && movedRuleIndex >= 0 && movedRuleIndex < ruleIndex;

  // Prepare the callbacks
  const dispatch = useDispatch();
  const onMoveUp = useCallback(
    (e: any) => {
      defaultOnClick(e);
      handleClose();
      if (capsObjectRuleId && movedRuleId && parentId) {
        const move: IRulesMove = {
          ruleId: capsObjectRuleId,
          movedRuleId: movedRuleId,
          movedToRuleId: parentId,
          copy: movedIsCopy,
          position: movedRuleIsPriorSibling ? ruleIndex - 1 : ruleIndex,
        };
        dispatch(moveRuleOptimistically(move));
        dispatch(moveRule(move));
      }
    },
    [capsObjectRuleId, dispatch, handleClose, movedIsCopy, movedRuleId, movedRuleIsPriorSibling, parentId, ruleIndex],
  );
  const onMoveDown = useCallback(
    (e: any) => {
      defaultOnClick(e);
      handleClose();

      if (capsObjectRuleId && movedRuleId && parentId) {
        const move: IRulesMove = {
          ruleId: capsObjectRuleId,
          movedRuleId: movedRuleId,
          movedToRuleId: parentId,
          copy: movedIsCopy,
          position: movedRuleIsPriorSibling ? ruleIndex : ruleIndex + 1,
        };
        dispatch(moveRuleOptimistically(move));
        dispatch(moveRule(move));
      }
    },
    [capsObjectRuleId, dispatch, handleClose, movedIsCopy, movedRuleId, movedRuleIsPriorSibling, parentId, ruleIndex],
  );
  const onAppend = useCallback(
    (e: any) => {
      defaultOnClick(e);
      handleClose();
      if (capsObjectRuleId && movedRuleId) {
        const move: IRulesMove = {
          ruleId: capsObjectRuleId,
          movedRuleId: movedRuleId,
          movedToRuleId: ruleId,
          copy: movedIsCopy,
          position: numChildren,
        };
        dispatch(moveRuleOptimistically(move));
        dispatch(moveRule(move));
      }
    },
    [handleClose, capsObjectRuleId, movedRuleId, ruleId, movedIsCopy, numChildren, dispatch],
  );

  if (!moveRuleState || rule.id === moveRuleState.ruleId || (isSelection && !isMovedRuleGroup)) {
    return null;
  }

  return (
    <>
      &nbsp;&nbsp;
      <Tooltip title="Paste rule ..." placement="top">
        <IconButton size="small" onClick={handleClick}>
          <i className="material-icons" style={{ fontSize: "1em", background: beige.toString() }}>
            content_paste
          </i>
        </IconButton>
      </Tooltip>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!isSelection && (!isRootGroup || isMovedRuleGroup) && (
          <MenuItem onClick={onMoveUp}>
            <ListItemIcon>
              <PublishIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Paste rule above" />
          </MenuItem>
        )}
        {!isSelection && (!isRootGroup || isMovedRuleGroup) && (
          <MenuItem onClick={onMoveDown}>
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Paste rule below" />
          </MenuItem>
        )}
        {isSelection && isMovedRuleGroup && (
          <MenuItem onClick={onAppend}>
            <ListItemIcon>
              <PlaylistAddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Add rule to section/group" />
          </MenuItem>
        )}
        {isGroup && (
          <MenuItem onClick={onAppend}>
            <ListItemIcon>
              <PlaylistAddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Add rule to section/group" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
