import React, { useCallback } from "react";
import { IRuleEditorProps } from "../common/types";
import { Formik, FormikHelpers } from "formik";
import { getBooleanValue, getLinkText, getText } from "lib/parameters";
import { validateValuePresence } from "lib/validation";
import { InformationEditorFields } from "./InformationEditorFields";
import { RuleComponentType } from "store/types";
import { useAllowedFormats } from "../editor/editorHooks";

export interface IInfoEditorFormState {
  type: RuleComponentType;
  selectedOrDefaultFormat?: string;
  text: string;
  linkText?: string;
  selfEvaluated: boolean;
}

const validateFormState = (values: IInfoEditorFormState) => {
  const errors: any = {};
  validateValuePresence(errors, values, "text");
  validateValuePresence(errors, values, "selectedOrDefaultFormat");
  return errors;
};

export const InformationEditor = (props: IRuleEditorProps) => {
  const { rule, onSubmit } = props;
  const allowedFormats = useAllowedFormats(props);

  const initialState: IInfoEditorFormState = {
    linkText: (rule && getLinkText(rule)) || "",
    text: (rule && getText(rule)) || "",
    selfEvaluated: !!(rule && getBooleanValue("selfEvaluated")(rule)),
    type: RuleComponentType.Information,
    selectedOrDefaultFormat: props.parentTemplate?.selectedOrDefaultFormat ?? allowedFormats[0]?.name,
  };

  const callOnSubmit = useCallback(
    (values: IInfoEditorFormState, helpers: FormikHelpers<IInfoEditorFormState>) => {
      const p = onSubmit(values);
      if (Promise.resolve(p) === p) {
        p.catch(() => helpers.setSubmitting(false));
      }
    },
    [onSubmit],
  );

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      <InformationEditorFields {...props} />
    </Formik>
  );
};
