import React from "react";
import { RoutedProps } from "store/routerTypes";

export const Homepage = (_props: RoutedProps) => {
  return (
    <>
      <p>homepage</p>
    </>
  );
};
