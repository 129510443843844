import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import keyBy from "lodash/keyBy";
import { GET_SUBJECT_BY_CODE, GET_SUBJECT_DETAILS } from "actions/subjectDetails";
import { EXECUTE_SUBJECT_QUERY } from "actions/subjectQueryResult";

const subjectDetails = (state = initialState.subjectDetails, action: any) => {
  switch (action.type) {
    case fulfilled(GET_SUBJECT_DETAILS):
    case fulfilled(GET_SUBJECT_BY_CODE):
      return {
        ...state,
        ...keyBy(action.payload, (e) => e.recordId),
      };
    case fulfilled(EXECUTE_SUBJECT_QUERY):
      return {
        ...state,
        ...keyBy(action.payload.result, (e) => e.recordId),
      };
    default:
      return state;
  }
};

export default subjectDetails;
