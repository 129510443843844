import initialState from "store/initialState";
import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import {
  UPDATE_CAPS_OBJ,
  ADD_CAPS_OBJ,
  POST_RULEGROUP_CAPS_OBJ,
  MOVE_RULE,
  UPDATE_CAPS_OBJ_RAW,
  ADD_SHARED_SUBJECT_SET,
  RESAVE_CAPS_OBJ,
} from "actions/capsObject";
import { GET_SUBJECT_DETAILS } from "actions/subjectDetails";
import { GET_VALIDATETOKEN } from "actions/token";

import { DELETE_RULE_EDITOR } from "actions/editorState";
import { GET_MMS_DETAILS } from "actions/mmsDetails";
import { GET_COURSES_AND_MMSES, SEARCH_COURSE_BY_NAME } from "actions/courses";

const submittingChanges = (state = initialState.submittingChanges, action: any) => {
  switch (action.type) {
    case pending(UPDATE_CAPS_OBJ):
    case pending(RESAVE_CAPS_OBJ):
    case pending(ADD_CAPS_OBJ):
    case pending(ADD_SHARED_SUBJECT_SET):
    case pending(POST_RULEGROUP_CAPS_OBJ):
    case pending(GET_SUBJECT_DETAILS):
    case pending(GET_MMS_DETAILS):
    case pending(GET_VALIDATETOKEN):
    case pending(DELETE_RULE_EDITOR):
    case pending(MOVE_RULE):
    case pending(UPDATE_CAPS_OBJ_RAW):
    case pending(SEARCH_COURSE_BY_NAME):
    case pending(GET_COURSES_AND_MMSES):
      return true;
    case failed(UPDATE_CAPS_OBJ):
    case fulfilled(UPDATE_CAPS_OBJ):
    case failed(RESAVE_CAPS_OBJ):
    case fulfilled(RESAVE_CAPS_OBJ):
    case failed(ADD_CAPS_OBJ):
    case fulfilled(ADD_CAPS_OBJ):
    case failed(ADD_SHARED_SUBJECT_SET):
    case fulfilled(ADD_SHARED_SUBJECT_SET):
    case failed(POST_RULEGROUP_CAPS_OBJ):
    case fulfilled(POST_RULEGROUP_CAPS_OBJ):
    case failed(GET_SUBJECT_DETAILS):
    case fulfilled(GET_SUBJECT_DETAILS):
    case failed(GET_MMS_DETAILS):
    case fulfilled(GET_MMS_DETAILS):
    case fulfilled(SEARCH_COURSE_BY_NAME):
    case failed(SEARCH_COURSE_BY_NAME):
    case failed(GET_VALIDATETOKEN):
    case fulfilled(GET_VALIDATETOKEN):
    case failed(DELETE_RULE_EDITOR):
    case fulfilled(DELETE_RULE_EDITOR):
    case failed(MOVE_RULE):
    case fulfilled(MOVE_RULE):
    case failed(UPDATE_CAPS_OBJ_RAW):
    case fulfilled(UPDATE_CAPS_OBJ_RAW):
    case fulfilled(GET_COURSES_AND_MMSES):
    case failed(GET_COURSES_AND_MMSES):
      return false;
  }
  return state;
};

export default submittingChanges;
