import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { commonStyles } from "theme/styles";
import { Aligned } from "components/divs/alignedDiv";
import { HeaderActionContainer, headingForLevel } from "./ruleComponent";
import { IRuleComponent, Stereotype } from "store/types";
import { ActionMenu } from "../menu";
import { MoveOptions } from "../moveOptions";
import { TagsPreview } from "components/tags/tagsPreview";
import { Tooltip, useTheme } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { brandBlue, validationRed } from "theme/colors";
import styled from "styled-components";

interface IDraggableActionHeaderProps {
  label?: any;
  level: number;
  expanded: boolean;
  tagPreview?: boolean;
  dragHandle?: any;
  rule: IRuleComponent;
  parentTemplate?: IRuleComponent;
  error?: string | null;
  handleExpansion?: () => any;
}
const useStyles = () => ({
  icon: {
    // padding: "6px 6px 6px 0px",
    fill: "white",
  },
  clickable: {
    cursor: "pointer",
  },
});

const GroupIconWrapper = styled.div`
  border-radius: 50%;
  background: ${brandBlue.toString()};
  display: flex;
  justify-content: center;
  align-items: "center;
  margin-right: 10px;
  margin-left: 2px;
`;

const RuleIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: "center;
  margin-right: 10px;
  margin-left: 2px;
`;

const DraggableConstraintHeaderRef = (
  {
    expanded,
    dragHandle,
    handleExpansion,
    label,
    level,
    rule,
    parentTemplate,
    tagPreview,
    error,
  }: IDraggableActionHeaderProps,
  ref: any,
) => {
  const theme = useTheme();
  const commonStyling = commonStyles(theme);
  const styles = useStyles();
  const H = headingForLevel(level);

  const presentAsGroup = rule.stereotype === Stereotype.RuleGroup || level === 2;
  const IconWrapper = presentAsGroup ? GroupIconWrapper : RuleIconWrapper;

  return (
    <HeaderActionContainer
      role="button"
      tabIndex={0}
      aria-expanded={expanded ? true : false}
      onClick={handleExpansion}
      style={styles.clickable}
      ref={ref}
    >
      <Aligned>
        {dragHandle}
        <IconWrapper>
          {expanded ? (
            <ExpandLess sx={presentAsGroup ? styles.icon : undefined} fontSize="small" />
          ) : (
            <ExpandMore sx={presentAsGroup ? styles.icon : undefined} fontSize="small" />
          )}
        </IconWrapper>
        <H style={presentAsGroup ? commonStyling.groupLabel : commonStyling.label}>{label}</H>
        &nbsp; &nbsp;
        {error && (
          <>
            <Tooltip title={error} placement="top" arrow>
              <WarningIcon style={{ fill: validationRed.toString() }} fontSize="small" />
            </Tooltip>
            &nbsp;
          </>
        )}
        {tagPreview && <TagsPreview rule={rule} />}
      </Aligned>
      <ActionMenu rule={rule} parentTemplate={parentTemplate} />
      <MoveOptions rule={rule} />
    </HeaderActionContainer>
  );
};

export const DraggableConstraintHeader = React.forwardRef(DraggableConstraintHeaderRef);
