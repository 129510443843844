import { fulfilled, failed } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { GET_CAPS_OBJ } from "actions/capsObject";

const dependents = (state = initialState.capsObject, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case fulfilled(GET_CAPS_OBJ):
      return payload.dependents ?? [];
    case failed(GET_CAPS_OBJ):
      return [];
    default:
      return state;
  }
};

export default dependents;
