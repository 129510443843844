import { Tooltip } from "@mui/material";
import { getBreadthDisciplineScope } from "lib/parameters";
import React from "react";
import { BreadthDisciplineScope, IRuleComponent, RuleTargetType } from "store/types";
import styled from "styled-components";

interface IBreadhDisciplineIconProps {
  rule: IRuleComponent;
  targetType: RuleTargetType | null;
}

export const IconWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  background: #b2b2b2;
  color: white;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: SANS-SERIF;
  font-size: 12px;
  font-weight: bold;
`;

export const BreadthDisciplineScopeIcons = ({ rule, targetType }: IBreadhDisciplineIconProps) => {
  if (!targetType || targetType === RuleTargetType.Subject) {
    return null;
  }
  const scope = getBreadthDisciplineScope(rule, targetType);

  let result: any = null;
  switch (scope) {
    case BreadthDisciplineScope.Breadth:
      result = (
        <Tooltip title="Only Breadth Subjects" placement="top" arrow>
          <IconWrapper>B</IconWrapper>
        </Tooltip>
      );
      break;
    case BreadthDisciplineScope.Discipline:
      result = (
        <Tooltip title="Only Discipline Subjects" placement="top" arrow>
          <IconWrapper>D</IconWrapper>
        </Tooltip>
      );
      break;
    case BreadthDisciplineScope.Breadth_and_Discipline:
      result = (
        <>
          <Tooltip title="Breadth Subjects" placement="top" arrow>
            <IconWrapper>B</IconWrapper>
          </Tooltip>
          &nbsp;
          <Tooltip title="Discipline Subjects" placement="top" arrow>
            <IconWrapper>D</IconWrapper>
          </Tooltip>
        </>
      );
      break;
  }

  return <>&nbsp;&nbsp;{result}</>;
};
