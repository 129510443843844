import React, { useCallback, useMemo } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "lib/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getMMSByCode, getMMSByNameOrCode } from "actions/mmsDetails";
import { IState, IMMS } from "store/types";
import uniq from "lodash/uniq";
import { featureToggles } from "config/featureToggles";

interface IProps {
  mmsRecordIds: string[];
  onMMSRecordIdsChanged: (ids: string[]) => any;
  errorMesage?: string | null;
}

export default function MMSSearch(props: IProps) {
  const { mmsRecordIds, onMMSRecordIdsChanged, errorMesage } = props;
  const [extraOptions, setExtraOptions] = React.useState<string[]>([]);
  const [text, setText] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const mmsDetails = useSelector((s: IState) => s.mmsDetails);

  const dispatch = useDispatch();

  const debouncedText = useDebounce(text, 500).trimStart();

  React.useEffect(() => {
    if (text.trim()) {
      setLoading(true);
    }
  }, [text]);

  const clearOptions = useCallback(() => setExtraOptions([]), [setExtraOptions]);
  const clearText = useCallback(() => setText(""), [setText]);
  const updateText = useCallback((e: any) => setText(e?.target?.value || ""), [setText]);

  React.useEffect(() => {
    if (debouncedText.trim()) {
      setExtraOptions([]);
      let searchText;
      if (featureToggles.search) {
        searchText = debouncedText.trimStart();
      } else {
        searchText = debouncedText.trim().toUpperCase();
      }

      const result = dispatch(featureToggles.search ? getMMSByNameOrCode(searchText) : getMMSByCode(searchText)) as any;
      result
        .then((resp: { value: IMMS[] }) => {
          const matchigRecordIds = resp.value.map((s) => s.recordId);
          setExtraOptions([...matchigRecordIds]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debouncedText, setExtraOptions, setLoading, dispatch]);

  const onMMSSelection = useCallback(
    (_e: any, newValue: string[]) => {
      onMMSRecordIdsChanged(newValue);
      setText("");
      setExtraOptions([]);
    },
    [onMMSRecordIdsChanged],
  );

  const getOptionLabel = useCallback(
    (option: string) => {
      const s = mmsDetails[option];
      return s ? `${s.type}: ${s.name} (${s.code})` : option;
    },
    [mmsDetails],
  );

  const options = useMemo(() => uniq([...mmsRecordIds, ...extraOptions]), [mmsRecordIds, extraOptions]);

  return (
    <>
      <Autocomplete
        id="mms-code-search"
        onOpen={clearOptions}
        onClose={clearText}
        clearOnEscape={true}
        filterSelectedOptions={true}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        multiple={true}
        value={mmsRecordIds}
        onChange={onMMSSelection}
        inputValue={text}
        onInputChange={updateText}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            helperText={errorMesage || undefined}
            error={!!errorMesage}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
