import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IRuleEditorProps } from "../common/types";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { Button, FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, ScopeContainer, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { getTemplateParamsByName } from "lib/parameters";
import {
  RuleComponentType,
  IState,
  RuleTargetType,
  SubjectRuleSectionType,
  IParameter,
  ParameterName,
  ConcurrencyType,
  BreadthDisciplineScope,
  Stereotype,
} from "store/types";
import { SubjectSearch } from "./SubjectSearch";
import { useSelector } from "react-redux";
import { commonStyles } from "theme/styles";
import { INumEditorFormState } from "./NumConstraintEditor";
import { SSSSearchModal } from "../sharedRules/static/SSSSearchModal";
import { uniq } from "lodash";
import { featureToggles } from "config/featureToggles";
import { LabelWithHint } from "components/help-hint/HelpHint";
import includes from "lodash/includes";
import { EditorForm } from "../common/ruleComponent";
import { useFormatsSamplesAndTypes } from "../editor/editorHooks";
import { RulePreview } from "../common/rulePreview";
import { getRuleUILabels } from "lib/rules";
import { useDeepCompareMemo } from "use-deep-compare";
import { AutocompleteMultiSelect } from "../../../components/autocomplete/AutocompleteMultiSelect";
import { CommonTag, DestinationTag, getTagLabel } from "../../../lib/tags";

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const NumConstraintEditorFields = (props: Props) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule, ruleType, parentSection, changeRuleType } = props;
  const [finderOpen, setFinderOpen] = useState(false);
  const flipFinder = useCallback(() => setFinderOpen((old) => !old), []);

  const [finderOpenExcluded, setFinderOpenExcluded] = useState(false);
  const flipFinderExcluded = useCallback(() => setFinderOpenExcluded((old) => !old), []);
  const targetType = useSelector((state: IState) => state.targetType);

  const sharedComponentsIds = useSelector((s: IState) => (s.capsObject!.rules.sharedComponents || []).map((s) => s.id));
  const parentSharedComponentsIds = useSelector((s: IState) => (s.parentSharedComponents || []).map((s) => s.id));

  const parentSectionType = parentSection?.type;
  const useNonAllowedDefaults =
    SubjectRuleSectionType.NonAllowedSubjects === parentSectionType && !!featureToggles.nonAllowedDefaults;

  const ruleTypeTemplate = useSelector((state: IState) => state.templates).find((t) => t.type === ruleType);
  const ruleTypeParams = getTemplateParamsByName(ruleType, ruleTypeTemplate);

  const formik = useFormikContext<INumEditorFormState>();
  const { setFieldValue } = formik;

  const { allowedFormats, samples, allRuleTypes } = useFormatsSamplesAndTypes(
    props,
    formik.values.selectedOrDefaultFormat,
  );

  const supportedTags: string[] = Object.values(DestinationTag);
  if (targetType === RuleTargetType.Component && featureToggles.countAllSubjectsForComponentTag) {
    supportedTags.push(CommonTag.COUNT_ALL_SUBJECTS);
  }

  const Tags = uniq([...supportedTags, ...formik.initialValues.tags]);

  // Notify the dispatcher that we need to render a new rule type form
  useEffect(() => {
    if (formik.values.type !== ruleType) {
      changeRuleType(formik.values.type);
    }
  }, [changeRuleType, formik.values.type, ruleType]);

  const showConcurrencyType = useCallback(() => {
    return (
      targetType === RuleTargetType.Subject &&
      (parentSection?.type === SubjectRuleSectionType.Corequisites ||
        parentSection?.type === SubjectRuleSectionType.Prerequisites)
    );
  }, [targetType, parentSection]);

  const addSubject = (id: string) => {
    setFieldValue("subjectsAndSets", uniq([...formik.values.subjectsAndSets, id]));
  };
  const deleteSubject = (id: string) => {
    const without = formik.values.subjectsAndSets.filter((s) => s !== id);
    setFieldValue("subjectsAndSets", without);
  };

  const addExcludedSubject = (id: string) => {
    setFieldValue("subjectOmissions", uniq([...formik.values.subjectOmissions, id]));
  };
  const deleteExcludedSubject = (id: string) => {
    const without = formik.values.subjectOmissions.filter((s) => s !== id);
    setFieldValue("subjectOmissions", without);
  };

  const onTagsChange = useCallback(
    (newTagNames: string[]) => {
      formik.setFieldValue("tags", newTagNames);
    },
    [formik],
  );

  useEffect(() => {
    switch (parentSectionType) {
      case SubjectRuleSectionType.Corequisites:
        setFieldValue("concurrency", ConcurrencyType.Corequisite);
        break;
      case SubjectRuleSectionType.Prerequisites:
        if (!rule) {
          setFieldValue("concurrency", ConcurrencyType.Prerequisite);
        }
        break;
    }
  }, [rule, ruleType, parentSectionType, setFieldValue]);

  const previewReq = useDeepCompareMemo(() => {
    const embeddedChildRuleProps = {
      ruleType: RuleComponentType.SubjectSet,
      parameters: {
        label: formik.values.label,
        staticSubjectReferences: formik.values.subjectsAndSets.filter(
          (i) => !includes(sharedComponentsIds, i) && !includes(parentSharedComponentsIds, i),
        ),
      },
    };
    const childrenReferences = formik.values.subjectsAndSets.filter((i) => includes(sharedComponentsIds, i));
    const externalReferences = formik.values.subjectsAndSets.filter((i) => includes(parentSharedComponentsIds, i));
    return {
      ruleProps: {
        stereotype: Stereotype.RuleComponent,
        ruleType,
        parameters: formik.values,
        childrenReferences,
        externalReferences,
      },
      embeddedChildRuleProps,
      isValid: formik.isValid,
    };
  }, [formik.values, sharedComponentsIds, formik.isValid, ruleType]);

  const allConcurrencyTypes = useMemo(() => {
    const concurrencyTypes = rule
      ? rule?.parameters.find((p: IParameter) => p.name === ParameterName.Concurrency)?.enumValues || []
      : ruleTypeParams?.find((p) => p.name === ParameterName.Concurrency)?.enumValues || [];

    return concurrencyTypes?.filter((ct) => ct !== ConcurrencyType.None) || [];
  }, [ruleTypeParams, rule]);

  const allowedConcurrencyTypes =
    parentSectionType === SubjectRuleSectionType.Prerequisites
      ? allConcurrencyTypes.filter((ct) => ct !== ConcurrencyType.Corequisite)
      : parentSectionType === SubjectRuleSectionType.Corequisites
      ? allConcurrencyTypes.filter((ct) => ct === ConcurrencyType.Corequisite)
      : allConcurrencyTypes;

  return (
    <EditorForm onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-type-select" sx={styles.label}>
            Rule type
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="type"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps as any}
              inputProps={{ id: "rule-type-select" }}
              disabled={!!rule}
            >
              {allRuleTypes.map((t) => {
                const uiLabels = getRuleUILabels(t);
                return (
                  <MenuItem key={t} value={t}>
                    <LabelWithHint
                      label={
                        <div>
                          {uiLabels?.title}
                          {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                        </div>
                      }
                      helpText={uiLabels.helpText}
                    />
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <PaddedLabel htmlFor="label-text" sx={styles.label}>
            <LabelWithHint
              label="Label"
              helpText="Give your rule or subject options a label. You can use this label in your Published Format to replace subject codes with identifying text, or you can use it for administrative reasons. If you leave this empty, either the subject codes or the subject list labels will be used."
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="label"
              label=""
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Rule Label",
                id: "label-text",
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <PaddedLabel htmlFor="minimum-text" sx={styles.label}>
            <LabelWithHint
              label="Minimum"
              helpText="Either a minimum or maximum needs to be entered. Complete only one field if an exact number is not required."
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="minimum"
              label=""
              fullWidth={true}
              variant="outlined"
              type="number"
              disabled={useNonAllowedDefaults}
              inputProps={{
                "aria-label": "minimum",
                id: "minimum-text",
                min: 0,
                step: RuleComponentType.PointsConstraint === ruleType ? 6.25 : 1,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <PaddedLabel htmlFor="maximum-text" sx={styles.label}>
            Maximum
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="maximum"
              label=""
              fullWidth={true}
              variant="outlined"
              type="number"
              disabled={useNonAllowedDefaults}
              inputProps={{
                "aria-label": "maximum",
                id: "maximum-text",
                min: 0,
                step: RuleComponentType.PointsConstraint === ruleType ? 6.25 : 1,
              }}
            />
          </FormControl>
        </Grid>
        {showConcurrencyType() ? (
          <Grid item xs={12} sm={6} md={4}>
            <PaddedLabel htmlFor="rule-concurrency-select" sx={styles.label}>
              <LabelWithHint
                label="Concurrency"
                helpText="Select Concurrency if ALL subjects in the rule can be taken concurrently. If only a selection can be taken concurrently, you will need to break these subjects out into a separate rule."
              />
            </PaddedLabel>
            <FormControl fullWidth={true}>
              <Field
                component={Select}
                name={"concurrency"}
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps}
                disabled={allowedConcurrencyTypes.length === 1}
                inputProps={{ id: "rule-concurrency-select" }}
              >
                {allowedConcurrencyTypes.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        ) : null}

        {targetType !== RuleTargetType.Subject && (
          <Grid item xs={12} sm={6} md={4}>
            <PaddedLabel htmlFor="rule-breadthOrDisciplineScope-select" sx={styles.label}>
              <LabelWithHint
                label="Bachelor Degree Validation"
                helpText="Determine what type of subjects should be validated in My Course Planner against this rule."
              />
            </PaddedLabel>
            <FormControl fullWidth={true}>
              <Field
                component={Select}
                name={"breadthOrDisciplineScope"}
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps}
                inputProps={{ id: "rule-breadthOrDisciplineScope-select" }}
              >
                {Object.values(BreadthDisciplineScope).map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          {featureToggles.search ? (
            <>
              <ScopeContainer>
                <PaddedLabel htmlFor="subjects-text" sx={styles.label}>
                  <LabelWithHint
                    label="Subjects"
                    helpText="Add in subjects by typing subject codes in the field, using the search, or adding in pre-established subject lists from the Subject List section below by simply clicking into the field."
                  />
                </PaddedLabel>
                <Button variant="text" color="primary" onClick={flipFinder}>
                  Advanced search
                </Button>
              </ScopeContainer>
              {finderOpen && (
                <SSSSearchModal
                  subjectRecordIds={formik.values.subjectsAndSets}
                  close={flipFinder}
                  addSubject={addSubject}
                  deleteSubject={deleteSubject}
                />
              )}
            </>
          ) : (
            <PaddedLabel htmlFor="subjects-text" sx={styles.label}>
              Scope
              <LabelWithHint
                label="Scope"
                helpText="Add in subjects by typing subject codes in the field, using the search, or adding in pre-established subject lists from the Subject List section below by simply clicking into the field."
              />
            </PaddedLabel>
          )}
          <FormControl fullWidth={true} variant="outlined">
            <SubjectSearch
              subjectsAndSets={formik.values.subjectsAndSets}
              onSubjectRecordIdsChanged={(v) => {
                setFieldValue("subjectsAndSets", v);
              }}
              errorMesage={formik.errors["subjectsAndSets"] as string}
            />
          </FormControl>
        </Grid>

        {!!featureToggles.subjectExclusion && (
          <Grid item xs={12}>
            <ScopeContainer>
              <PaddedLabel htmlFor="subject-exclusions-text" sx={styles.label}>
                <LabelWithHint
                  label="Subjects to be excluded from this rule"
                  helpText="Exclude these subjects from validating this particular rule as they are being used in other rule validations within this structure (eg. compulsory/selective subject rules)"
                />
              </PaddedLabel>
              <Button variant="text" color="primary" onClick={flipFinderExcluded}>
                Advanced search
              </Button>
            </ScopeContainer>
            {finderOpenExcluded && (
              <SSSSearchModal
                subjectRecordIds={formik.values.subjectOmissions}
                close={flipFinderExcluded}
                addSubject={addExcludedSubject}
                deleteSubject={deleteExcludedSubject}
              />
            )}
            <FormControl fullWidth={true} variant="outlined">
              <SubjectSearch
                subjectsAndSets={formik.values.subjectOmissions}
                onSubjectRecordIdsChanged={(v) => {
                  setFieldValue("subjectOmissions", v);
                }}
                errorMesage={formik.errors["subjectOmissions"] as string}
                subjectsOnly={true}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-presentation-format-select" sx={styles.label}>
            <LabelWithHint
              label="Published format"
              helpText="Choose the way this rule is presented in CAPS, the Handbook and My Course Planner, by selecting what information is displayed and how. This format will also interact with the parent (Rule Group) format."
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Published format",
                id: "rule-presentation-format-select",
              }}
            >
              {allowedFormats.map((f) => (
                <MenuItem style={{ whiteSpace: "normal" }} key={f.name} value={f.name}>
                  {f.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {(samples.length > 0 || !!featureToggles.previewRuleBeforeSave) && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={previewReq}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleComponent}
              ruleType={ruleType}
              ruleId={rule?.id}
            />
          </Grid>
        )}
        {!!featureToggles.availabilityTags && (
          <Grid item xs={12} md={6}>
            <PaddedLabel htmlFor="group-tags-select" sx={styles.label}>
              Tags
            </PaddedLabel>
            <AutocompleteMultiSelect
              id="group-tags-select"
              options={Tags}
              formValue={formik.values.tags}
              formatLabel={getTagLabel}
              onChange={onTagsChange}
              placeholder="tags"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <RuleFormActions
            ruleId={rule?.id}
            submitDisabled={!formik.isValid || !formik.dirty}
            submitting={formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </EditorForm>
  );
};
