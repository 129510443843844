import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, FormControl, useTheme } from "@mui/material";
import { PaddedLabel } from "./forms";
import { commonStyles } from "theme/styles";
import { brandDarkBlue } from "theme/colors";
import { useDebounce } from "lib/useDebounce";
import styled from "styled-components";
import { IState, Stereotype } from "store/types";
import { useSelector } from "react-redux";
import { post } from "lib/fetch";
import { PreviewRequest } from "./types";
import { useDeepCompareEffect } from "react-use";

export interface IProps {
  samples?: string[];
  previewReq: PreviewRequest | null;
  selectedOrDefaultFormat?: string;
  stereotype: Stereotype;
  ruleType?: string;
  ruleId?: string;
}

const PreviewWrapper = styled.div`
  border: 1px solid ${brandDarkBlue.toString()};
  border-radius: 4px;
  padding: 4px 8px;
  resize: vertical;
  overflow: hidden;
  height: 100px;
  background: #f2f2f2;
`;

const PreviewScroller = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const PreviewSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const errorHTML = `<i style="color: red">There was an error generating the preview.<i>`;
const emptyWarningHTML = `<i style="color: #808000">The preview is empty. Please specify the missing rule properties or change the published format.<i>`;

export const RulePreview = (props: IProps) => {
  const theme = useTheme();
  const commonStyling = commonStyles(theme);
  const { previewReq, selectedOrDefaultFormat, ruleType, ruleId, stereotype } = props;

  const previewReqWithFormat = useMemo(
    () =>
      previewReq && {
        ...previewReq,
        ruleProps: { ...previewReq.ruleProps, selectedOrDefaultFormat },
      },
    [previewReq, selectedOrDefaultFormat],
  );

  const dPreviewReq = useDebounce(previewReqWithFormat, 500);
  const [loading, setLoading] = useState(false);
  const [previewText, setPreviewText] = useState<string>("");

  const { sysId, tokenValidated, targetType } = useSelector((s: IState) => ({
    sysId: s.sysId,
    tokenValidated: s.tokenValidated,
    targetType: s.targetType,
  }));

  useDeepCompareEffect(() => {
    setPreviewText("");
    if (selectedOrDefaultFormat) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleType, ruleId, previewReq ?? {}, selectedOrDefaultFormat]);

  const previewCAPSChange = useCallback(() => {
    // Ignore old responses
    if (
      !dPreviewReq ||
      !dPreviewReq.ruleProps.selectedOrDefaultFormat ||
      dPreviewReq.ruleProps.ruleType !== ruleType ||
      dPreviewReq.ruleProps.stereotype !== stereotype
    ) {
      return Promise.resolve(null);
    }

    const courseRulesObj = {
      ruleProps: {
        ...dPreviewReq.ruleProps,
        ruleType,
        childrenReferences: dPreviewReq.ruleProps.childrenReferences ?? [],
      },
      ruleId,
      embeddedChildRuleProps: dPreviewReq.embeddedChildRuleProps,
      sysId: sysId,
      sessionId: tokenValidated?.sessionId,
    };
    // const urlQueryParams = `sysId=${sysId}&sessionId=${tokenValidated?.sessionId}`;
    return post(`/v1/${targetType}rules/generatePreview`, courseRulesObj);
  }, [dPreviewReq, ruleType, stereotype, sysId, targetType, tokenValidated?.sessionId, ruleId]);

  useEffect(() => {
    previewCAPSChange()
      .then((data) => {
        if (data !== null) {
          setLoading(false);
          setPreviewText(data.previewHtml);
        }
      })
      .catch((_e) => {
        setLoading(false);
        setPreviewText(errorHTML);
      });
  }, [previewCAPSChange]);

  return (
    <>
      <PaddedLabel htmlFor="rule-presentation-preview" sx={commonStyling.label}>
        Rule Preview
      </PaddedLabel>
      <FormControl fullWidth={true}>
        <PreviewWrapper>
          <PreviewScroller>
            {loading && (
              <PreviewSpinnerContainer>
                <CircularProgress size={50} thickness={2} />
              </PreviewSpinnerContainer>
            )}
            {!loading && <div dangerouslySetInnerHTML={{ __html: previewText || emptyWarningHTML }} />}
          </PreviewScroller>
        </PreviewWrapper>
      </FormControl>
    </>
  );
};
