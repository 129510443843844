import { get } from "lib/fetch";
import { getParentCourseSysIdForComponentSearch } from "lib/search";
import pickBy from "lodash/pickBy";
import store from "../store";
export const EXECUTE_MMS_QUERY = "mmsQueryResult/EXECUTE_MMS_QUERY";

export const executeMMSQuery = (query: any) => {
  const { targetType, tokenValidated, capsObject } = store.getState();

  // Filter by mms' parent_course.sys_id field
  query.parentCourseSysId = getParentCourseSysIdForComponentSearch(targetType, capsObject);

  const filteredQuery: any = {
    ...pickBy(query, (v) => String(v).trim() || v.length > 0),
    searchYear: parseInt(capsObject.effectiveYear) || new Date().getFullYear(),
  };
  const allKeys = Object.keys(filteredQuery);
  const ignoreKeys = ["searchYear"];
  const optimisedKeys = allKeys.filter((k) => !ignoreKeys.includes(k));

  const now = Date.now();
  // Don't search if there's no point
  if (optimisedKeys.length === 0) {
    return {
      type: EXECUTE_MMS_QUERY,
      payload: Promise.resolve({
        searchTimestamp: now,
        result: [],
      }),
    };
  }

  const queryURLComponent = allKeys
    .map((k) => `${k}=${encodeURIComponent(JSON.stringify(filteredQuery[k]))}`)
    .join("&");
  const urlQueryParams = `sessionId=${tokenValidated.sessionId}&${queryURLComponent}`;
  return {
    type: EXECUTE_MMS_QUERY,
    payload: get(`/v1/search/component?${urlQueryParams}`).then((result) => ({ result, searchTimestamp: now })),
  };
};
