import React, { useCallback, useMemo } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "lib/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { IState, ICourseSummary } from "store/types";
import uniq from "lodash/uniq";
import { searchCourseByName } from "actions/courses";

interface IProps {
  courseRecordIds: string[];
  onCourseRecordIdsChanged: (ids: string[]) => any;
  errorMesage?: string | null;
}

export default function CourseSearch(props: IProps) {
  const { courseRecordIds, onCourseRecordIdsChanged, errorMesage } = props;
  const [extraOptions, setExtraOptions] = React.useState<string[]>([]);
  const [text, setText] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const courses = useSelector((s: IState) => s.courses);

  const dispatch = useDispatch();
  const debouncedText = useDebounce(text, 500).trimStart();

  React.useEffect(() => {
    if (text.trim()) {
      setLoading(true);
    }
  }, [text]);

  const clearOptions = useCallback(() => setExtraOptions([]), [setExtraOptions]);
  const clearText = useCallback(() => setText(""), [setText]);
  const updateText = useCallback((e: any) => setText(e?.target?.value || ""), [setText]);

  React.useEffect(() => {
    const searchText = debouncedText.trim();
    if (searchText) {
      setExtraOptions([]);

      const result = dispatch(searchCourseByName(debouncedText.trim())) as any;
      result
        .then((resp: { value: ICourseSummary[] }) => {
          const matchigRecordIds = resp.value.map((s) => s.recordId);
          setExtraOptions([...matchigRecordIds]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debouncedText, setExtraOptions, setLoading, dispatch]);

  const onCourseSelection = useCallback(
    (_e: any, newValue: string[]) => {
      onCourseRecordIdsChanged(newValue);
      setText("");
      setExtraOptions([]);
    },
    [onCourseRecordIdsChanged],
  );

  const getOptionLabel = useCallback(
    (option: string) => {
      const s = courses[option];
      return s ? `${s.name} (${s.code})` : option;
    },
    [courses],
  );

  const options = useMemo(() => uniq([...courseRecordIds, ...extraOptions]), [courseRecordIds, extraOptions]);

  return (
    <>
      <Autocomplete
        id="course-search"
        onOpen={clearOptions}
        onClose={clearText}
        clearOnEscape={true}
        filterSelectedOptions={true}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        multiple={true}
        value={courseRecordIds}
        onChange={onCourseSelection}
        inputValue={text}
        onInputChange={updateText}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            helperText={errorMesage || undefined}
            error={!!errorMesage}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
