import { get } from "lib/fetch";
export const GET_VALIDATETOKEN = "token/GET_VALIDATETOKEN";

export const validateToken = (sessionId?: string, token?: string) => {
  const urlQueryParams = `sessionId=${sessionId}&retoken=${token}`;
  return {
    type: GET_VALIDATETOKEN,
    payload: get(`/v1/token?${urlQueryParams}`).then((resp) => ({ token: resp.token.token, sessionId })),
  };
};
