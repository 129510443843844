import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import { Aligned } from "components/divs/alignedDiv";
import { Button, Collapse, IconButton } from "@mui/material";
const H3 = styled.h3`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0.5rem;
`;

const Banner = styled.div`
  padding: 1rem;
  border: 1px solid lightgray;
  background: #e8e8e8;
  border-radius: 10px;
  margin-top: 1rem;
`;

interface IButtonProps {
  label: string;
  onClick: () => void;
  colour?: "inherit" | "primary" | "secondary" | undefined;
}
interface IProps {
  heading: string;
  icon?: React.ReactElement<any>;
  body?: React.ReactElement<any>;
  buttons?: IButtonProps[];
  collapsible?: boolean;
}
export const BannerComponent = ({ heading, icon, body, buttons, collapsible }: IProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const flipCollapsed = useCallback(() => setCollapsed((old) => !old), []);
  return (
    <Banner>
      <Aligned>
        {icon || <InfoOutlined />}
        <H3>{heading}</H3>
        {collapsible && (
          <IconButton onClick={flipCollapsed} size="small">
            {collapsed ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
          </IconButton>
        )}
      </Aligned>
      <Collapse in={!collapsible || !collapsed}>
        {body}
        {buttons &&
          buttons.length > 1 &&
          buttons.map((b: IButtonProps, i: number) => (
            <React.Fragment key={`${b.label}-${i}`}>
              <Button variant="contained" color={b.colour || "primary"} onClick={b.onClick}>
                {b.label}
              </Button>
              &nbsp; &nbsp;
            </React.Fragment>
          ))}
      </Collapse>
    </Banner>
  );
};
