import React, { useCallback } from "react";

import { IRuleEditorProps } from "../common/types";
import { Formik, FormikHelpers } from "formik";
import { getLabel, getMMSReferences, getCourseReferences } from "lib/parameters";
import { assertThat } from "lib/assert";
import { RuleComponentType, Stereotype } from "store/types";
import { useDispatch } from "react-redux";
import { IRulesUpdateObj, updateCAPSObject } from "actions/capsObject";
import { getByRuleId } from "lib/rules";
import { AdmissionFields } from "./AdmissionFields";
import { getCoursesAndMMS } from "actions/courses";
import { flatten } from "lodash";
import { useDeepCompareEffect } from "react-use";
import { validateValuePresence } from "lib/validation";
import { useAllowedFormats } from "../editor/editorHooks";

export interface IAdmissionEditorFormState {
  type: RuleComponentType;
  selectedOrDefaultFormat?: string;
  label?: string | null;
  mmsReferences: string[];
  courseReferences: string[];
}

const validateFormState = (values: IAdmissionEditorFormState) => {
  const errors: any = {};
  if (values.mmsReferences.length + values.courseReferences.length === 0) {
    const error = "MMS and Course list may not be empty";
    errors["mmsReferences"] = error;
    errors["courseReferences"] = error;
  }
  validateValuePresence(errors, values, "selectedOrDefaultFormat");
  return errors;
};

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const AdmissionEditor = (props: Props) => {
  const { rule, onSubmit, ruleType } = props;

  const mmsSetChild = (rule?.children || []).find((c) => c.type === RuleComponentType.CourseMMSSet);
  const mmsSetChildId = mmsSetChild?.id;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mmsReferences = mmsSetChild ? getMMSReferences(mmsSetChild) : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const courseReferences = mmsSetChild ? getCourseReferences(mmsSetChild) : [];

  const dispatch = useDispatch();
  useDeepCompareEffect(() => {
    if (mmsReferences.length > 0 || courseReferences.length > 0) {
      dispatch(getCoursesAndMMS(courseReferences, mmsReferences));
    }
  }, [mmsReferences, courseReferences, dispatch]);

  const useInitialValue = rule && rule.type === ruleType;
  const allowedFormats = useAllowedFormats(props);

  const initialState: IAdmissionEditorFormState = {
    label: useInitialValue ? getLabel(rule!) : "",
    mmsReferences,
    courseReferences,
    type: RuleComponentType.Admission,
    selectedOrDefaultFormat: props.parentTemplate?.selectedOrDefaultFormat ?? allowedFormats[0]?.name,
  };

  const callOnSubmit = useCallback(
    (values: IAdmissionEditorFormState, helpers: FormikHelpers<IAdmissionEditorFormState>) => {
      const { mmsReferences, courseReferences, label, ...params } = values;
      const parentParams = { label, ...params };
      const p = onSubmit(parentParams).then((response: any) => {
        let ruleId = mmsSetChildId;
        if (!ruleId) {
          const parent = getByRuleId(response.value.rules, response.value.newlyCreatedRuleId);
          const children = parent?.children ?? [];
          const potentialSets =
            parent?.stereotype === Stereotype.RuleComponent ? children : flatten(children.map((c) => c.children));
          const mmsSetChild = potentialSets.find((c) => c.type === RuleComponentType.CourseMMSSet);
          ruleId = mmsSetChild?.id;
        }

        if (!ruleId) {
          throw new Error(`Could not locate parent of Course MMS Set`);
        }

        const courseRulesUpdateObj: IRulesUpdateObj = {
          ruleId,
          ruleType: RuleComponentType.CourseMMSSet,
          parameters: {
            label,
            mmsReferences,
            courseReferences,
          },
        };
        dispatch(updateCAPSObject(courseRulesUpdateObj));
      });

      if (Promise.resolve(p) === p) {
        p.catch(() => helpers.setSubmitting(false));
      }
    },
    [dispatch, onSubmit, mmsSetChildId],
  );

  assertThat([RuleComponentType.Admission].indexOf(ruleType) >= 0, `Invalid rule type ${ruleType}`);

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      <AdmissionFields {...props} />
    </Formik>
  );
};
