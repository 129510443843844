import React from "react";
import { InputLabel } from "@mui/material";
import styled from "styled-components";
import { Field } from "formik";
import { TextField, Switch, Select } from "formik-mui";
import { FormControl, FormControlLabel, MenuItem } from "@mui/material";
import { isBoolean } from "lib/parameters";
import { IParameter, ParamType } from "store/types";

export const PaddedLabel = React.memo(styled(InputLabel)`
  margin-bottom: 0.25rem;
  min-height: 18px;
`);

export const SelectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

export const ScopeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const fieldForParam = (param: IParameter) => {
  const id = `${param.label}-field`;

  // Ignore this field for now ...
  if (param.name === "qualifier") {
    return undefined;
  }

  if ([ParamType.number, ParamType.string].find((e) => e === param.type)) {
    return (
      <>
        <PaddedLabel htmlFor={id}>{param.label}</PaddedLabel>
        <FormControl fullWidth={true} variant="outlined">
          <Field
            component={TextField}
            name={param.name}
            label=""
            fullWidth={true}
            variant="outlined"
            type={param.type === ParamType.number ? "number" : undefined}
            inputProps={{
              "aria-label": param.label,
              id,
              min: param.type === ParamType.number ? 0 : undefined,
            }}
          />
        </FormControl>
      </>
    );
  }

  if (isBoolean(param)) {
    return (
      <FormControlLabel
        labelPlacement="end"
        control={<Field component={Switch} type="checkbox" name={param.name} color="primary" />}
        label={param.label}
      />
    );
  }

  if (param.type === ParamType.enum) {
    return (
      <>
        <PaddedLabel htmlFor={id}>{param.label}</PaddedLabel>
        <FormControl fullWidth={true}>
          <Field
            component={Select}
            name={param.name}
            fullWidth={true}
            variant="outlined"
            MenuProps={SelectMenuProps}
            inputProps={{ id }}
          >
            {(param.enumValues || []).map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </>
    );
  }

  console.log(`We can't display ${param.type} yet ...`);
};
