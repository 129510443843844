import { Level } from "./searchConstants";
import uniq from "lodash/uniq";

const parseLevelValue = (levelVal: string | number) => {
  const result = String(levelVal);
  if (Level.find((l) => l === result)) {
    return result;
  }
  if (result.includes("Honours")) {
    return "4";
  }
  return result.replace(/[^\d]/gi, "") || result;
};
export const parseLevelVlues = (levels: Array<string | number>) => uniq(levels.map(parseLevelValue).filter(Boolean));
export const formatLevel = (lvl: string) => {
  const levelValue = parseLevelValue(lvl);
  if (levelValue === "4") {
    return "Honours (Level 4)";
  }
  return levelValue.match(/\d$/g) ? `Level ${levelValue}` : levelValue;
};
