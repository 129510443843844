import React from "react";
import { getNumValue } from "lib/parameters";
import { IRulesProps } from "../common/types";
import { Tooltip } from "@mui/material";
import { HeaderActionContainer } from "../common/ruleComponent";
import styled from "styled-components";

import { Aligned } from "components/divs/alignedDiv";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { ActionMenu } from "../menu";
import { MoveOptions } from "../moveOptions";
import { assertThat } from "lib/assert";

export const ProgressionContainer = styled.div`
  width: calc(100% - 1rem);
  margin-top: 0rem;
`;

export const P = styled.p`
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  white-space: pre-wrap;
`;

export const AdmissionWarning = React.memo(() => (
  <Aligned style={{ width: "100%", alignItems: "top" }}>
    <HelpIcon fontSize="small" />
    <p style={{ marginLeft: "0.5rem", marginTop: 0, paddingTop: 0 }}>
      Please select one or more courses and/or components that apply to this rule. The rule will be valid if the student
      is enrolled in{" "}
      <u>
        <strong>any</strong>
      </u>{" "}
      of the selected courses and components listed in either of the scopes below.
    </p>
  </Aligned>
));

export const Progression = (props: IRulesProps) => {
  const { rule, parentTemplate } = props;

  assertThat(rule.children.length === 0, "Progression should not have children");

  const subjectLevel = getNumValue("level")(rule);
  const points = getNumValue("points")(rule);

  return (
    <ProgressionContainer>
      <HeaderActionContainer>
        <Aligned style={{ alignItems: "start" }}>
          {props.dragHandle}
          <Tooltip title={"Progression Rule"} placement="top" arrow>
            <i className="material-icons-outlined">stairs</i>
          </Tooltip>
          <P>{`Level ${subjectLevel} to Level ${subjectLevel + 1} / ${points}pts / Progression Rule`}</P>
          &nbsp; &nbsp;
          <ActionMenu rule={rule} parentTemplate={parentTemplate} />
          <MoveOptions rule={rule} />
        </Aligned>
      </HeaderActionContainer>
    </ProgressionContainer>
  );
};
