import React from "react";

import { IRuleComponent, Operator } from "store/types";
import styled from "styled-components";
import { getOperator } from "lib/parameters";
import { featureToggles } from "config/featureToggles";

interface IWrapperProps {
  left: string;
  top: string;
}

const BracketLabelWrapper = styled.div<IWrapperProps>`
  position: absolute;
  transform: rotate(270deg) translateX(-100%);
  transform-origin: left top;
  top: ${(props: IWrapperProps) => props.top};
  left: ${(props: IWrapperProps) => props.left};
  font-weight: bold;
  font-size: small;
  color: rgba(20, 20, 20, 0.8);
  word-spacing: 0.2rem;
`;

const excludeIfOneChild = [Operator.OR, Operator.XOR, Operator.AND, Operator.IF];

const opNames: any = {
  [Operator.IF]: "IF - THEN",
};

interface IProps {
  group: IRuleComponent;
  level: number;
  topOffset: number;
}

export const RuleBracketLabel = ({ group, level, topOffset }: IProps) => {
  const op = getOperator(group);
  const children = group.children;

  if (
    !featureToggles.bracketConnectors ||
    !op ||
    children.length === 0 ||
    (children.length === 1 && excludeIfOneChild.indexOf(op) >= 0)
  ) {
    return null;
  }

  const top = group.children.length === 1 ? `${topOffset}px` : `${topOffset + 16}px`;
  return (
    <BracketLabelWrapper top={top} left={level <= 1 ? "2rem" : "1.6rem"}>
      {opNames[op] ?? op}
    </BracketLabelWrapper>
  );
};
