import { SET_SYSID } from "actions/sysId";
import initialState from "store/initialState";

const sysId = (state = initialState.sysId, action: any) => {
  switch (action.type) {
    case SET_SYSID:
      return action.payload.sysId;
    default:
      return state;
  }
};

export default sysId;
