import { get } from "lib/fetch";
import store from "../store";

export const GET_SUBJECT_DETAILS = "subjectDetails/GET_SUBJECT_DETAILS";
export const GET_SUBJECT_BY_CODE = "subjectDetails/GET_SUBJECT_BY_CODE";

export const getSubjectDetails = (subjectRecordIds: string[] = []) => {
  const {
    sysId,
    tokenValidated,
    subjectDetails,
    capsObject: { effectiveYear },
  } = store.getState();
  const year = parseInt(effectiveYear) || new Date().getFullYear();
  const query = `?sysId=${sysId}&sessionId=${tokenValidated.sessionId}&retoken=${tokenValidated.token}`;

  const alreadyLoaded =
    subjectRecordIds.length === 0 || !subjectRecordIds.find((recordId) => !subjectDetails[recordId]);

  return {
    type: GET_SUBJECT_DETAILS,
    payload: alreadyLoaded
      ? Promise.resolve([])
      : get(`/v1/subject${query}&subjectRecordIds=${JSON.stringify(subjectRecordIds)}&searchYear=${year}`),
  };
};

export const subjectByCode = (codes: string[]) => {
  const {
    sysId,
    tokenValidated,
    subjectDetails,
    capsObject: { effectiveYear },
  } = store.getState();
  const year = parseInt(effectiveYear) || new Date().getFullYear();
  const query = `?sysId=${sysId}&sessionId=${tokenValidated.sessionId}&retoken=${tokenValidated.token}`;

  const subjectByCode = (code: string) => Object.values(subjectDetails).find((s) => s.code === code);
  const codeIsNotLoaded = (code: string) => !subjectByCode(code);
  const alreadyLoaded = codes.length === 0 || !codes.find(codeIsNotLoaded);

  return {
    type: GET_SUBJECT_BY_CODE,
    payload: alreadyLoaded
      ? Promise.resolve(codes.map(subjectByCode))
      : get(`/v1/subject${query}&subjectCode=${JSON.stringify(codes)}&searchYear=${year}`),
  };
};
