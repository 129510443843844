import React, { useCallback, useEffect, useState } from "react";

import { IRuleEditorProps } from "../common/types";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { Button, FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, ScopeContainer, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { RuleComponentType, Stereotype, SubjectRuleSectionType } from "store/types";
import MMSSearch from "./MMSSearch";
import { commonStyles } from "theme/styles";
import { IMMSNumEditorFormState } from "./MMSNumConstraintEditor";
import { featureToggles } from "config/featureToggles";
import { MMSSearchModal } from "./mms/MMSSearchModal";
import { uniq } from "lodash";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { EditorForm } from "../common/ruleComponent";
import { getRuleUILabels } from "lib/rules";
import { RulePreview } from "../common/rulePreview";
import { useFormatsSamplesAndTypes } from "../editor/editorHooks";
import { useDeepCompareMemo } from "use-deep-compare";

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const MMSNumConstraintEditorFields = (props: Props) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule, ruleType, parentSection, changeRuleType } = props;

  const parentSectionType = parentSection?.type;
  const useNonAllowedDefaults =
    SubjectRuleSectionType.NonAllowedSubjects === parentSectionType && !!featureToggles.nonAllowedDefaults;

  const [finderOpen, setFinderOpen] = useState(false);
  const flipFinder = useCallback(() => setFinderOpen((old) => !old), []);
  const formik = useFormikContext<IMMSNumEditorFormState>();
  const { setFieldValue } = formik;

  // Notify the dispatcher that we need to render a new rule type form
  useEffect(() => {
    if (formik.values.type !== ruleType) {
      changeRuleType(formik.values.type);
    }
  }, [changeRuleType, formik.values.type, ruleType]);

  const { allowedFormats, samples, allRuleTypes } = useFormatsSamplesAndTypes(
    props,
    formik.values.selectedOrDefaultFormat,
  );

  const addMMS = (id: string) => {
    setFieldValue("mmsReferences", uniq([...formik.values.mmsReferences, id]));
  };
  const deleteMMS = (id: string) => {
    const without = formik.values.mmsReferences.filter((s) => s !== id);
    setFieldValue("mmsReferences", without);
  };

  const previewReq = useDeepCompareMemo(() => {
    const embeddedChildRuleProps = {
      ruleType: RuleComponentType.SubjectSet,
      parameters: {
        label: formik.values.label,
        mmsReferences: formik.values.mmsReferences,
      },
    };
    return {
      ruleProps: {
        stereotype: Stereotype.RuleComponent,
        parameters: formik.values,
        ruleType: RuleComponentType.CountConstraintMMS,
      },
      embeddedChildRuleProps,
      isValid: formik.isValid,
    };
  }, [formik.values, formik.isValid]);

  return (
    <EditorForm onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-type-select" sx={styles.label}>
            Rule type
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="type"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps as any}
              inputProps={{ id: "rule-type-select" }}
              disabled={!!rule}
            >
              {allRuleTypes.map((t) => {
                const uiLabels = getRuleUILabels(t);
                return (
                  <MenuItem key={t} value={t}>
                    <LabelWithHint
                      label={
                        <div>
                          {uiLabels?.title}
                          {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                        </div>
                      }
                      helpText={uiLabels.helpText}
                    />
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <PaddedLabel htmlFor="label-text" sx={styles.label}>
            <LabelWithHint
              label="Label"
              helpText="Give your rule or subject options a label. You can use this label in your Published Format to replace subject codes with identifying text, or you can use it for administrative reasons. If you leave this empty, either the subject codes or the subject list labels will be used."
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="label"
              label=""
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Rule Label",
                id: "label-text",
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <PaddedLabel htmlFor="minimum-text" sx={styles.label}>
            <LabelWithHint
              label="Minimum"
              helpText="Either a minimum or maximum needs to be entered. Complete only one field if an exact number is not required."
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="minimum"
              label=""
              fullWidth={true}
              variant="outlined"
              type="number"
              disabled={useNonAllowedDefaults}
              inputProps={{
                "aria-label": "minimum",
                id: "minimum-text",
                min: 0,
                step: RuleComponentType.PointsConstraint === ruleType ? 12.5 : 1,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <PaddedLabel htmlFor="maximum-text" sx={styles.label}>
            Maximum
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="maximum"
              label=""
              fullWidth={true}
              variant="outlined"
              type="number"
              disabled={useNonAllowedDefaults}
              inputProps={{
                "aria-label": "maximum",
                id: "maximum-text",
                min: 0,
                step: RuleComponentType.PointsConstraint === ruleType ? 12.5 : 1,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {featureToggles.search ? (
            <>
              <ScopeContainer>
                <PaddedLabel htmlFor="mmsReferences" sx={styles.label}>
                  <LabelWithHint label="Component Scope" helpText="Add in components by using the search." />
                </PaddedLabel>
                <Button variant="text" color="primary" onClick={flipFinder}>
                  Advanced search
                </Button>
              </ScopeContainer>
              {finderOpen && (
                <MMSSearchModal
                  mmsRecordIds={formik.values.mmsReferences}
                  close={flipFinder}
                  addMMS={addMMS}
                  deleteMMS={deleteMMS}
                />
              )}
            </>
          ) : (
            <PaddedLabel htmlFor="mmsReferences" sx={styles.label}>
              Component Scope
            </PaddedLabel>
          )}

          <FormControl fullWidth={true} variant="outlined">
            <MMSSearch
              mmsRecordIds={formik.values.mmsReferences}
              onMMSRecordIdsChanged={(v) => {
                setFieldValue("mmsReferences", v);
              }}
              errorMesage={formik.errors["mmsReferences"] as string}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-presentation-format-select" sx={styles.label}>
            <LabelWithHint
              label="Published format"
              helpText="Choose the way this rule is presented in CAPS, the Handbook and My Course Planner, by selecting what information is displayed and how. This format will also interact with the parent (Rule Group) format."
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Published format",
                id: "rule-presentation-format-select",
              }}
            >
              {allowedFormats.map((f) => (
                <MenuItem style={{ whiteSpace: "normal" }} key={f.name} value={f.name}>
                  {f.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {(samples.length > 0 || !!featureToggles.previewRuleBeforeSave) && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={previewReq}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleComponent}
              ruleType={ruleType}
              ruleId={rule?.id}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <RuleFormActions
            ruleId={rule?.id}
            submitDisabled={!formik.isValid || !formik.dirty}
            submitting={formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </EditorForm>
  );
};
