import React, { useCallback, useEffect, useRef } from "react";

import { IState, Stereotype } from "store/types";
import { assertThat } from "lib/assert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RuleDispatcher } from "../RuleDispatcher";
import { FullWidth } from "components/divs/fullWidthDiv";
import { getLabel, getOperator } from "lib/parameters";
import { NestedGroup } from "./NestedGroup";
import { HeaderActionContainer, headingForLevel } from "../common/ruleComponent";
import { beige, brandLightBlue, brandLightGrey } from "theme/colors";
import { ActionMenu } from "../menu";
import { IRulesProps } from "../common/types";
import { RuleGroupEditor } from "./RuleGroupEditor";
import { useDispatch, useSelector } from "react-redux";
import { EditorDispatcher } from "../component/EditorDispatcher";
import { Aligned } from "components/divs/alignedDiv";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DNDHandle, DroppableFullWidth } from "../common/dnd";
import { TagsPreview } from "components/tags/tagsPreview";
import { MoveOptions } from "../moveOptions";
import { MovingWrapper } from "../common/movingWrapper";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { isChildOf, ruleHTMLId } from "lib/rules";
import { flipPreviewRule } from "actions/editorState";
import { RuleBracketLabel } from "./RuleBracketLabel";
import { OperatorHeader, OperatorLink } from "./operator";
import { groupHelpHint } from "./groupConst";
import { useSize } from "lib/useSize";

const useStyles = () => ({
  rootAccordianSummary: {
    background: brandLightBlue.toString(),
    minHeight: "48px !important",
    height: 54,
  },
  rootAccordianMovedSummary: {
    background: beige.toString(),
    border: "3px dashed gray",
    minHeight: "48px !important",
    height: 54,
  },
  rootAccordianDetails: {
    background: brandLightGrey.toString(),
    width: "-webkit-fill-available",
    paddingRight: "0.5rem",
  },
});

export const RuleGroup = (props: IRulesProps) => {
  const styles = useStyles();
  const { rule, level, parentSection } = props;
  const newlyCreatedRules = useSelector((state: IState) => state.capsObject?.newlyCreatedRules);
  assertThat(rule.stereotype === Stereotype.RuleGroup, `Invalid group stereotype ${rule.stereotype}`);

  const expandedSections = useSelector((s: IState) => s.editorState.expandedSections);
  const expandParentSection = (expandedSections && parentSection && expandedSections[parentSection.id]) || false;
  const operator = getOperator(rule);
  assertThat(operator, `Group must have operator`);

  const label = getLabel(rule) || "";
  const id = `panel-group-${rule.id}`;
  const H = headingForLevel(level);

  const nChildren = rule.children.length;

  // Expansion state
  /** set expanded to true on initial render, and then use value from expandParentSection or handleExpansion */
  const [expanded, setExpanded] = React.useState<boolean>(true);
  React.useEffect(() => {
    setExpanded(expandParentSection);
  }, [expandParentSection]);
  // reset to true on initial render
  React.useEffect(() => {
    setExpanded(true);
  }, []);

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const moveInProgress = !!moveRuleState;
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  // Compute the size of the header
  const headerRef = useRef<any | null>(null);
  const headerSize = useSize(headerRef);

  // Handle the editing
  const editedRuleId = useSelector((s: IState) => s.editorState.editedRuleId);
  const editMode = rule.id === editedRuleId && !isBeingMoved;

  const addRuleToRuleId = useSelector((s: IState) => s.editorState.addRuleToRuleId);
  const beingAddedToMode = rule.id === addRuleToRuleId && !isBeingMoved;

  const addGroupToRuleId = useSelector((s: IState) => s.editorState.addGroupToRuleId);
  const beingAddedGroupTo = rule.id === addGroupToRuleId && !isBeingMoved;

  const previewedRuleId = useSelector((s: IState) => s.editorState.previewedRuleId);
  const capsRules = useSelector((s: IState) => s.capsObject?.rules);
  const previewing = rule.id === previewedRuleId || isChildOf(capsRules, rule.id, previewedRuleId);

  // Handle the DnD progress
  const disableDrag = moveInProgress || editMode || beingAddedToMode || beingAddedGroupTo || !!newlyCreatedRules;

  useEffect(() => {
    if (editMode || beingAddedToMode || beingAddedGroupTo) {
      setExpanded(true);
    }
  }, [setExpanded, editMode, beingAddedToMode, beingAddedGroupTo]);

  // Whenever the previewed rule changes - we may need to expand
  useEffect(() => {
    if (previewing && rule.id !== previewedRuleId) {
      setExpanded(true);
    }
  }, [setExpanded, previewing, previewedRuleId, rule.id]);

  const dispatch = useDispatch();
  const handleExpansion = useCallback(() => {
    setExpanded((wasExpanded) => {
      if (previewing && wasExpanded && rule.id !== previewedRuleId) {
        dispatch(flipPreviewRule(null));
      }
      return !wasExpanded;
    });
  }, [dispatch, previewedRuleId, previewing, rule.id]);

  return (
    <>
      <Draggable draggableId={rule.id} index={props.index} isDragDisabled={disableDrag}>
        {(dragProvided) => (
          <FullWidth
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            // {...provided.dragHandleProps}
            tabIndex={-1}
            id={ruleHTMLId(rule.id)}
            // style={{ border: "1px dashed red" }}
          >
            <Droppable droppableId={rule.id} type={`group-${level}`}>
              {(provided, snapshot) => {
                const handle = <DNDHandle {...dragProvided.dragHandleProps} />;

                return (
                  <MovingWrapper beingMoved={!!isBeingMoved} hideBorder={level <= 1}>
                    <DroppableFullWidth
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isDraggingOver={snapshot.isDraggingOver}
                      tabIndex={-1}
                    >
                      {level <= 1 ? (
                        <Accordion
                          TransitionProps={{ unmountOnExit: false, mountOnEnter: true, timeout: 0 }}
                          expanded={expanded && !isBeingMoved}
                          onChange={isBeingMoved ? undefined : handleExpansion}
                          style={{ position: "relative" }}
                        >
                          <AccordionSummary
                            // className={classes.rootAccordianSummary}
                            sx={isBeingMoved ? styles.rootAccordianMovedSummary : styles.rootAccordianSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${id}-content`}
                            id={`${id}-header`}
                            ref={headerRef}
                          >
                            <HeaderActionContainer>
                              <Aligned style={{ alignItems: "start" }}>
                                {!disableDrag && handle}
                                <H>
                                  <LabelWithHint label={label} helpText={groupHelpHint} />
                                </H>
                                <TagsPreview rule={rule} />
                              </Aligned>
                              <ActionMenu rule={rule} />
                              {!isBeingMoved && <MoveOptions rule={rule} />}
                            </HeaderActionContainer>
                          </AccordionSummary>
                          <AccordionDetails sx={styles.rootAccordianDetails}>
                            <FullWidth>
                              {editMode && <RuleGroupEditor {...props} />}
                              <OperatorHeader operator={operator} level={level} numOperands={nChildren} />
                              {(rule.children as any[]).map((c, i) => (
                                <React.Fragment key={c.id}>
                                  <RuleDispatcher
                                    rule={c}
                                    level={level + 1}
                                    index={i}
                                    parentSection={parentSection}
                                    siblings={nChildren}
                                  />
                                  {i < nChildren - 1 && <OperatorLink operator={operator} level={level} index={i} />}
                                </React.Fragment>
                              ))}
                              {beingAddedToMode && <EditorDispatcher level={level} parentSection={parentSection} />}
                              {beingAddedGroupTo && (
                                <RuleGroupEditor {...props} parentSection={parentSection} rule={null} />
                              )}

                              {provided.placeholder}
                            </FullWidth>
                            {expanded && !editMode && (
                              <RuleBracketLabel group={rule} level={level} topOffset={headerSize?.height ?? 0} />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <NestedGroup
                          rule={rule}
                          parentSection={parentSection}
                          level={level}
                          index={props.index}
                          siblings={nChildren}
                          dragHandle={moveInProgress ? null : handle}
                          controlledExpansion={{
                            expanded,
                            handleExpansion,
                          }}
                          dndPlaceholder={provided.placeholder}
                        ></NestedGroup>
                      )}
                    </DroppableFullWidth>
                  </MovingWrapper>
                );
              }}
            </Droppable>
          </FullWidth>
        )}
      </Draggable>
    </>
  );
};
