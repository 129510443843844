import React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DSSQueryEditor } from "./DSSQueryEditor";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  query: any;
  changeQuery: (newQuery: any) => any;
  close: () => any;
}

export function DSSSearchModal(props: IProps) {
  const { query, close, changeQuery } = props;

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={close}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
    >
      <DSSQueryEditor query={query} changeQuery={changeQuery} close={close} />
    </Dialog>
  );
}
