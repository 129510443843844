import { IComponent, ICourse, ISubject, RuleTargetType } from "store/types";

export const extractSubjectCodes = (mayBeCodes: string) => {
  const codes = mayBeCodes.match(/[A-Z]{4}\d{5}/g);
  return codes || [];
};

export const getParentCourseSysIdForComponentSearch = (
  targetType: RuleTargetType,
  capsObject: IComponent | ICourse | ISubject,
) => {
  switch (targetType) {
    case RuleTargetType.Component:
      return (capsObject as IComponent).parentCourse.versionId;
    case RuleTargetType.Course:
      return (capsObject as ICourse).versionId;
    case RuleTargetType.Subject:
    default:
      return "";
  }
};
