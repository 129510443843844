import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IState } from "store/types";
import { ResponsiveCenter } from "components/spinner/Center";
import { Button, Drawer, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Aligned } from "components/divs/alignedDiv";
import { cancelMove } from "actions/moveRuleState";

const useStyles = () => ({
  drawerPaper: {
    height: "100px",
    paddingTop: "1rem",
    borderTop: "2px solid black",
    background: "darkgray",
    width: "100%",
    zIndex: 2000,
  },
});

export const MoveNotificaiton = () => {
  const moveRuleState = useSelector((state: IState) => state.moveRuleState);

  const styles = useStyles();

  // Get the course on load
  const dispatch = useDispatch();

  const cancel = useCallback(() => {
    dispatch(cancelMove());
  }, [dispatch]);

  if (!moveRuleState) {
    return null;
  }

  return (
    <>
      <Drawer
        anchor={"bottom"}
        variant="permanent"
        open={!!moveRuleState}
        PaperProps={{
          sx: styles.drawerPaper,
        }}
        keepMounted
      >
        <ResponsiveCenter>
          <Aligned style={{ justifyContent: "space-between" }}>
            <Typography variant="h6" variantMapping={{ h6: "h4" }}>
              {moveRuleState.label && `Select the destination for rule "${moveRuleState.label}"`}
              {!moveRuleState.label && `Select the destination for the selected rule`}
            </Typography>
            <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />}>
              Cancel
            </Button>
          </Aligned>
        </ResponsiveCenter>
      </Drawer>
    </>
  );
};
