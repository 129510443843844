interface IOrgUnitDefinition {
  code: string;
  description: string;
  previousDescriptions?: string[];
}

export const OrgUnits: IOrgUnitDefinition[] = [
  { code: "1000", description: "Arts" },
  { code: "1060", description: "Culture and Communication" },
  { code: "1100", description: "Asia Institute" },
  { code: "1140", description: "Graduate School of Humanities and Social Sciences" },
  { code: "1190", description: "Languages and Linguistics" },
  { code: "1310", description: "Historical and Philosophical Studies" },
  { code: "1510", description: "Melbourne School of Government" },
  { code: "1660", description: "Social and Political Sciences" },
  { code: "1960", description: "Social Work" },
  { code: "2070", description: "Geography and Resource Management" },
  { code: "2080", description: "Agriculture and Food Systems" },
  { code: "2200", description: "Ecosystem and Forest Sciences" },
  { code: "2510", description: "Veterinary & Agricultural Sciences" },
  { code: "2540", description: "Veterinary and Agricultural Sciences" },
  { code: "2560", description: "Melbourne Veterinary School" },
  { code: "2570", description: "Veterinary Clinical Sciences" },
  { code: "2580", description: "Veterinary Biosciences" },
  { code: "2590", description: "Agriculture and Food" },
  { code: "2860", description: "Veterinary Hospital" },
  { code: "3000", description: "Business and Economics" },
  { code: "3040", description: "Graduate School of Business and Economics" },
  { code: "3060", description: "Accounting" },
  { code: "3160", description: "Economics" },
  { code: "3200", description: "Business Administration" },
  { code: "3250", description: "Management and Marketing" },
  { code: "3330", description: "Finance" },
  { code: "3360", description: "Melbourne Institute of Applied Economic and Social Research" },
  { code: "3400", description: "Melbourne School of Professional and Continuing Education" },
  { code: "4000", description: "Engineering and IT", previousDescriptions: ["Engineering"] },
  { code: "4110", description: "Chemical Engineering" },
  { code: "4120", description: "Biomedical Engineering" },
  { code: "4130", description: "Chemical and Biomedical Engineering" },
  { code: "4140", description: "Electrical, Mechanical and Infrastructure Engineering" },
  { code: "4180", description: "Computing and Information Systems" },
  { code: "4310", description: "Electrical and Electronic Engineering" },
  { code: "4320", description: "Infrastructure Engineering" },
  { code: "4360", description: "Mechanical Engineering" },
  { code: "4600", description: "Melbourne Graduate School of Education" },
  { code: "5000", description: "Medicine, Dentistry and Health Sciences" },
  { code: "5010", description: "Doherty Institute" },
  { code: "5040", description: "Rural Health Academic Centre" },
  { code: "5050", description: "Melbourne School of Population and Global Health" },
  { code: "5070", description: "Centre for Youth Mental Health" },
  { code: "5090", description: "Nossal Institute for Global Health" },
  { code: "5100", description: "Melbourne Medical School" },
  { code: "5110", description: "Melbourne Dental School" },
  { code: "5120", description: "Melbourne School of Psychological Sciences" },
  { code: "5130", description: "Physiotherapy" },
  { code: "5140", description: "Nursing" },
  { code: "5160", description: "Anatomy and Physiology", previousDescriptions: ["Anatomy and Neuroscience"] },
  { code: "5200", description: "Otolaryngology" },
  {
    code: "5210",
    description: "Biochemistry and Pharmacology",
    previousDescriptions: ["Biochemistry and Molecular Biology"],
  },
  { code: "5260", description: "Microbiology and Immunology" },
  { code: "5300", description: "School of Biomedical Sciences" },
  { code: "5310", description: "Pathology" },
  { code: "5330", description: "Baker Department of Cardiometabolic Health" },
  { code: "5340", description: "Pharmacology and Therapeutics" },
  { code: "5360", description: "Physiology" },
  { code: "5430", description: "Medicine - Austin Health" },
  { code: "5460", description: "Surgery - Austin Health" },
  { code: "5510", description: "Medical Education" },
  { code: "5520", description: "Surgery" },
  { code: "5530", description: "Medicine - Royal Melbourne Hospital" },
  { code: "5540", description: "Psychiatry" },
  { code: "5550", description: "Radiology" },
  { code: "5560", description: "Surgery - Royal Melbourne Hospital" },
  { code: "5570", description: "Medicine and Radiology" },
  { code: "5630", description: "Medicine - St Vincent's Hospital" },
  { code: "5660", description: "Surgery - St Vincent's Hospital" },
  { code: "5690", description: "Clinical Pathology" },
  { code: "5690", description: "Clinical Pathology" },
  { code: "5700", description: "School of Health Sciences" },
  { code: "5710", description: "Paediatrics Royal Children's Hospital" },
  { code: "5760", description: "Ophthalmology Eye and Ear Hospital" },
  { code: "5770", description: "Audiology and Speech Pathology" },
  { code: "5790", description: "Obstetrics and Gynaecology Royal Women's Hospital/Mercy" },
  { code: "5850", description: "General Practice" },
  { code: "5870", description: "Rural Health" },
  { code: "5970", description: "Medical Biology (W.E.H.I.)" },
  { code: "6030", description: "Science" },
  { code: "6100", description: "Chemistry" },
  { code: "6200", description: "Mathematics and Statistics" },
  { code: "6230", description: "Geography" },
  { code: "6250", description: "Earth Sciences" },
  { code: "6300", description: "BioSciences" },
  { code: "6400", description: "Physics" },
  { code: "6550", description: "Optometry and Vision Sciences" },
  {
    code: "6700",
    description: "Geography, Earth and Atmospheric Sciences",
    previousDescriptions: ["Geography, Earth & Atmospheric Sciences"],
  },
  { code: "7020", description: "Architecture, Building and Planning" },
  { code: "7320", description: "Law" },
  { code: "7410", description: "VCA and MCM" },
  { code: "7420", description: "Melbourne Conservatorium of Music" },
  { code: "7500", description: "Victorian College of the Arts" },
  { code: "7620", description: "Fine Arts and Music" },
  { code: "8760", description: "UoM Commercial Ltd" },
  { code: "8780", description: "Melbourne Business School" },
  { code: "9510", description: "The Sir Peter MacCallum Department of Oncology" },
  { code: "9520", description: "Florey Department of Neuroscience and Mental Health" },
  { code: "9560", description: "Medical Bionics Department" },
  { code: "9590", description: "MHRI Department of Neuroscience in Mental Health" },
];

export const getOrgUnitDefinition = (txt: string) => {
  let match = OrgUnits.find((u) => String(u.code) === String(txt));
  if (!match) {
    match = OrgUnits.find((u) => u.description === String(txt));
  }
  if (!match) {
    match = OrgUnits.find((u) => (u.previousDescriptions ?? []).find((d) => d === String(txt)));
  }
  return match;
};

export const getOrgUnitCode = (org: string | string[]) =>
  Array.isArray(org) ? org.map((t) => getOrgUnitDefinition(t)?.code ?? t) : getOrgUnitDefinition(org)?.code ?? org;

export const getOrgUnitDescription = (org: string | string[]) =>
  Array.isArray(org)
    ? org.map((t) => getOrgUnitDefinition(t)?.description ?? t)
    : getOrgUnitDefinition(org)?.description ?? org;
