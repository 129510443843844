import { Grid } from "@mui/material";
import styled from "styled-components";

export const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;
export const H3 = styled.h3`
  font-size: 1.3em;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;
export const H4 = styled.h4`
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;
export const H5 = styled.h5`
  font-size: 1.1em;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;
export const H6 = styled.h6`
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;
export const Strong = styled.strong``;

export const NextLevelContainer = styled.div`
  width: -webkit-fill-available;
  padding-left: 2rem;
`;

export const headingForLevel = (level: number) => {
  switch (level) {
    case 0:
      return H2;
    case 1:
      return H3;
    case 2:
      return H4;
    case 3:
      return H5;
    case 4:
      return H6;
    default:
      return Strong;
  }
};

export const HeaderActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .reveal-on-hover {
    visibility: hidden;
  }
  &:hover .reveal-on-hover {
    visibility: visible;
  }
`;

export const EditorGrid = styled(Grid)`
  margin-left: 1rem !important;
  padding: 1rem 0.5rem 1rem 0.5rem;
  background: white;
  width: calc(100% - 1rem);

  // border: 1px solid red;
`;

export const EditorForm = styled.form`
  width: 100%;
  margin-right: 1rem;
`;
