import React, { useEffect } from "react";
import { IRuleEditorProps } from "../common/types";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { commonStyles } from "theme/styles";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { IInfoEditorFormState } from "./InformationEditor";
import { ICourse, IState, RuleComponentType, Stereotype } from "store/types";
import { EditorForm } from "../common/ruleComponent";
import { RulePreview } from "../common/rulePreview";
import { featureToggles } from "config/featureToggles";
import { useFormatsSamplesAndTypes } from "../editor/editorHooks";
import { getRuleUILabels } from "lib/rules";
import { useSelector } from "react-redux";

export const ProgressionEditorFields = (props: IRuleEditorProps) => {
  const theme = useTheme();
  const syles = commonStyles(theme);
  const { rule, changeRuleType, ruleType } = props;

  const formik = useFormikContext<IInfoEditorFormState>();
  const coursePoints = useSelector((s: IState) => (s.capsObject as ICourse).points);
  const maxPoints = parseFloat((coursePoints || 300) as any);

  // Notify the dispatcher that we need to render a new rule type form
  useEffect(() => {
    if (formik.values.type !== ruleType) {
      changeRuleType(formik.values.type);
    }
  }, [changeRuleType, formik.values.type, ruleType]);

  const { allowedFormats, samples, allRuleTypes } = useFormatsSamplesAndTypes(
    props,
    formik.values.selectedOrDefaultFormat,
  );

  return (
    <EditorForm onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-type-select" sx={syles.label}>
            Rule type
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="type"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps as any}
              inputProps={{ id: "rule-type-select" }}
              disabled={!!rule}
            >
              {allRuleTypes.map((t) => {
                const uiLabels = getRuleUILabels(t);
                return (
                  <MenuItem key={t} value={t}>
                    <LabelWithHint
                      label={
                        <div>
                          {uiLabels?.title}
                          {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                        </div>
                      }
                      helpText={uiLabels.helpText}
                    />
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8} md={6}>
          <PaddedLabel htmlFor="rule-progression-increment-select" sx={syles.label}>
            <LabelWithHint
              label="Progression increment"
              helpText="Choose which level of subjects are required before progressing to the next level"
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name={"level"}
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps}
              inputProps={{ id: "rule-progression-increment-select" }}
            >
              {[1, 2].map((l) => (
                <MenuItem key={`progression-increment-value-${l}`} value={l}>
                  Level {l} to Level {l + 1}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={6}>
          <PaddedLabel htmlFor="points-text-input" sx={syles.label}>
            Credit Points
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="points"
              label=""
              fullWidth={true}
              variant="outlined"
              type="number"
              inputProps={{
                "aria-label": "points",
                id: "points-text-input",
                min: 6.25,
                max: maxPoints,
                step: 6.25,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-presentation-format-select" sx={syles.label}>
            <LabelWithHint
              label="Published format"
              helpText="Choose the way this rule is presented in CAPS, the Handbook and My Course Planner, by selecting what information is displayed and how. This format will also interact with the parent (Rule Group) format."
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Published format",
                id: "rule-presentation-format-select",
              }}
            >
              {allowedFormats.map((f) => (
                <MenuItem style={{ whiteSpace: "normal" }} key={f.name} value={f.name}>
                  {f.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {(samples.length > 0 || !!featureToggles.previewRuleBeforeSave) && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={{
                ruleProps: {
                  stereotype: Stereotype.RuleComponent,
                  ruleType: RuleComponentType.Progression,
                  parameters: formik.values,
                },
                isValid: formik.isValid,
              }}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleComponent}
              ruleType={ruleType}
              ruleId={rule?.id}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <RuleFormActions
            ruleId={rule?.id}
            submitDisabled={!formik.isValid || !formik.dirty}
            submitting={formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </EditorForm>
  );
};
