import React from "react";
import { Formik } from "formik";
import { MMSQueryEditorFormContent } from "./MMSQueryEditorFormContent";
import { useSelector } from "react-redux";
import { IState, RuleTargetType } from "store/types";

export interface IMMSQueryEditorFormState {
  nameOrCode?: string;
  name?: string;
  code?: string;
  type: string[];
  points: string;
  parentCourseNameOrCode?: string;
}

interface IProps {
  mmsRecordIds: string[];
  addMMS: (s: string) => any;
  close: () => any;
}

export const MMSQueryEditor = (props: IProps) => {
  const targetType = useSelector((s: IState) => s.targetType);
  const initialValues: IMMSQueryEditorFormState =
    targetType === RuleTargetType.Subject
      ? {
          name: "",
          code: "",
          type: [],
          points: "",
          parentCourseNameOrCode: "",
        }
      : {
          nameOrCode: "",
          type: [],
          points: "",
        };

  return (
    <Formik initialValues={initialValues} onSubmit={props.close}>
      <MMSQueryEditorFormContent {...props} />
    </Formik>
  );
};
