import React from "react";

import { Aligned } from "components/divs/alignedDiv";
import { Collapse, IconButton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { HeaderActionContainer, NextLevelContainer } from "../common/ruleComponent";
import styled from "styled-components";
import { LabelWithHint } from "components/help-hint/HelpHint";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import { IState } from "store/types";

interface IProps {
  children: any;
}

const H2 = styled.h2`
  padding-top: 0.25rem;
  margin: 0.25rem;
`;

export const ParentSharedSetsHeading = (props: IProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const handleExpansion = React.useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);
  const newlyCreatedRules = useSelector((s: IState) => s.capsObject?.newlyCreatedRules);

  return newlyCreatedRules ? null : (
    <>
      <HeaderActionContainer>
        <Aligned>
          <H2>
            <LabelWithHint
              label={
                <Aligned>
                  <LinkIcon /> Course Subject Lists
                </Aligned>
              }
              helpText="A view only list of the parent course's shared subject lists."
            />
          </H2>
          <IconButton
            color="primary"
            onClick={handleExpansion}
            aria-label={expanded ? "expand less button" : "expand more button"}
            size="large"
          >
            {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
        </Aligned>
      </HeaderActionContainer>
      <NextLevelContainer>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: "-webkit-fill-available" }}>
          {props.children}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
