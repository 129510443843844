import React, { useState, useEffect, useCallback } from "react";
import { getLabel, numericLabelSuffix, getMMSReferences } from "lib/parameters";
import { IRulesProps } from "../common/types";
import { IState, RuleComponentType } from "store/types";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { NextLevelContainer } from "../common/ruleComponent";
import styled from "styled-components";
import { StaticMMSSet } from "./StaticMMSSet";
import { DraggableConstraintHeader } from "../common/draggableConstraintHeader";

const Strong = styled.strong`
  margin-left: 1.5rem;
  font-size: 95%;
`;

export const MMSNumConstraint = (props: IRulesProps) => {
  const { rule, level, dragHandle, parentSection, parentTemplate } = props;

  const ruleLabel = getLabel(rule);
  const suffix = numericLabelSuffix(rule, rule.type === RuleComponentType.PointsConstraint ? "pts" : "");
  const label = [ruleLabel, suffix].filter(Boolean).join(" / ");

  const embeddedMMSSet = rule.children[0];
  const isEmbeddedMMSSetEmpty = getMMSReferences(embeddedMMSSet).length === 0;

  const expandedSections = useSelector((s: IState) => s.editorState.expandedSections);
  const expandParentSection = (expandedSections && parentSection && expandedSections[parentSection.id]) || false;

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  /** set expanded to false on initial render, and then use value from expandParentSection or handleExpansion */
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(expandParentSection);
  }, [expandParentSection]);

  // reset to false on initial render
  useEffect(() => {
    setExpanded(false);
  }, []);
  const handleExpansion = useCallback(() => {
    if (!isBeingMoved) {
      setExpanded((oldValue) => !oldValue);
    }
  }, [isBeingMoved]);

  const draggableConstraintHeaderProps = {
    expanded: expanded && !isBeingMoved,
    dragHandle,
    handleExpansion,
    label,
    level,
    rule,
    parentTemplate,
  };

  return (
    <>
      <DraggableConstraintHeader {...draggableConstraintHeaderProps} />
      <NextLevelContainer>
        <Collapse in={expanded} timeout={0} mountOnEnter style={{ width: "-webkit-fill-available" }}>
          {!isEmbeddedMMSSetEmpty && (
            <>
              <Strong>Included MMS:</Strong>
              <NextLevelContainer>
                <StaticMMSSet rule={embeddedMMSSet} level={level} index={0} siblings={1} />
              </NextLevelContainer>
            </>
          )}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
