import { ITag, IRuleComponent } from "store/types";

export const getTag = (tagName: string, rule: IRuleComponent) => {
  return rule?.tags?.find((t) => t.name === tagName);
};

export const hasTag = (tagName: string, rule: IRuleComponent) => !!getTag(tagName, rule);

export enum CommonTag {
  CORE = "isCoreCompulsory",
  HANDBOOK_ONLY = "handbookOnly",
  CPT_ONLY = "cptOnly",
  EXCLUSIVE_GROUP = "exclusiveGroup",
  HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK = "HideTotalCoursePointsInHandbook",
  COUNT_ALL_SUBJECTS = "CountAllSubjectsForComponent",
}

const commonTagLabels: Record<CommonTag, string> = {
  [CommonTag.CORE]: "Core / Compulsory",
  [CommonTag.HANDBOOK_ONLY]: "Handbook Only",
  [CommonTag.CPT_ONLY]: "My Course Planner only",
  [CommonTag.EXCLUSIVE_GROUP]: "Exclusive Group",
  [CommonTag.HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK]: "Hide Course Points In Handbook",
  [CommonTag.COUNT_ALL_SUBJECTS]: "Count All Plan Subjects (Assigned and Unassigned)",
};

export const CoreTag: ITag = {
  name: CommonTag.CORE,
  label: commonTagLabels[CommonTag.CORE],
};

export enum DestinationTag {
  SEM1 = "Semester1",
  SEM2 = "Semester2",
  SEM3 = "Semester3",
  SEM4 = "Semester4",
  SEM5 = "Semester5",
  SEM6 = "Semester6",
}

const destinationTagLabels: Record<DestinationTag, string> = {
  [DestinationTag.SEM1]: "First semester of the course/program",
  [DestinationTag.SEM2]: "Second semester of the course/program",
  [DestinationTag.SEM3]: "Third semester of the course/program",
  [DestinationTag.SEM4]: "Fourth semester of the course/program",
  [DestinationTag.SEM5]: "Fifth semester of the course/program",
  [DestinationTag.SEM6]: "Sixth semester of the course/program",
};

export const getTagLabel = (tag: string) =>
  commonTagLabels[tag as CommonTag] ?? destinationTagLabels[tag as DestinationTag] ?? tag;
