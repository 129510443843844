import isString from "lodash/isString";
import isArray from "lodash/isArray";
import { IRuleComponent } from "store/types";
import { getRuleUsageChainRecursive } from "./rules";

export const isBlank = (v: string | null | undefined) => {
  const value = isString(v) ? v.trim() : v;
  return !value;
};

export const validateValuePresence = (errors: any, values: any, errorKey: string, label = "Required") => {
  const value = values[errorKey];
  if (isBlank(value) || (isArray(value) && value.length === 0)) {
    errors[errorKey] = label;
  }
};

export const validateNumericValuePresence = (errors: any, values: any, errorKey: string, label = "Required") => {
  const value = values[errorKey];
  if (isBlank(value) || value.length === 0) {
    errors[errorKey] = label;
  }
};

export const validateSharedSubjectSetUsage = (rules: IRuleComponent, ruleId: string) => {
  const result = getRuleUsageChainRecursive(rules, rules, ruleId);
  if (result) {
    return {
      used: true,
      usageChains: result,
    };
  }
  return {
    used: false,
    usageChains: [],
  };
};
