import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Fab from "@mui/material/Fab";
import { ResponsiveCenter } from "components/spinner/Center";
import { commonSearchModalStyles } from "theme/styles";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { Aligned } from "components/divs/alignedDiv";
import { useTheme } from "@mui/material";

interface ISearchModalHeaderProps {
  title: string;
  onClose: () => any;
  onSave: () => any;
}

export const SearchModalHeader = ({ title, onClose, onSave }: ISearchModalHeaderProps) => {
  const theme = useTheme();
  const styles = commonSearchModalStyles(theme);
  return (
    <AppBar sx={styles.appBar}>
      <ResponsiveCenter>
        <Toolbar sx={styles.toolbar}>
          <h2>{title}</h2>
          <Aligned>
            <Fab variant="extended" onClick={onClose} aria-label="Close" sx={styles.closeButton}>
              <CloseIcon />
              &nbsp; Cancel
            </Fab>
            &nbsp; &nbsp;
            <Fab type="submit" variant="extended" size="large" onClick={onSave} aria-label="save">
              <SaveIcon />
              &nbsp; Save
            </Fab>
          </Aligned>
        </Toolbar>
      </ResponsiveCenter>
    </AppBar>
  );
};
