/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Divider, IconButton, Theme, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IState, IGeneratedRuleText, IRuleText, RuleSectionType, Stereotype } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { flipPreviewDrawer, resizeDrawer } from "actions/previewDrawerState";
import "./PreviewDrawer.css";
import {
  getAllShareRuleIds,
  getChildrenByStereotype,
  getHighligthedChildrenIds,
  getParentTemplateId,
  getPreviewParentId,
} from "lib/rules";
import { getClickedRuleId, higligth } from "./previewDom";
import { flipPreviewRule } from "actions/editorState";
import { AutoGeneratedDialog } from "./AutoGeneratedDialog";
import { getLabel } from "lib/parameters";

// Ref: draggable sized drawer - https://stackblitz.com/edit/react-2h1g6x?file=ResponsiveDrawer.js

// const drawerWidth = window.screen.width / 4;

const useStyles = (theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    position: "relative",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  dragger: {
    width: 5,
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: theme.palette.grey[100],
  },
  ruleText: {
    padding: "1rem",
    cursor: "pointer",
  },
  disclaimerText: {
    padding: "0rem 1rem 0 1rem",
  },
});

const mapToCapsRuleSectionName = (sectionName: RuleSectionType) => {
  switch (sectionName) {
    case RuleSectionType.MMSGroups:
      return "MMS Subpages";
    case RuleSectionType.ComponentStructure:
      return "Structure";
    case RuleSectionType.MMSPage:
      return "MMS Subpages";
    case RuleSectionType.Prerequisites:
      return "Prerequisite Description";
    case RuleSectionType.Corequisites:
      return "Corequisite Description";
    case RuleSectionType.NonAllowedSubjects:
      return "Disallowed Description";
    default:
      return sectionName;
  }
};

export const PreviewDrawer = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const generatedRuleText: IGeneratedRuleText[] = useSelector((s: IState) => s?.capsObject?.ruleTextIdentified ?? []);

  // const generatedRuleText: IGeneratedRuleText[] = useSelector(
  //   (s: IState) => (s?.capsObject && (s?.capsObject as any)["ruleText"]) ?? [],
  // );
  const dispatch = useDispatch();

  const open = useSelector((s: IState) => s.previewDrawerState.open);
  const rootRules = useSelector((s: IState) => s.capsObject?.rules);
  const sections = useMemo(
    () => getChildrenByStereotype(rootRules, rootRules?.id, [Stereotype.RuleSection]),
    [rootRules],
  );

  // Handle the highligthing
  const ruleId = useSelector((s: IState) => s.editorState.editedRuleId || s.editorState.previewedRuleId);
  const editedRuleId = ruleId && getParentTemplateId(rootRules, ruleId);
  const childrenIds = getHighligthedChildrenIds(rootRules, ruleId);
  const sharedRuleIds = getAllShareRuleIds(rootRules);
  const [showPreviewWarning, setShowPreviewWarning] = useState(false);
  const flipPreviewWarning = useCallback(() => setShowPreviewWarning((old) => !old), [setShowPreviewWarning]);

  useEffect(() => {
    higligth(ruleId, editedRuleId, childrenIds, sharedRuleIds);
  }, [ruleId, editedRuleId, childrenIds, sharedRuleIds]);

  // Handle the click on the preview HTML
  const onClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      const ruleId = getClickedRuleId(e);
      const previewParentId = getPreviewParentId(rootRules, ruleId);
      if (!previewParentId) {
        flipPreviewWarning();
      }

      // getPreviewParentId(rootRules, ruleId);
      dispatch(flipPreviewRule(previewParentId ?? ruleId));
    },
    [dispatch, flipPreviewWarning, rootRules],
  );

  const flipDrawerVisibility = useCallback(() => dispatch(flipPreviewDrawer()), [dispatch]);

  // Handle resizing logic
  const width = useSelector((s: IState) => s.previewDrawerState.width);
  const resize = useCallback((w: string) => dispatch(resizeDrawer(w)), [dispatch]);
  const [resizing, setResizing] = useState(false);
  const handleMouseDown = useCallback((_e: any) => setResizing(true), [setResizing]);
  useEffect(() => {
    const handleMouseMove = (e: any) => {
      // we don't want to do anything if we aren't resizing.
      if (!resizing) {
        return;
      }

      const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      const minWidth = 50;
      const maxWidth = window.screen.width - window.screen.width / 8;
      if (offsetRight > minWidth && offsetRight < maxWidth) {
        resize(`${offsetRight}px`);
      }
    };
    const handleMouseUp = () => {
      setResizing(false);
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [resize, resizing]);

  return (
    <>
      <AutoGeneratedDialog open={showPreviewWarning} onConfirm={flipPreviewWarning} />
      <Drawer
        sx={styles.drawer}
        anchor="right"
        variant="persistent"
        open={open}
        ModalProps={{
          keepMounted: true,
          onBackdropClick: flipDrawerVisibility,
        }}
        PaperProps={{ style: { width } }}
      >
        <div style={{ position: "relative", height: "auto" }} id="preview-drawer-container">
          <div id="dragger" onMouseDown={handleMouseDown} style={styles.dragger as any} />
          <div style={styles.drawerHeader}>
            <IconButton aria-label="close right drawer" onClick={flipDrawerVisibility} size="large">
              {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <h2>CAPS Preview</h2>
          </div>
          <Divider />
          <p style={styles.disclaimerText}>
            <strong>⚠️&nbsp; Please note:</strong> this output corresponds to what you should expect to see in CAPS, not
            the handbook.
          </p>
          <Divider />
          {generatedRuleText.length > 0 ? (
            generatedRuleText
              .filter((gr: IGeneratedRuleText) => typeof gr === "object")
              .map((gr: IGeneratedRuleText, i) => {
                const sectionName = (gr as IRuleText).section;
                const capsSectionName = mapToCapsRuleSectionName((gr as IRuleText).section as RuleSectionType);
                const section = sections.find((s) => {
                  const label = getLabel(s);
                  return label === sectionName || label === capsSectionName;
                });

                const sectionKey = `${(gr as IRuleText).section}-${i}`;

                return (
                  <React.Fragment key={sectionKey}>
                    <div id={section?.id ?? sectionKey} onClick={onClick} key={sectionKey} style={styles.ruleText}>
                      <h2>{capsSectionName}</h2>
                      <div onClick={onClick} dangerouslySetInnerHTML={{ __html: (gr as IRuleText).html }} />
                    </div>
                    {i < generatedRuleText.length && <Divider />}
                  </React.Fragment>
                );
              })
          ) : (
            <p style={styles.ruleText}>No rules</p>
          )}
        </div>
      </Drawer>
    </>
  );
};
