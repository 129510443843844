import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DialogContent, Grid, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { MMSTable } from "../../common/mmsTable";
import { MMSQueryEditor } from "./MMSQueryEditor";
import { SearchModalHeader } from "containers/rules/common/searchModalHeader";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  mmsRecordIds: string[];
  addMMS: (id: string) => any;
  deleteMMS: (id: string) => any;
  close: () => any;
}

export function MMSSearchModal(props: IProps) {
  const { addMMS, deleteMMS, close, mmsRecordIds } = props;
  const noMMS = mmsRecordIds.length === 0;

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={close}
      TransitionComponent={Transition}
      // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
      TransitionProps={{ role: "presentation" } as any}
    >
      <SearchModalHeader title={"Component Finder"} onClose={close} onSave={close} />

      <DialogContent>
        <Grid container spacing={0} alignItems="center" justifyContent="center" direction="column">
          {!noMMS && (
            <Grid item xs={12} sm={10} md={9} lg={8} xl={6} style={{ width: "inherit" }}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <strong>Component Scope</strong>
                <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
                  <MMSTable
                    mmsRecordIds={mmsRecordIds}
                    rowEndAction={(id: string) => (
                      <td align="center">
                        <IconButton size="small" color="primary" onClick={() => deleteMMS(id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </td>
                    )}
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={10} md={9} lg={8} xl={6} style={{ width: "inherit" }}>
            <MMSQueryEditor mmsRecordIds={mmsRecordIds} addMMS={addMMS} close={close} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
