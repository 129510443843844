import sortBy from "lodash/sortBy";
import { featureToggles } from "config/featureToggles";
export const Name = [];
//Honours (Level 4)
export const Level = featureToggles.search
  ? ["1", "2", "3", "4", "Graduate coursework", "Graduate research"]
  : ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const StudyType = ["Coursework", "Research"];

export interface IAreaOfStudy {
  code: string;
  description: string;
}

export const AreaOfStudy = sortBy(
  [
    { code: "ENEN", description: "Environmental Engineering" },
    { code: "FNCE", description: "Finance" },
    { code: "GEOG", description: "Geography" },
    { code: "HORT", description: "Horticulture" },
    { code: "ENGL", description: "English" },
    { code: "EURO", description: "European Studies" },
    { code: "GENP", description: "General Practice" },
    { code: "HIST", description: "History" },
    { code: "ENGR", description: "Engineering" },
    { code: "FREN", description: "French" },
    { code: "HLTH", description: "Health" },
    { code: "ENST", description: "Environmental Studies" },
    { code: "FOOD", description: "Food Science" },
    { code: "GEOM", description: "Geomatics" },
    { code: "INDO", description: "Indonesian" },
    { code: "ISLM", description: "Islamic Studies" },
    { code: "MEED", description: "Medical Education" },
    { code: "NRMT", description: "Natural Resource Management" },
    { code: "ACCT", description: "Accounting" },
    { code: "INHL", description: "International Health" },
    { code: "LANG", description: "Language" },
    { code: "MCEN", description: "Mechanical Engineering" },
    { code: "MUSI", description: "Music" },
    { code: "ISYS", description: "Information Systems" },
    { code: "JOUR", description: "Journalism" },
    { code: "MKTG", description: "Marketing" },
    { code: "MGRK", description: "Modern Greek" },
    { code: "JAPN", description: "Japanese" },
    { code: "MGMT", description: "Management" },
    { code: "MEDS", description: "Medicine/Surgery" },
    { code: "AGRI", description: "Agriculture" },
    { code: "AHIS", description: "Art History" },
    { code: "BCMB", description: "Biochemistry and Molecular Biology" },
    { code: "BOTA", description: "Botany" },
    { code: "PAED", description: "Paediatrics" },
    { code: "PLAN", description: "Planning" },
    { code: "PROP", description: "Property" },
    { code: "RURA", description: "Rural Health" },
    { code: "ABPL", description: "Architecture, Building & Planning" },
    { code: "AIND", description: "Australian Indigenous Studies" },
    { code: "BIEN", description: "Biomolecular Engineering" },
    { code: "ORAL", description: "Oral Health" },
    { code: "PHYS", description: "Physiology" },
    { code: "POLS", description: "Political Science" },
    { code: "RADI", description: "Radiology" },
    { code: "ARBC", description: "Arabic" },
    { code: "ATOC", description: "Atmosphere and Ocean Sciences" },
    { code: "BMSC", description: "Biomedical Science" },
    { code: "OPHT", description: "Ophthalmology" },
    { code: "PHIL", description: "Philosophy" },
    { code: "PPMN", description: "Public Policy and Management" },
    { code: "PPMN", description: "Public Policy and Management" },
    { code: "ANCW", description: "Ancient World Studies" },
    { code: "AMGT", description: "Arts Management" },
    { code: "BIOL", description: "Biology" },
    { code: "BISY", description: "Business Information Systems" },
    { code: "NURS", description: "Nursing Science" },
    { code: "PERF", description: "Performing Arts" },
    { code: "PSYC", description: "Psychology" },
    { code: "CLRS", description: "Clinical Research" },
    { code: "DNCE", description: "Dance" },
    { code: "ECOM", description: "Econometrics" },
    { code: "SCWK", description: "Social Work" },
    { code: "TRAN", description: "Translation and Interpretation" },
    { code: "STDY", description: "Study Abroad" },
    { code: "CVEN", description: "Civil Engineering" },
    { code: "CUMC", description: "Cultural Materials Conservation" },
    { code: "ERTH", description: "Earth Sciences" },
    { code: "SPAN", description: "Spanish and Latin American Studies" },
    { code: "THTR", description: "Theatre Studies" },
    { code: "WOHT", description: "Women's Health" },
    { code: "XNTS", description: "Cross-institutional" },
    { code: "CEDB", description: "Cell and Developmental Biology" },
    { code: "CHIN", description: "Chinese" },
    { code: "CWRI", description: "Creative Writing" },
    { code: "DASC", description: "Domestic Animal Science" },
    { code: "SCRN", description: "Screen Studies" },
    { code: "SURG", description: "Surgery" },
    { code: "VISM", description: "Visual Media" },
    { code: "ZOOL", description: "Zoology" },
    { code: "CHEN", description: "Chemical Engineering" },
    { code: "COMP", description: "Computer Science" },
    { code: "DESN", description: "Design" },
    { code: "EDUC", description: "Education" },
    { code: "SOCI", description: "Sociology" },
    { code: "URBD", description: "Urban Design" },
    { code: "LTAM", description: "Latin American Studies" },
    { code: "LING", description: "Linguistics and Applied Linguistics" },
    { code: "SCIE", description: "Science" },
    { code: "CREA", description: "Creative Arts" },
    { code: "ENVS", description: "Environments" },
    { code: "FRST", description: "Forest Science" },
    { code: "GERM", description: "German" },
    { code: "INFO", description: "Informatics" },
    { code: "EVSC", description: "Environmental Science" },
    { code: "FINA", description: "Fine Art" },
    { code: "GEOL", description: "Geology" },
    { code: "INAM", description: "Indigenous Arts Management" },
    { code: "ESLA", description: "English as a Second Language" },
    { code: "FLTV", description: "Film and Television" },
    { code: "GENE", description: "Genetics" },
    { code: "HPSC", description: "History and Philosophy of Science" },
    { code: "ENGM", description: "Engineering Management" },
    { code: "GEND", description: "Gender Studies" },
    { code: "HEBR", description: "Hebrew" },
    { code: "JEWI", description: "Jewish Studies" },
    { code: "MFEN", description: "Manufacturing Engineering" },
    { code: "MIIM", description: "Microbiology and Immunology" },
    { code: "ITAL", description: "Italian" },
    { code: "MEDI", description: "Medicine" },
    { code: "INDG", description: "Indigenous Studies" },
    { code: "ACTL", description: "Actuarial Studies" },
    { code: "INTS", description: "International Studies" },
    { code: "MECM", description: "Media and Communications" },
    { code: "MUST", description: "Music Theatre" },
    { code: "DPSS", description: "Design & Production for Stage & Screen" },
    { code: "IBUS", description: "International Business" },
    { code: "LARC", description: "Landscape Architecture" },
    { code: "MAST", description: "Mathematics and Statistics" },
    { code: "MULT", description: "Multidisciplinary subject" },
    { code: "ANTH", description: "Anthropology" },
    { code: "ASIA", description: "Asian Studies" },
    { code: "BMEN", description: "Biomedical Engineering" },
    { code: "OBGY", description: "Obstetrics & Gynaecology" },
    { code: "PHRM", description: "Pharmacology" },
    { code: "PADM", description: "Public Administration" },
    { code: "SINF", description: "Science Informatics" },
    { code: "ANAT", description: "Anatomy" },
    { code: "ARTS", description: "Arts" },
    { code: "BINF", description: "Bioinformatics" },
    { code: "BUSA", description: "Business Administration" },
    { code: "NEUR", description: "Neuroscience" },
    { code: "PATH", description: "Pathology" },
    { code: "PSYT", description: "Psychiatry" },
    { code: "RUSS", description: "Russian" },
    { code: "ACUR", description: "Art Curatorship" },
    { code: "AUST", description: "Australian Studies" },
    { code: "BTCH", description: "Biotechnology" },
    { code: "OTOL", description: "Otolaryngology" },
    { code: "PHTY", description: "Physiotherapy" },
    { code: "POPH", description: "Population Health" },
    { code: "REHB", description: "Rehabilitation Therapy" },
    { code: "ARCH", description: "Architecture" },
    { code: "AUDI", description: "Audiology" },
    { code: "BIOM", description: "Biomedicine" },
    { code: "OPTO", description: "Optometry and Vision Sciences" },
    { code: "PHYC", description: "Physics" },
    { code: "PUBL", description: "Publishing" },
    { code: "BLAW", description: "Business Law" },
    { code: "CHEM", description: "Chemistry" },
    { code: "CONS", description: "Construction" },
    { code: "DEVT", description: "Development Studies" },
    { code: "ELEN", description: "Electrical Engineering" },
    { code: "SWEN", description: "Software Engineering" },
    { code: "VETS", description: "Veterinary Science" },
    { code: "KORE", description: "Korean Studies" },
    { code: "CCDP", description: "Community Cultural Development Practice" },
    { code: "DENT", description: "Dentistry" },
    { code: "ECON", description: "Economics" },
    { code: "SOLS", description: "Socio-Legal Studies" },
    { code: "UNIB", description: "University Breadth" },
    { code: "SMED", description: "Sports Medicine" },
    { code: "CLAS", description: "Classics" },
    { code: "CULS", description: "Cultural Studies" },
    { code: "ECOL", description: "Ecology" },
    { code: "SOTH", description: "Social Theory" },
    { code: "THEO", description: "Theology" },
    { code: "EXCH", description: "Exchange" },
    { code: "CICU", description: "Cinema and Cultural Studies" },
    { code: "CRIM", description: "Criminology" },
    { code: "DRAM", description: "Drama" },
    { code: "SKIL", description: "Skills" },
    { code: "SWED", description: "Swedish" },
    { code: "WELF", description: "Welfare Studies" },
    { code: "ADMX", description: "Administrative" },
    { code: "LAWS", description: "Law" },
    { code: "MREN", description: "Materials Engineering" },
    { code: "NUTR", description: "Human Nutrition" },
    { code: "ANSC", description: "Animal Science" },
    { code: "GDES", description: "Graphic Design" },
    { code: "CMCE", description: "Commerce" },
  ],
  "description",
);

/**boolean or empty (for both true and false search) */
export const Discontinue = featureToggles.search ? ["true", "false", ""] : ["true", "false"];
/**integer */
export const CreditPoints = [6.25, 12.5, 25, 50];

export const ComponentTypes = [
  {
    label: "Major",
    value: "major",
  },
  {
    label: "Minor",
    value: "minor",
  },
  {
    label: "Specialisation (formal)",
    value: "spec",
  },
  {
    label: "Informal specialisation",
    value: "infspc",
  },
  {
    label: "Stream",
    value: "stream",
  },
  {
    label: "Course Entry Point",
    value: "entry",
  },
  {
    label: "Pathway",
    value: "path",
  },
  {
    label: "Study Option",
    value: "study_option",
  },
];
