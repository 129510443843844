import { MOVE_RULE } from "actions/capsObject";
import { SET_RULE_MOVE } from "actions/moveRuleState";
import { fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { IMoveRuleState } from "store/types";

const moveRuleState = (state = initialState.moveRuleState, action: any): IMoveRuleState | null => {
  switch (action.type) {
    case SET_RULE_MOVE:
      return action.payload;
    case fulfilled(MOVE_RULE):
      return null;
    default:
      return state;
  }
};

export default moveRuleState;
