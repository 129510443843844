import React from "react";
import { MemoTableCell, MemoTableCellBlueText } from "lib/memoTable";
import { useDispatch, useSelector } from "react-redux";
import { IState, RuleTargetType } from "store/types";
import { brandLightBlue } from "theme/colors";
import { commonStyles } from "theme/styles";
import { getMMSDetails } from "actions/mmsDetails";
import { useDeepCompareEffect } from "react-use";
import { useTheme } from "@mui/material";

interface IProps {
  mmsRecordIds: string[];
  rowEndAction?: (id: string) => any;
}
export const MMSTableComponent = ({ mmsRecordIds, rowEndAction }: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);

  const mmsDetails = useSelector((s: IState) => s.mmsDetails);
  const targetType = useSelector((s: IState) => s.targetType);
  return (
    <table style={styles.subjectTable}>
      <tbody>
        {mmsRecordIds.map((id, i) => {
          const mms = mmsDetails[id];
          if (mms) {
            return targetType === RuleTargetType.Subject ? (
              <tr
                key={id}
                style={{ ...styles.subjectTableRow, background: i % 2 === 0 ? "white" : brandLightBlue.toString() }}
              >
                <MemoTableCellBlueText bold={true} alignment="left" value={mms.name} />
                <MemoTableCell alignment="left" width="80px" value={mms.parentCourse.code} />
                <MemoTableCell alignment="left" width="80px" value={mms.parentCourse.name} />
                <MemoTableCell alignment="left" width="80px" value={mms.type} />
                <MemoTableCell alignment="center" width="20px" value={mms.points} />
                <MemoTableCell alignment="center" width="220px" value={mms.code} />
                {rowEndAction !== undefined && rowEndAction(id)}
              </tr>
            ) : (
              <tr
                key={id}
                style={{ ...styles.subjectTableRow, background: i % 2 === 0 ? "white" : brandLightBlue.toString() }}
              >
                <MemoTableCellBlueText bold={true} alignment="left" value={mms.name} />
                <MemoTableCell alignment="left" width="80px" value={mms.type} />
                <MemoTableCell alignment="center" width="20px" value={mms.points} />
                <MemoTableCell alignment="center" width="220px" value={mms.code} />
                {rowEndAction !== undefined && rowEndAction(id)}
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export const MMSTable = (props: IProps) => {
  const { mmsRecordIds } = props;
  const dispatch = useDispatch();
  useDeepCompareEffect(() => {
    dispatch(getMMSDetails(mmsRecordIds));
  }, [mmsRecordIds, dispatch]);
  return <MMSTableComponent {...props} />;
};
