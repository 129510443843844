import React from "react";

import { IState, Stereotype } from "store/types";
import { assertThat } from "lib/assert";
import { IRulesProps } from "../common/types";
import { Draggable } from "react-beautiful-dnd";
import { RuleComponentViewer } from "./RuleComponentViewer";
import { DNDHandle } from "../common/dnd";
import { FullWidth } from "components/divs/fullWidthDiv";
import { useSelector } from "react-redux";
import { MovingWrapper } from "../common/movingWrapper";

export const RuleComponent = (props: IRulesProps) => {
  const { rule } = props;
  const newlyCreatedRules = useSelector((state: IState) => state.capsObject?.newlyCreatedRules);
  assertThat(rule.stereotype === Stereotype.RuleComponent, `Invalid group stereotype ${rule.stereotype}`);

  // The non-DnD rule moving
  const moveRuleState = useSelector((s: IState) => s.moveRuleState);
  const moveInProgress = !!moveRuleState;
  const isBeingMoved = moveRuleState && moveRuleState.ruleId === rule.id;

  return (
    <MovingWrapper beingMoved={!!isBeingMoved}>
      <Draggable draggableId={rule.id} index={props.index} isDragDisabled={!!newlyCreatedRules}>
        {(provided) => (
          <FullWidth
            ref={provided.innerRef}
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            tabIndex={-1}
          >
            <RuleComponentViewer
              {...props}
              dragHandle={moveInProgress ? null : <DNDHandle {...provided.dragHandleProps} />}
            />
          </FullWidth>
        )}
      </Draggable>
    </MovingWrapper>
  );
};
