import React from "react";
import styled from "styled-components";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DragWrapper = styled.div`
  padding: 0 0 0 3px;
  margin: 0;
  // height: 100%;
  display: flex;
  align-items: center;
`;

type Props = DraggableProvidedDragHandleProps | Record<string, unknown>;

export const DNDHandle = (props: Props) => (
  <DragWrapper className="reveal-on-hover" {...props}>
    <DragIndicatorIcon />
  </DragWrapper>
);

export const DroppableFullWidth: any = styled.div`
  width: 100%;
  margin: ${(props: any) => (props.isDraggingOver ? "0.1rem" : "inherit")};
  border: ${(props: any) => (props.isDraggingOver ? "dashed 2px rgba(153, 162, 170, 0.8)" : "none")};
`;
