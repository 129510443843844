import initialState from "store/initialState";
import { FLIP_PREVIEW_DRAWER, RESIZE_PREVIEW_DRAWER } from "actions/previewDrawerState";

const previewDrawerState = (state = initialState.previewDrawerState, action: any) => {
  switch (action.type) {
    case FLIP_PREVIEW_DRAWER:
      return { ...state, open: !state.open };
    case RESIZE_PREVIEW_DRAWER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default previewDrawerState;
