import * as React from "react";
import { Aligned } from "components/divs/alignedDiv";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { FormControl, Grid, IconButton, TextField, useTheme } from "@mui/material";
import { RuleDispatcher } from "containers/rules/RuleDispatcher";
import { Theme } from "@mui/material/styles";
import { brandBlue } from "theme/colors";
import { ActionMenu } from "containers/rules/menu";
import { HeaderActionContainer, headingForLevel, NextLevelContainer } from "containers/rules/common/ruleComponent";
import { IRulesProps } from "../common/types";
import { getLabel } from "lib/parameters";
import { PaddedLabel } from "../common/forms";
import { MoveOptions } from "../moveOptions";

const useStyles = (theme: Theme) => ({
  label: {
    color: brandBlue.toString(),
    fontWeight: "bold",
  },
  inputRoot: {
    "& input::disabled": {
      color: theme.palette.text.primary,
      borderColor: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.primary,
    },
  },
});

export const GenericViewer = (props: IRulesProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { rule, level, parentTemplate } = props;
  const H = headingForLevel(props.level);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = React.useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);

  const label = getLabel(rule)?.trim();
  const headerText = label ? `${label} (${rule.type})` : rule.type;

  return (
    <>
      <HeaderActionContainer>
        <Aligned>
          {props.dragHandle}
          <IconButton
            color="primary"
            onClick={handleExpansion}
            aria-label={expanded ? "expand less button" : "expand more button"}
            size="large"
          >
            {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
          <H style={styles.label}>{headerText}</H>
        </Aligned>
        <ActionMenu rule={rule} parentTemplate={parentTemplate} />
        <MoveOptions rule={rule} />
      </HeaderActionContainer>
      <NextLevelContainer>
        <Collapse in={expanded} timeout={0} mountOnEnter style={{ width: "-webkit-fill-available" }}>
          <Grid container spacing={2}>
            {rule.parameters.map((param) => {
              const id = `${rule.id}-${param.name}-${param.type}`.replace(/\s/g, "");
              return (
                <Grid key={id} item xs={12}>
                  <PaddedLabel htmlFor={id}>{param.label}</PaddedLabel>
                  <FormControl fullWidth={true} variant="outlined">
                    <TextField
                      disabled={true}
                      label=""
                      fullWidth={true}
                      variant="outlined"
                      inputProps={{
                        "aria-label": param.label,
                        id,
                      }}
                      value={String(param.value)}
                      InputProps={{
                        sx: styles.inputRoot,
                      }}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>

          {rule.children.map((c, i) => (
            <RuleDispatcher
              key={c.id}
              {...props}
              rule={c}
              level={level + 1}
              index={i}
              siblings={rule.children.length}
            />
          ))}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
