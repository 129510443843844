import { EXECUTE_SUBJECT_QUERY } from "actions/subjectQueryResult";
import { failed, fulfilled, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const subjectQueryResult = (state = initialState.subjectQueryResult, action: any) => {
  switch (action.type) {
    case fulfilled(EXECUTE_SUBJECT_QUERY):
      // If this result is earlier than the last one - ignore it
      if (state && action.payload.searchTimestamp < state.searchTimestamp) {
        return state;
      }
      return action.payload;
    case failed(EXECUTE_SUBJECT_QUERY):
    case pending(EXECUTE_SUBJECT_QUERY):
      return null;
    default:
      return state;
  }
};

export default subjectQueryResult;
