import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IQueryParams, IState } from "store/types";

import { Homepage } from "./Homepage";
import { Router } from "@reach/router";
import AppBar from "./app-bar/AppBar";
import AppFooter from "../components/app-footer/app-footer";
import { RulesRoot } from "./RulesRoot";
import Spinner from "components/spinner";
import { AppSubheading } from "./AppSubheading";
import { parse } from "query-string";
import { validateToken } from "../actions/token";
import { getTemplates } from "actions/templates";
import { SnackBar } from "components/snackbar";
import { PreviewDrawer } from "./preview-drawer/PreviewDrawer";
import { SessionWarningModal } from "./session-warning/SessionWarningModal";
import { Dependents } from "./Dependents";

export const App = () => {
  const searchParams: IQueryParams = parse(window.location.search);
  let sessionId: string | undefined = "";
  let retoken: string | undefined = "";

  if (searchParams) {
    sessionId = searchParams.sessionId;
    retoken = searchParams.retoken;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateToken(sessionId, retoken));
  }, [sessionId, retoken, dispatch]);

  const submittingChanges = useSelector((state: IState) => state.submittingChanges);
  const loading = useSelector((state: IState) => state.loading);
  const tokenValidated = useSelector((state: IState) => state.tokenValidated);
  const previewDrawerOpen = useSelector((state: IState) => state.previewDrawerState.open);
  const previewDrawerWidth = useSelector((state: IState) => state.previewDrawerState.width);
  const hasToken = tokenValidated && tokenValidated.token ? true : false;
  const dependents = useSelector((state: IState) => state.dependents);

  const bodyWidth = previewDrawerOpen ? `max(675px, 100% - ${previewDrawerWidth} + 100px)` : "100%";

  useEffect(() => {
    if (hasToken) {
      dispatch(getTemplates());
    }
  }, [hasToken, dispatch]);

  return (
    <>
      <div style={{ width: bodyWidth }}>
        <AppBar />
        <AppSubheading />

        {dependents && dependents.length > 0 && <Dependents />}
        {submittingChanges && (
          <div className={submittingChanges ? "changes-in-progess-overlay" : ""}>
            <div className="loader" />
          </div>
        )}
        <Spinner loading={loading} />
        {hasToken && (
          <Router primary={false}>
            <Homepage path="/"></Homepage>
            <RulesRoot path=":targetType"></RulesRoot>
          </Router>
        )}
        <SessionWarningModal />
        <AppFooter />
      </div>
      <PreviewDrawer />
      <SnackBar />
    </>
  );
};
