import { get } from "lib/fetch";
import { getParentCourseSysIdForComponentSearch } from "lib/search";
import store from "../store";
export const GET_MMS_DETAILS = "mmsDetails/GET_MMS_DETAILS";
export const GET_MMS_BY_CODE = "mmsDetails/GET_MMS_BY_CODE";

export const getMMSDetails = (mmsRecordIds: string[] = []) => {
  const {
    capsObject: { effectiveYear },
    mmsDetails,
  } = store.getState();
  const missingMMSRecordIds = mmsRecordIds.filter((rid) => !mmsDetails[rid]);
  const searchYear = parseInt(effectiveYear) || new Date().getFullYear();
  const inheritedQuery = window.location.search ? `${window.location.search}&` : "?";
  return {
    type: GET_MMS_DETAILS,
    payload:
      missingMMSRecordIds.length === 0
        ? Promise.resolve([])
        : get(
            `/v1/search/component${inheritedQuery}recordId=${JSON.stringify(
              missingMMSRecordIds,
            )}&searchYear=${searchYear}`,
          ),
  };
};

export const getMMSByNameOrCode = (value: string) => {
  const { targetType, capsObject } = store.getState();
  // Filter by mms' parent_course.sys_id field
  const parentCourseSysId = getParentCourseSysIdForComponentSearch(targetType, capsObject);

  const searchYear = parseInt(capsObject.effectiveYear) || new Date().getFullYear();
  const inheritedQuery = window.location.search ? `${window.location.search}&` : "?";
  // If value has underscore -> user is definitely typing a component code
  const isCode = value.match(/_/g);
  let filterQuery = "";
  filterQuery += isCode ? `code=["${value.toUpperCase()}"]` : `name=["${value}"]`;
  if (parentCourseSysId) {
    filterQuery += `&parentCourseSysId=["${parentCourseSysId}"]`;
  }
  return {
    type: GET_MMS_BY_CODE,
    payload: get(`/v1/search/component${inheritedQuery}${filterQuery}&searchYear=${searchYear}`),
  };
};

export const getMMSByCode = (code: string) => {
  const {
    capsObject: { effectiveYear },
  } = store.getState();
  const searchYear = parseInt(effectiveYear) || new Date().getFullYear();
  const inheritedQuery = window.location.search ? `${window.location.search}&` : "?";
  return {
    type: GET_MMS_BY_CODE,
    payload: get(`/v1/search/component${inheritedQuery}code=["${code}"]&searchYear=${searchYear}`),
  };
};
