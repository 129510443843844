import React /* useEffect */ from "react";
import { Aligned } from "components/divs/alignedDiv";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { ActionMenu } from "containers/rules/menu";
import { HeaderActionContainer, headingForLevel, NextLevelContainer } from "containers/rules/common/ruleComponent";
import { commonStyles } from "theme/styles";
import { getLabel, isDynamicSubjectSet, isStaticSubjectSet } from "lib/parameters";
import { IRuleComponent, IState } from "store/types";
import { StaticSubjectSet } from "../component/StaticSubjectSet";
import { DynamicSubjectSet } from "../component/DynamicSubjectSet";
import { useSelector } from "react-redux";
import { DSSEditor } from "./dynamic/DSSEditor";
import { SSSEditor } from "./static/SSSEditor";
import { TagsPreview } from "components/tags/tagsPreview";
import { MoveOptions } from "../moveOptions";
import { useTheme } from "@mui/material";

interface IProps {
  rule: IRuleComponent;
  level: number;
  index: number;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
  readOnly?: boolean;
}

export const SharedSubjectSet = (props: IProps) => {
  const theme = useTheme();
  const commonStyling = commonStyles(theme);
  const { rule, level, index, ruleBeingUsed, readOnly } = props;
  const label = getLabel(rule) || rule.type;
  const H = headingForLevel(level);

  // Handle the editing
  const editedRuleId = useSelector((s: IState) => s.editorState.editedRuleId);

  const editMode = rule.id === editedRuleId;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpansion = React.useCallback(() => {
    setExpanded((oldValue) => !oldValue);
  }, []);
  React.useEffect(() => {
    if (editMode) {
      setExpanded(true);
    }
  }, [editMode, setExpanded]);

  return (
    <>
      <HeaderActionContainer
        role="button"
        tabIndex={0}
        aria-expanded={expanded ? true : false}
        onClick={handleExpansion}
        style={{ cursor: "pointer" }}
      >
        <Aligned>
          {expanded ? (
            <ExpandLess sx={{ padding: "12px" }} fontSize="small" />
          ) : (
            <ExpandMore sx={{ padding: "12px" }} fontSize="small" />
          )}
          <H style={commonStyling.label}>{label}</H>
          <TagsPreview rule={rule} />
        </Aligned>
        {!readOnly && <ActionMenu rule={rule} ruleBeingUsed={ruleBeingUsed} />}
        <MoveOptions rule={rule} />
      </HeaderActionContainer>
      <NextLevelContainer>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: "-webkit-fill-available" }}>
          {editMode && isDynamicSubjectSet(rule) && <DSSEditor rule={rule} ruleBeingUsed={ruleBeingUsed} />}
          {editMode && isStaticSubjectSet(rule) && <SSSEditor rule={rule} ruleBeingUsed={ruleBeingUsed} />}
          {!editMode && isStaticSubjectSet(rule) && (
            <StaticSubjectSet rule={rule} level={level} index={index} siblings={1} />
          )}
          {!editMode && isDynamicSubjectSet(rule) && (
            <DynamicSubjectSet rule={rule} level={level} index={index} siblings={1} />
          )}
        </Collapse>
      </NextLevelContainer>
    </>
  );
};
