import { SET_TARGET_TYPE } from "actions/targetType";
import initialState from "store/initialState";

const targetType = (state = initialState.targetType, action: any) => {
  switch (action.type) {
    case SET_TARGET_TYPE:
      return action.payload.targetType;
    default:
      return state;
  }
};

export default targetType;
