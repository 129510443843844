import { combineReducers } from "redux";
import loading from "./loading";
import submittingChanges from "./submittingChanges";
import subjectDetails from "./subjectDetails";
import mmsDetails from "./mmsDetails";
import courses from "./courses";
import capsObject from "./capsObject";
import parentSharedComponents from "./parentSharedComponents";
import dependents from "./dependents";
import tokenValidated from "./tokenValidated";
import editorState from "./editorState";
import sysId from "./sysId";
import targetType from "./targetType";
import templates from "./templates";
import snackBarState from "./snackBarState";
import optimisticUpdate from "./optimisticUpdate";
import subjectQueryResult from "./subjectQueryResult";
import moveRuleState from "./moveRuleState";
import mmsQueryResult from "./mmsQueryResult";
import sessionError from "./sessionError";
import previewDrawerState from "./previewDrawerState";

export default () =>
  combineReducers({
    sessionError,
    submittingChanges,
    loading,
    capsObject,
    parentSharedComponents,
    dependents,
    moveRuleState,
    subjectDetails,
    mmsDetails,
    courses,
    tokenValidated,
    editorState,
    sysId,
    targetType,
    templates,
    snackBarState,
    optimisticUpdate,
    subjectQueryResult,
    mmsQueryResult,
    previewDrawerState,
  });
