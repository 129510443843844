import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "formik-mui";
import { FormControl, Grid, Button, IconButton, useTheme } from "@mui/material";
import { PaddedLabel, ScopeContainer } from "../../common/forms";
import { RuleFormActions } from "../../common/RuleFormActions";
import { getLabel, getStaticSetSubjects } from "lib/parameters";
import { getTagLabel } from "lib/tags";
import { validateValuePresence } from "lib/validation";
import { commonStyles } from "theme/styles";
import { IRuleComponent, IState, SubjectSetType, RuleComponentType, RuleTargetType, ICourse } from "store/types";
import AddIcon from "@mui/icons-material/Add";
import { getSubjectDetails } from "actions/subjectDetails";
import { SSSSearchModal } from "./SSSSearchModal";
import uniq from "lodash/uniq";
import { addSharedSubjectSet, updateCAPSObject } from "actions/capsObject";
import { BannerComponent } from "containers/rules/common/banner";
import { CommonTag } from "lib/tags";
import { AutocompleteMultiSelect } from "components/autocomplete/AutocompleteMultiSelect";
import { SharedSubjectSetInlineWarning } from "../SharedSubjectSetWarningModal";
import { EditorGrid } from "containers/rules/common/ruleComponent";
import { SubjectTable } from "containers/rules/common/subjectTable";
import { Delete } from "@mui/icons-material";
import { LabelWithHint } from "components/help-hint/HelpHint";

interface IFormState {
  label: string;
  subjectRecordIds: string[];
  // isCoreCompulsory?: boolean;
  tags: string[];
}

const validateFormState = (values: IFormState) => {
  const errors: any = {};
  validateValuePresence(errors, values, "label");
  validateValuePresence(errors, values, "subjectRecordIds");
  return errors;
};

interface IProps {
  rule?: IRuleComponent;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

export const SSSEditor = (props: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const dispatch = useDispatch();
  const [finderOpen, setFinderOpen] = useState(false);
  const flipFinder = useCallback(() => setFinderOpen((old) => !old), []);
  const { rule, ruleBeingUsed } = props;
  const subjectSetUsageChains = useSelector((s: IState) => s.editorState.subjectSetUsageChains);
  const allComponentReferences = useSelector((s: IState) => (s.capsObject as ICourse)?.componentReferences ?? []);
  const componentReferences = useMemo(
    () => allComponentReferences.filter((cr) => cr.subjectSetId === rule?.id),
    [allComponentReferences, rule?.id],
  );
  const showWarning = (!!subjectSetUsageChains && subjectSetUsageChains.length > 0) || componentReferences.length > 0;

  const targetType = useSelector((s: IState) => s.targetType);
  const allowCore = targetType && targetType !== RuleTargetType.Subject;
  const allowedTags = [allowCore && CommonTag.CORE].filter(Boolean);

  const initialSubjectRecordIds = (rule && getStaticSetSubjects(rule)) || [];
  const ruleTags = rule?.tags;
  const initialState: IFormState = {
    label: (rule && getLabel(rule)) || "",
    subjectRecordIds: initialSubjectRecordIds,
    // isCoreCompulsory: !!(rule && hastTag(CommonTag.CORE, rule)),
    tags: (ruleTags || []).map((t) => t.name),
  };

  useEffect(() => {
    dispatch(getSubjectDetails(initialSubjectRecordIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSubjectRecordIds.join(", "), dispatch]);

  const callOnSubmit = useCallback(
    (values: IFormState, _helpers: FormikHelpers<IFormState>) => {
      const tags = values.tags.map(
        (t) =>
          (ruleTags || []).find((rt) => rt.name === t) || {
            name: t,
            label: getTagLabel(t),
          },
      );

      if (!rule) {
        dispatch(
          addSharedSubjectSet(
            {
              label: values.label,
              staticSubjectReferences: values.subjectRecordIds,
              ssType: SubjectSetType.Static,
            },
            tags,
          ),
        );
      } else {
        dispatch(
          updateCAPSObject({
            parameters: {
              label: values.label,
              staticSubjectReferences: values.subjectRecordIds,
              ssType: SubjectSetType.Static,
            },
            tags,
            ruleType: RuleComponentType.SubjectSet,
            ruleId: rule.id,
          }),
        );
      }
    },
    [dispatch, rule, ruleTags],
  );

  const tagOptions = uniq([...allowedTags, ...initialState.tags]);

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      {(props) => {
        const disableSubmit = !props.isValid || !props.dirty;
        const { subjectRecordIds } = props.values;
        const noSubjects = subjectRecordIds.length === 0;

        const onSubjectDelete = (id: string) => {
          const withoutSubject = subjectRecordIds.filter((s) => s !== id);
          props.setFieldValue("subjectRecordIds", withoutSubject);
        };

        const addSubject = (id: string) => {
          const withSubject = uniq([...subjectRecordIds, id]);
          props.setFieldValue("subjectRecordIds", withSubject);
        };

        const onTagsChange = (newTagNames: string[]) => {
          props.setFieldValue("tags", newTagNames);
        };

        return (
          <form onSubmit={props.handleSubmit}>
            <EditorGrid container spacing={2}>
              {showWarning && (
                <Grid item xs={12}>
                  <BannerComponent
                    heading={"Warning!"}
                    body={
                      <SharedSubjectSetInlineWarning
                        subjectSetUsageChains={subjectSetUsageChains ?? []}
                        componentReferences={componentReferences}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <PaddedLabel htmlFor="sset-label" sx={styles.label}>
                  <LabelWithHint
                    label="Subject List Label"
                    helpText="This label can be referenced in the Published Formats of rules. Make sure you consider how the list will be used when creating a label."
                  />
                </PaddedLabel>
                <FormControl fullWidth={true} variant="outlined">
                  <Field
                    component={TextField}
                    name="label"
                    label=""
                    fullWidth={true}
                    variant="outlined"
                    inputProps={{
                      "aria-label": "Subject Set Label",
                      id: "sset-label",
                    }}
                  />
                </FormControl>
              </Grid>

              {tagOptions.length > 0 && (
                <Grid item xs={12} md={6}>
                  <PaddedLabel htmlFor="group-tags-select" sx={styles.label}>
                    Subject Set Tags
                  </PaddedLabel>
                  <AutocompleteMultiSelect
                    id="sss-tags-select"
                    options={tagOptions}
                    formValue={props.values.tags}
                    formatLabel={getTagLabel}
                    onChange={onTagsChange}
                    placeholder="Subject Set Tags"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ScopeContainer>
                  <strong>Subject scope</strong>

                  <Button variant="text" color="primary" startIcon={<AddIcon />} onClick={flipFinder}>
                    Add subject
                  </Button>
                </ScopeContainer>
                {finderOpen && (
                  <SSSSearchModal
                    subjectRecordIds={subjectRecordIds}
                    close={flipFinder}
                    addSubject={addSubject}
                    deleteSubject={onSubjectDelete}
                  />
                )}
                {!noSubjects && (
                  <SubjectTable
                    subjectRecordIds={subjectRecordIds}
                    rowEndAction={(id: string) => (
                      <td align="center">
                        <IconButton size="small" color="primary" onClick={() => onSubjectDelete(id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </td>
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <RuleFormActions
                  ruleId={rule?.id}
                  submitDisabled={disableSubmit}
                  submitting={props.isSubmitting}
                  ruleBeingUsed={ruleBeingUsed}
                />
              </Grid>
            </EditorGrid>
          </form>
        );
      }}
    </Formik>
  );
};
