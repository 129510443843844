import React, { useCallback, useEffect, useState } from "react";

import { IRuleEditorProps } from "../common/types";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { Button, FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, ScopeContainer, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { RuleComponentType, Stereotype } from "store/types";
import MMSSearch from "./MMSSearch";
import { commonStyles } from "theme/styles";
import { featureToggles } from "config/featureToggles";
import { MMSSearchModal } from "./mms/MMSSearchModal";
import { uniq } from "lodash";
import CourseSearch from "./CourseSearch";
import { IAdmissionEditorFormState } from "./AdmissionEditor";
import { AdmissionWarning } from "./Admission";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { EditorForm } from "../common/ruleComponent";
import { getRuleUILabels } from "lib/rules";
import { RulePreview } from "../common/rulePreview";
import { useFormatsSamplesAndTypes } from "../editor/editorHooks";
import { useDeepCompareMemo } from "use-deep-compare";

type Props = IRuleEditorProps & { ruleType: RuleComponentType };

export const AdmissionFields = (props: Props) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule, changeRuleType, ruleType } = props;

  const [finderOpen, setFinderOpen] = useState(false);
  const flipFinder = useCallback(() => setFinderOpen((old) => !old), []);
  const formik = useFormikContext<IAdmissionEditorFormState>();
  const { setFieldValue } = formik;

  // Notify the dispatcher that we need to render a new rule type form
  useEffect(() => {
    if (formik.values.type !== ruleType) {
      changeRuleType(formik.values.type);
    }
  }, [changeRuleType, formik.values.type, ruleType]);

  const { allowedFormats, samples, allRuleTypes } = useFormatsSamplesAndTypes(
    props,
    formik.values.selectedOrDefaultFormat,
  );

  const addMMS = (id: string) => {
    setFieldValue("mmsReferences", uniq([...formik.values.mmsReferences, id]));
  };
  const deleteMMS = (id: string) => {
    const without = formik.values.mmsReferences.filter((s) => s !== id);
    setFieldValue("mmsReferences", without);
  };

  const previewReq = useDeepCompareMemo(() => {
    const embeddedChildRuleProps = {
      ruleType: RuleComponentType.CourseMMSSet,
      parameters: {
        mmsReferences: formik.values.mmsReferences,
        courseReferences: formik.values.courseReferences,
      },
    };
    return {
      ruleProps: {
        stereotype: Stereotype.RuleComponent,
        ruleType: RuleComponentType.Admission,
        parameters: formik.values,
      },
      embeddedChildRuleProps,
      isValid: formik.isValid,
    };
  }, [formik.values, formik.isValid]);

  return (
    <EditorForm onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-type-select" sx={styles.label}>
            Rule type
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="type"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps as any}
              inputProps={{ id: "rule-type-select" }}
              disabled={!!rule}
            >
              {allRuleTypes.map((t: string) => {
                const uiLabels = getRuleUILabels(t);
                return (
                  <MenuItem key={t} value={t}>
                    <LabelWithHint
                      label={
                        <div>
                          {uiLabels?.title}
                          {uiLabels.subTitle && <i>&nbsp;({uiLabels.subTitle})</i>}
                        </div>
                      }
                      helpText={uiLabels.helpText}
                    />
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <PaddedLabel htmlFor="label-text" sx={styles.label}>
            <LabelWithHint
              label="Label"
              helpText="This field is to assist you administratively in finding and editing the rules later."
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="label"
              label=""
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Rule Label",
                id: "label-text",
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <AdmissionWarning />
          {featureToggles.search ? (
            <>
              <ScopeContainer>
                <PaddedLabel htmlFor="mmsReferences" sx={styles.label}>
                  <LabelWithHint
                    label="Component Scope"
                    helpText="Add components that apply to this rule by using the search button. When selecting a component, you do not need to select the parent course also."
                  />
                </PaddedLabel>
                <Button variant="text" color="primary" onClick={flipFinder}>
                  Advanced search
                </Button>
              </ScopeContainer>
              {finderOpen && (
                <MMSSearchModal
                  mmsRecordIds={formik.values.mmsReferences}
                  close={flipFinder}
                  addMMS={addMMS}
                  deleteMMS={deleteMMS}
                />
              )}
            </>
          ) : (
            <PaddedLabel htmlFor="mmsReferences" sx={styles.label}>
              <LabelWithHint
                label="Component Scope"
                helpText="Add any courses that apply to this rule by typing their course code into the field."
              />
            </PaddedLabel>
          )}

          <FormControl fullWidth={true} variant="outlined">
            <MMSSearch
              mmsRecordIds={formik.values.mmsReferences}
              onMMSRecordIdsChanged={(v) => {
                setFieldValue("mmsReferences", v);
              }}
              errorMesage={formik.errors["mmsReferences"] as string}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="mmsReferences" sx={styles.label}>
            Course Scope
          </PaddedLabel>

          <FormControl fullWidth={true} variant="outlined">
            <CourseSearch
              courseRecordIds={formik.values.courseReferences}
              onCourseRecordIdsChanged={(v) => {
                setFieldValue("courseReferences", v);
              }}
              errorMesage={formik.errors["courseReferences"] as string}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="rule-presentation-format-select" sx={styles.label}>
            <LabelWithHint
              label="Published format"
              helpText="Choose the way this rule is presented in CAPS, the Handbook and My Course Planner, by selecting what information is displayed and how. This format will also interact with the parent (Rule Group) format."
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Published format",
                id: "rule-presentation-format-select",
              }}
            >
              {allowedFormats.map((f) => (
                <MenuItem style={{ whiteSpace: "normal" }} key={f.name} value={f.name}>
                  {f.name === "One of Course or MMS (Mixed)"
                    ? "One of Courses or MMSs (Mixed)"
                    : f.name === "One of Course or MMS (dot points)"
                    ? "One of Courses or MMSs (dot points)"
                    : f.name === "Selection of one of multiple MMS"
                    ? "Selection of one of multiple MMSs"
                    : f.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {(samples.length > 0 || !!featureToggles.previewRuleBeforeSave) && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={previewReq}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleComponent}
              ruleType={ruleType}
              ruleId={rule?.id}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <RuleFormActions
            ruleId={rule?.id}
            submitDisabled={!formik.isValid || !formik.dirty}
            submitting={formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </EditorForm>
  );
};
