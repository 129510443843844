import React from "react";
import { Checkbox, FormControl, MenuItem, ListItemText, InputAdornment, IconButton, Grid } from "@mui/material";
import { Field } from "formik";
import { SelectMenuProps } from "../common/forms";
import { Select } from "formik-mui";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

export const ButtonRow = styled(Grid)`
  display: flex;
  flex-direction: row-reverse;
`;

export const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

interface IMultiSelectFormProps {
  id: string;
  name: string;
  formValue: string[];
  allValues: string[];
  placeholder: string;
  renderValue: (nSelected: number) => string;
  formatLabel: (l: string) => string;
  onClear: () => any;
}
export const MultiSelectField = (props: IMultiSelectFormProps) => (
  <FormControl fullWidth={true}>
    <Field
      component={Select}
      name={props.name}
      fullWidth={true}
      variant="outlined"
      multiple
      MenuProps={SelectMenuProps}
      displayEmpty={true}
      renderValue={(selected: any) =>
        !selected || selected.length === 0 ? (
          <em>{props.placeholder}</em>
        ) : selected.length === 1 ? (
          props.formatLabel(selected[0])
        ) : (
          props.renderValue(selected.length)
        )
      }
      inputProps={{ id: `${props.name}-select` }}
      endAdornment={
        props.formValue.length === 0 ? undefined : (
          <InputAdornment position="end">
            <IconButton size="small" style={{ marginRight: "15px" }} onClick={props.onClear} aria-label="Close button">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    >
      {props.allValues.map((l) => (
        <MenuItem key={l} value={l}>
          <Checkbox checked={props.formValue.indexOf(l) > -1} />
          <ListItemText primary={props.formatLabel(l)} />
        </MenuItem>
      ))}
    </Field>
  </FormControl>
);
