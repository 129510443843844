import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "store/types";

import { BannerComponent } from "./rules/common/banner";
import { ResponsiveCenter } from "components/spinner/Center";
import { Tooltip } from "@mui/material";
import { Aligned } from "components/divs/alignedDiv";
import { featureToggles } from "config/featureToggles";
import { IconWrapper } from "components/icons/breadthDisciplineScope";
import LinkIcon from "@mui/icons-material/Link";
import { orderBy } from "lodash";

export const Dependents = () => {
  const dependents = useSelector((state: IState) => state.dependents);
  const orderedDependents = useMemo(
    () => orderBy(dependents, [(d) => d.ruleType, (d) => d.isCoreCompulsory, (d) => d.title], ["desc", "desc", "asc"]),
    [dependents],
  );

  if (!dependents || dependents.length === 0 || !featureToggles.showDependentObjects) {
    return null;
  }

  const body = (
    <>
      <ul>
        {orderedDependents.map((d, i) => (
          <li key={`${d.sys_id}-${d.ruleId}-${i}`}>
            <Aligned>
              {d.ruleType} {d.title} ({d.code}, v{d.version}) &nbsp;
              {d.isCoreCompulsory && (
                <Tooltip title="Compulsory / Core dependent" placement="top" arrow>
                  <IconWrapper>C</IconWrapper>
                </Tooltip>
              )}
            </Aligned>
          </li>
        ))}
      </ul>
      <p>
        <strong>Note:</strong> This list is based on structured data (converted for My Course Planner) from the latest
        Published Handbook year. Updates to course rules made in future years will not be represented, nor will any
        unconverted courses. Please ensure you consider all courses when building your subject&rsquo;s requisites. This
        list is updated every evening and is based on structured course rules only.
      </p>
    </>
  );

  return (
    <ResponsiveCenter>
      <BannerComponent icon={<LinkIcon />} heading="Linked courses & components" body={body} collapsible />
    </ResponsiveCenter>
  );
};
