import * as React from "react";
import { IRuleComponent } from "store/types";
import { CommonTag, hasTag } from "lib/tags";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import { Tooltip } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { brandBlue } from "theme/colors";
import { IconWrapper } from "components/icons/breadthDisciplineScope";
import GridOffOutlinedIcon from "@mui/icons-material//GridOffOutlined";

interface IProps {
  rule?: IRuleComponent;
}

export const TagsPreview = ({ rule }: IProps) => {
  if (!rule) {
    return null;
  }

  // For groups we'll need an operator

  return (
    <>
      &nbsp; &nbsp;
      {hasTag(CommonTag.HANDBOOK_ONLY, rule) && (
        <>
          <Tooltip title="Handbook only. Rule will not appear in CPT." placement="top" arrow>
            <LocalLibraryOutlinedIcon fontSize="small" style={{ fill: brandBlue.toString() }} />
          </Tooltip>
          &nbsp;
        </>
      )}
      {hasTag(CommonTag.CPT_ONLY, rule) && (
        <>
          <Tooltip title="My Course Planner only. Rule will not appear in handook." placement="top" arrow>
            <DesktopMacOutlinedIcon fontSize="small" style={{ fill: brandBlue.toString() }} />
          </Tooltip>
          &nbsp;
        </>
      )}
      {hasTag(CommonTag.EXCLUSIVE_GROUP, rule) && (
        <>
          <Tooltip title="Exclusive group - rules within the group can not share subjects." placement="top" arrow>
            <NewReleasesIcon fontSize="small" style={{ fill: brandBlue.toString() }} />
          </Tooltip>
          &nbsp;
        </>
      )}
      {hasTag(CommonTag.HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK, rule) && (
        <>
          <Tooltip title="Hide the automatic Total Course Points rule from the handbook." placement="top" arrow>
            <GridOffOutlinedIcon fontSize="small" style={{ fill: brandBlue.toString() }} />
          </Tooltip>
          &nbsp;
        </>
      )}
      {hasTag(CommonTag.CORE, rule) && (
        <>
          <Tooltip
            title="Compulsory / Core subject set. Subjects from this set will be coloured in CPT."
            placement="top"
            arrow
          >
            <IconWrapper>C</IconWrapper>
          </Tooltip>
          &nbsp;
        </>
      )}
    </>
  );
};
