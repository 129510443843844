import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCAPSObject, moveRule } from "actions/capsObject";

import { IQueryParams, IState, RuleTargetType } from "store/types";
import { RuleDispatcher } from "./rules/RuleDispatcher";
import { ResponsiveCenter } from "components/spinner/Center";
import { parse } from "query-string";
import { RoutedProps } from "store/routerTypes";
import { saveSysId } from "actions/sysId";
import { saveTargetType } from "actions/targetType";
import { DragDropContext, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { DroppableFullWidth } from "./rules/common/dnd";
import { moveRuleOptimistically } from "actions/optimisticUpdate";
import { SharedSubjectSets } from "./rules/sharedRules/SharedSubjectSets";
import { RuleSubjectActionBar } from "./rules/RuleSubjectActionBar";
import { MoveNotificaiton } from "./MoveNotification";
import { ParentSharedSubjectSets } from "./rules/sharedRules/ParentSharedSubjectSets";

type Props = RoutedProps & { targetType?: RuleTargetType };

export const RulesRoot = (props: Props) => {
  const capsObject = useSelector((state: IState) => state.capsObject);
  const optimisticUpdate = useSelector((state: IState) => state.optimisticUpdate);
  const loading = useSelector((state: IState) => state.loading);
  const capsObjectRuleId = capsObject?.rules?.id;
  const sysId = useSelector((state: IState) => state.sysId);
  const targetType = useSelector((state: IState) => state.targetType);

  const { sys_id: sysIdQueryParam, sessionId, retoken }: IQueryParams = parse(props.location?.search || "");
  const newlyCreatedRules = capsObject && capsObject.newlyCreatedRules;

  const validPath = Object.values(RuleTargetType).indexOf(props.targetType!) >= 0;

  // Get the course on load
  const dispatch = useDispatch();
  useEffect(() => {
    if (sysIdQueryParam && validPath && props.targetType) {
      dispatch(saveSysId(sysIdQueryParam));
      dispatch(saveTargetType(props.targetType));
    }
  }, [sysIdQueryParam, props.targetType, validPath, dispatch]);
  useEffect(() => {
    if (sysId && validPath && targetType) {
      dispatch(getCAPSObject());
    }
  }, [sysId, sessionId, retoken, validPath, targetType, dispatch]);

  const onDragEnd = useCallback(
    (result: DropResult, _provided: ResponderProvided) => {
      console.log(
        `Dropping: ${result.draggableId} at ${result.destination?.index} in ${result.destination?.droppableId}`,
      );
      if (result.destination && capsObjectRuleId) {
        const move = {
          ruleId: capsObjectRuleId,
          movedRuleId: result.draggableId,
          movedToRuleId: result.destination.droppableId,
          position: result.destination.index,
        };
        dispatch(moveRuleOptimistically(move));
        dispatch(moveRule(move));
      }
    },
    [capsObjectRuleId, dispatch],
  );

  if (!sysId || !capsObject || !validPath) {
    return null;
  }
  const rule = optimisticUpdate || capsObject.rules;
  return (
    <>
      <ResponsiveCenter>
        {newlyCreatedRules && !loading && <RuleSubjectActionBar />}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="root" type="selection" isDropDisabled={!!newlyCreatedRules}>
            {(provided, snapshot) => (
              <DroppableFullWidth
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.draggingOverWith}
                tabIndex={-1}
              >
                <RuleDispatcher rule={rule} level={0} index={0} siblings={1} />
                {provided.placeholder}
                <SharedSubjectSets rule={rule} />
                <ParentSharedSubjectSets />
              </DroppableFullWidth>
            )}
          </Droppable>
        </DragDropContext>
      </ResponsiveCenter>
      <MoveNotificaiton />
    </>
  );
};
