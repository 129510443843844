export const FLIP_PREVIEW_DRAWER = "previewDrawerState/FLIP";
export const RESIZE_PREVIEW_DRAWER = "previewDrawerState/RESIZE";

export const flipPreviewDrawer = () => ({
  type: FLIP_PREVIEW_DRAWER,
  payload: {},
});

export const resizeDrawer = (width: string) => ({
  type: RESIZE_PREVIEW_DRAWER,
  payload: { width },
});
