import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";

export const useSize = (target: any) => {
  const [size, setSize] = React.useState<DOMRectReadOnly | null>(null);

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  // Where the magic happens - https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer#readme
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
