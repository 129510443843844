import { get } from "lib/fetch";
import store from "../store";
export const SEARCH_COURSE_BY_NAME = "courses/SEARCH_COURSE_BY_NAME";
export const GET_COURSES_AND_MMSES = "courses/GET_COURSES_AND_MMSES";

export const getCoursesAndMMS = (courseRecordIds: string[] = [], mmsRecordIds: string[] = []) => {
  const {
    capsObject: { effectiveYear },
    courses,
    mmsDetails,
  } = store.getState();
  const searchYear = parseInt(effectiveYear) || new Date().getFullYear();
  const inheritedQuery = window.location.search ? `${window.location.search}&` : "?";

  const missingCourseRecordIds = courseRecordIds.filter((rid) => !courses[rid]);
  const missingMMsRecordIds = mmsRecordIds.filter((rid) => !mmsDetails[rid]);

  const payload = Promise.all([
    missingCourseRecordIds.length > 0
      ? get(
          `/v1/search/course${inheritedQuery}recordId=${JSON.stringify(
            missingCourseRecordIds,
          )}&searchYear=${searchYear}`,
        )
      : Promise.resolve([]),
    missingMMsRecordIds.length > 0
      ? get(
          `/v1/search/component${inheritedQuery}recordId=${JSON.stringify(
            missingMMsRecordIds,
          )}&searchYear=${searchYear}`,
        )
      : Promise.resolve([]),
  ]).then(([courses, mms]: any) => ({ courses, mms }));

  return {
    type: GET_COURSES_AND_MMSES,
    payload,
  };
};

export const searchCourseByName = (nameOrCode: string) => {
  const { capsObject } = store.getState();

  const searchYear = parseInt(capsObject.effectiveYear) || new Date().getFullYear();
  const inheritedQuery = window.location.search ? `${window.location.search}&` : "?";

  return {
    type: SEARCH_COURSE_BY_NAME,
    payload: get(`/v1/search/course${inheritedQuery}nameOrCode=${nameOrCode}&searchYear=${searchYear}`),
  };
};
