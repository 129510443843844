import React from "react";

import { IState, RuleComponentType, Stereotype } from "store/types";
import { assertThat } from "lib/assert";
import { NumConstraint } from "./NumConstraint";
import { StaticSubjectSet } from "./StaticSubjectSet";
import { Information } from "./Information";
import { EditorDispatcher } from "./EditorDispatcher";
import { useSelector } from "react-redux";
import { IRulesProps } from "../common/types";
import { GenericViewer } from "./GenericViewer";
import { isStaticSubjectSet } from "lib/parameters";
import { DynamicSubjectSet } from "./DynamicSubjectSet";
import { StaticMMSSet } from "./StaticMMSSet";
import { Admission } from "./Admission";
import { MMSNumConstraint } from "./MMSNumConstraint";
import { Progression } from "./Progression";

export const RuleComponentViewer = (props: IRulesProps) => {
  const { rule } = props;
  assertThat(rule.stereotype === Stereotype.RuleComponent, `Invalid group stereotype ${rule.stereotype}`);

  const editedRuleId = useSelector((s: IState) => s.editorState.editedRuleId);
  const editMode = rule.id === editedRuleId;

  if (editMode) {
    return <EditorDispatcher {...props} />;
  }

  const type = rule.type.trim();
  switch (type) {
    case RuleComponentType.PointsConstraint:
    case RuleComponentType.CountConstraint:
      return <NumConstraint {...props} />;
    case RuleComponentType.CountConstraintMMS:
      return <MMSNumConstraint {...props} />;
    case RuleComponentType.SubjectSet:
      return isStaticSubjectSet(props.rule) ? <StaticSubjectSet {...props} /> : <DynamicSubjectSet {...props} />;
    case RuleComponentType.MMSSet:
      return <StaticMMSSet {...props} />;
    case RuleComponentType.Information:
      return <Information {...props} />;
    case RuleComponentType.Admission:
      return <Admission {...props} />;
    case RuleComponentType.Progression:
      return <Progression {...props} />;
    default:
      return <GenericViewer {...props} />;
  }
};
