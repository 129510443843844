export const assertThat = (val: any | undefined | null, msg: string) => {
  if (!val) {
    throw new Error(`Failed assertion: ${msg}`);
  }
};

export const assertAll = <T>(arr: T[], predicate: (v: T) => boolean, msg: string) => {
  for (const val of arr) {
    if (!predicate(val)) {
      throw new Error(`Failed assertion: ${msg}`);
    }
  }
};

export const assertNot = (val: any | undefined | null, msg: string) => assertThat(!val, msg);
