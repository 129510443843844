import {
  BreadthDisciplineScope,
  IParameter,
  IRuleComponent,
  Operator,
  ParameterName,
  ParamType,
  RuleComponentType,
  RuleTargetType,
  SubjectSetType,
} from "store/types";
import { assertThat } from "./assert";

type Value = string | number | null | undefined | string[] | boolean;

const getValue = <T = string>(paramName: string) => (rule: IRuleComponent) => {
  return rule?.parameters?.find((p) => p.name === paramName)?.value as T | undefined;
};
const setValue = <T = string>(paramName: string) => (rule: IRuleComponent, val: T) => {
  const param = rule?.parameters?.find((p) => p.name === paramName);
  param!.value = val as any;
};

export const isStaticSubjectSet = (rule: IRuleComponent) => getValue("ssType")(rule) === SubjectSetType.Static;
export const isDynamicSubjectSet = (rule: IRuleComponent) => getValue("ssType")(rule) === SubjectSetType.Dynamic;
export const isBreadthDynamicSubjectSet = (rule: IRuleComponent) => {
  if (!isDynamicSubjectSet(rule)) {
    return false;
  }

  const query = getValue<any[][]>("dynamicQueryAttributes")(rule) ?? [];
  const i = query.findIndex((attr) => attr.find((e) => e.name === "name" && e.value === "breadthCourseCode"));
  if (i < 0) {
    return false;
  }
  const courses = query[i].find((e) => e.name === "values")?.value ?? [];
  return courses.length > 0;
};

export const getStaticSubjects = (rule?: IRuleComponent) =>
  ((rule?.parameters || []).find((p) => p.name === "staticSubjectReferences")?.value as string[]) || [];

export const getMMSReferences = (rule: IRuleComponent) =>
  (rule.parameters.find((p) => p.name === "mmsReferences")?.value as string[]) || [];

export const getCourseReferences = (rule: IRuleComponent) =>
  (rule.parameters.find((p) => p.name === "courseReferences")?.value as string[]) || [];

export const toNumber = (val: Value) => {
  if (String(val) === "0") {
    return 0;
  }
  return parseFloat(String(val) || "");
};
export const getNumValue = (paramName: string) => (rule: IRuleComponent) => toNumber(getValue(paramName)(rule));

export const toBoolean = (val: Value) => {
  return !!["t", "true"].find((e) => e === String(val).toLowerCase().trim());
};
export const getBooleanValue = (paramName: string) => (rule: IRuleComponent) => toBoolean(getValue(paramName)(rule));

export const isBoolean = (param: IParameter) => {
  const enumVals = param.enumValues || [];
  return (
    enumVals.length === 2 &&
    enumVals.find((e) => e.toLowerCase() === "true") &&
    enumVals.find((e) => e.toLowerCase() === "false")
  );
};

export const formValue = (param: IParameter): Value => {
  switch (param.type) {
    case ParamType.string:
    case ParamType.array:
      return param.value;
    case ParamType.number:
      return toNumber(param.value);
    case ParamType.enum:
      return isBoolean(param) ? toBoolean(param.value) : param.value;
    case ParamType.composite:
    default:
      throw new Error(`Unsupported param type ${param.type}`);
  }
};

export const paramValue = (v: Value, param: IParameter) => {
  switch (param.type) {
    case ParamType.string:
    case ParamType.array:
      return v;
    case ParamType.number:
      return toNumber(v);
    case ParamType.enum:
      return isBoolean(param) ? (v ? "True" : "False") : v;
    case ParamType.composite:
    default:
      throw new Error(`Unsupported param type ${param.type}`);
  }
};

interface IObjectParam {
  [key: string]: Value;
}
export const paramsToFormObject = (params: IParameter[]) => {
  const result: IObjectParam = {};
  params.forEach((p) => {
    result[p.name] = formValue(p);
  });
  return result;
};
export const formObjectToParams = (vals: IObjectParam, params: IParameter[]) => {
  const result: IObjectParam = {};
  params.forEach((p) => {
    result[p.name] = paramValue(vals[p.name], p);
  });
  return result;
};

export const dynamicSetQueryAsFlatObj = (rule?: IRuleComponent) => {
  const result: any = {};
  if (rule) {
    const paramQuery = getDynamicSetQuery(rule);
    (paramQuery || []).forEach((fieldArray) => {
      const name = fieldArray.find((p) => p.name === "name").value;
      const value = fieldArray.find((p) => p.name === "values").value;
      result[name] = value;
    });
  }
  return result;
};

export const getMin = getNumValue(ParameterName.Min);
export const getMax = getNumValue(ParameterName.Max);

export const getMinMax = (rule: IRuleComponent) => [getMin(rule), getMax(rule)];

export const numericLabelSuffix = (rule: IRuleComponent, suffix = "pts") => {
  const [min, max] = getMinMax(rule);
  assertThat(!isNaN(min) || !isNaN(max), "You need min or max for numeric constraint");
  let ptsSuffix = `${min} - ${max} ${suffix}`;
  if (isNaN(min) || min === max) {
    ptsSuffix = `${max} ${suffix}`;
  }
  if ((isNaN(min) || min === 0) && max) {
    ptsSuffix = `Max. ${max} ${suffix}`;
  }
  if (isNaN(max) || max < min) {
    ptsSuffix = `Min. ${min} ${suffix}`;
  }
  return ptsSuffix;
};

export const getTemplateParamsByName = (componentType: RuleComponentType, template?: IRuleComponent) => {
  return template?.children?.find((rc: IRuleComponent) => rc.type === componentType)?.parameters;
};

export const getOperator = getValue<Operator>(ParameterName.Operator);
export const getLabel = getValue(ParameterName.Label);
export const getConcurrency = getValue(ParameterName.Concurrency);
export const getText = getValue(ParameterName.Text);
export const getLinkText = getValue(ParameterName.LinkText);
export const getDynamicSetQuery = getValue<any[][]>(ParameterName.DynamicSetQuery);
export const getStaticSetSubjects = getValue<string[]>(ParameterName.StaticSetSubjects);
export const getSubjectOmissions = getValue<string[]>(ParameterName.SubjectOmissions);
export const getBreadthDisciplineScope = (
  rule: IRuleComponent | null | undefined,
  targetType: RuleTargetType | null,
  isSecondaryCourse?: boolean | false,
) => {
  let res = rule && getValue<BreadthDisciplineScope>(ParameterName.BreadthDisciplineScope)(rule);
  // Default values ...
  if (!res) {
    if (!!isSecondaryCourse) {
      res = BreadthDisciplineScope.Breadth_and_Discipline;
    } else {
      switch (targetType ?? RuleTargetType.Course) {
        case RuleTargetType.Course:
        case RuleTargetType.Component:
          res = BreadthDisciplineScope.Discipline;
          break;
        case RuleTargetType.Subject:
          res = BreadthDisciplineScope.Breadth_and_Discipline;
          break;
        default:
          throw new Error(`Unknown target type ${targetType}`);
      }
    }
  }
  return res;
};

export const setLabel = setValue(ParameterName.Label);
