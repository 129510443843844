import React from "react";
import Grid from "@mui/material/Grid";

export const ResponsiveCenter = ({ children }: { children: any }) => (
  <Grid container spacing={0} alignItems="center" justifyContent="center">
    <Grid item xs={12} sm={11} md={10} lg={9} xl={7}>
      <div>{children}</div>
    </Grid>
  </Grid>
);

export const ResponsiveBottomAlignedCenter = ({ children }: { children: any }) => (
  <Grid container spacing={0} alignItems="flex-end" justifyContent="center">
    <Grid item xs={12} sm={11} md={10} lg={9} xl={7}>
      <div>{children}</div>
    </Grid>
  </Grid>
);
