import {
  IRuleComponent,
  IRulesMove,
  RuleComponentType,
  RuleComponentTypeForRuleEdit,
  RuleTargetType,
  Stereotype,
  SubjectRuleSectionType,
} from "store/types";
import { getLabel } from "./parameters";
import { IUsageChain } from "containers/rules/sharedRules/types";
import findLast from "lodash/findLast";
import includes from "lodash/includes";
import without from "lodash/without";
import flatten from "lodash/flatten";
import { featureToggles } from "config/featureToggles";

const ruleUILabels: Record<string, { title: string; subTitle?: string; helpText?: string }> = {
  "Count Constraint": {
    title: "Number of subjects",
    subTitle: "Count Constraint",
    helpText:
      "Define the number of subjects required out of a subject, group of subjects or subject list - SUBJECT REQUISITES DEFAULT",
  },
  "Points Constraint": {
    title: "Credit points of subjects",
    subTitle: "Points Constraint",
    helpText: "Define the amount of credit points required out of a subject, group of subjects or subject list",
  },
  Admission: {
    title: "Admission",
    helpText: "Define a requirement to be admitted to a degree or component, or one of many degrees or components",
  },
  Information: {
    title: "Information",
    helpText:
      "Define a rule that does not use subjects (e.g. audition, permission of coordinator), or provide supporting text",
  },
  "Count Constraint MMS": {
    title: "Number of components",
    subTitle: "Count Constraint MMS",
    helpText: "Define the number of components required out of a single component or group of components",
  },
  Progression: {
    title: "Progression Rule",
    subTitle: "Undergraduate",
    helpText: "Define the requirements for progressing from one year level to the next",
  },
};

export const getRuleUILabels = (ruleType: string) => ({
  title: ruleUILabels[ruleType]?.title ?? ruleType,
  subTitle: ruleUILabels[ruleType]?.subTitle,
  helpText: ruleUILabels[ruleType]?.helpText,
});

const allEditTypes = Object.values(RuleComponentTypeForRuleEdit);
export const validEditTypesPerTarget: Record<RuleTargetType, RuleComponentTypeForRuleEdit[]> = {
  [RuleTargetType.Course]: featureToggles.progression
    ? without(allEditTypes, RuleComponentTypeForRuleEdit.Admission)
    : without(allEditTypes, RuleComponentTypeForRuleEdit.Admission, RuleComponentTypeForRuleEdit.Progression),
  [RuleTargetType.Subject]: featureToggles.admission
    ? without(allEditTypes, RuleComponentTypeForRuleEdit.Progression)
    : without(allEditTypes, RuleComponentTypeForRuleEdit.Admission, RuleComponentTypeForRuleEdit.Progression),
  [RuleTargetType.Component]: without(
    allEditTypes,
    RuleComponentTypeForRuleEdit.Admission,
    RuleComponentTypeForRuleEdit.Progression,
  ),
};

export const getRuleComponentTypes = (
  templates: IRuleComponent[],
  target: RuleTargetType | null,
  parentSectionType?: SubjectRuleSectionType.Prerequisites | null,
) => {
  const validTypes = target ? validEditTypesPerTarget[target] : [];
  const ruleComponents: IRuleComponent[] = flatten(
    templates.map((rt: IRuleComponent) => rt.children),
  ).filter((rc: IRuleComponent) => validTypes.includes(rc.type as RuleComponentTypeForRuleEdit));

  let result = ruleComponents.map((rc: IRuleComponent) => rc.type);
  if (parentSectionType !== SubjectRuleSectionType.Prerequisites) {
    result = without(result, RuleComponentType.Admission);
  }
  return result;
};

export const getByRuleId = (rules: IRuleComponent | null | undefined, id?: string | null): IRuleComponent | null => {
  if (!rules || !id || rules.id === id) {
    return rules || null;
  }

  for (const child of rules.children) {
    const r = getByRuleId(child, id);
    if (r) {
      return r;
    }
  }

  for (const sharedRule of rules.sharedComponents || []) {
    const r = getByRuleId(sharedRule, id);
    if (r) {
      return r;
    }
  }

  return null;
};

export const getParentChain = (
  rules: IRuleComponent | null | undefined,
  id: string,
  chain: IRuleComponent[] = [],
): IRuleComponent[] | null => {
  if (!rules || rules.id === id) {
    return chain;
  }

  const newChain = [...chain, rules];

  for (const child of rules.children) {
    const childChain = getParentChain(child, id, newChain);
    if (childChain) {
      return childChain;
    }
  }

  for (const sharedRule of rules.sharedComponents || []) {
    const childChain = getParentChain(sharedRule, id, newChain);
    if (childChain) {
      return childChain;
    }
  }

  return null;
};

export const getParentTemplateId = (rules: IRuleComponent | null | undefined, id: string): string | undefined => {
  const parentChain = getParentChain(rules, id);
  return findLast(parentChain, (c) => c.stereotype === Stereotype.RuleTemplate)?.id;
};

export const getParentGroup = (rules: IRuleComponent | null | undefined, id: string): IRuleComponent | undefined => {
  const parentChain = getParentChain(rules, id);
  return findLast(parentChain, (c) => c.stereotype === Stereotype.RuleGroup);
};

export const getParentContainerId = (rules: IRuleComponent | null | undefined, id: string): string | undefined => {
  const parentChain = getParentChain(rules, id);
  return findLast(parentChain, (c) => c.stereotype === Stereotype.RuleGroup || c.stereotype === Stereotype.RuleSection)
    ?.id;
};

export const getChildrenByStereotype = (
  rules: IRuleComponent | null | undefined,
  id: string | undefined | null,
  stereotypes: Stereotype[],
) => {
  const rule = getByRuleId(rules, id);
  if (!rule || !id) {
    return [];
  }

  const bfsFront = [...rule.children];
  const result: IRuleComponent[] = [];
  while (bfsFront.length > 0) {
    const front = bfsFront.shift();
    if (front) {
      if (stereotypes.indexOf(front.stereotype) >= 0) {
        result.push(front);
      } else {
        bfsFront.unshift(...front.children);
      }
    }
  }
  return result;
};

export const getChildrenIdsByStereotype = (
  rules: IRuleComponent | null | undefined,
  id: string | undefined | null,
  stereotypes: Stereotype[],
) => getChildrenByStereotype(rules, id, stereotypes).map((r) => r.id);

export const getHighligthedChildrenIds = (rules: IRuleComponent | null | undefined, id: string | undefined | null) =>
  getChildrenIdsByStereotype(rules, id, [Stereotype.RuleComponent, Stereotype.RuleTemplate, Stereotype.RuleGroup]);

export const getAllShareRuleIds = (rules: IRuleComponent | null | undefined) => {
  if (!rules || !rules.sharedComponents) {
    return [];
  }
  const sharedComponentIds = rules.sharedComponents.map((sc) => sc.id);
  rules.children.forEach((c) => sharedComponentIds.push(...getAllShareRuleIds(c)));
  return sharedComponentIds;
};

export const getPreviewParentId = (rules: IRuleComponent | null | undefined, id: string | undefined | null) => {
  if (!id) {
    return null;
  }
  const chain = [...(getParentChain(rules, id) ?? []), getByRuleId(rules, id)];

  // Return the parent template/group/section
  return findLast(chain, (c) =>
    includes([Stereotype.RuleTemplate, Stereotype.RuleGroup, Stereotype.RuleSection], c?.stereotype),
  )?.id;
};
export const ruleHTMLId = (id?: string) => `re-rule-${id}`;

export const isChildOf = (
  rules: IRuleComponent | null | undefined,
  parentId: string | undefined | null,
  childId: string | undefined | null,
) => {
  if (!parentId || !childId || !rules) {
    return false;
  }
  const parent = getByRuleId(rules, parentId);
  if (!parent) {
    return false;
  }

  const bfsFront = [...parent.children];
  while (bfsFront.length > 0) {
    const front = bfsFront.shift();
    if (front?.id === childId) {
      return true;
    }
    bfsFront.unshift(...(front?.children ?? []));
  }
  return false;
};

const withoutRule = (root: IRuleComponent, id: string): IRuleComponent => {
  return {
    ...root,
    children: root.children.filter((r) => r.id !== id).map((r) => withoutRule(r, id)),
  };
};

export const applyMoveOptimistically = (root: IRuleComponent, move: IRulesMove) => {
  const moved = getByRuleId(root, move.movedRuleId);
  if (!moved) {
    return null;
  }
  const result = move.copy ? { ...root, children: [...root.children] } : withoutRule(root, move.movedRuleId);
  const destination = getByRuleId(result, move.movedToRuleId);
  if (destination) {
    destination.children.splice(move.position, 0, moved);
  }
  return result;
};

const countIds = (root: IRuleComponent | null | undefined, result: any) => {
  if (root) {
    result[root.id] = result[root.id] ? result[root.id] + 1 : 1;
    for (const c of root.children) {
      countIds(c, result);
    }
    for (const sharedRule of root.sharedComponents || []) {
      countIds(sharedRule, result);
    }
  }
};

export const duplicateIds = (root: IRuleComponent) => {
  const counts: any = {};
  countIds(root, counts);
  return Object.keys(counts).filter((k) => counts[k] > 1);
};

export const getRuleUsageChainRecursive = (
  rootRules: IRuleComponent,
  ruleObj: IRuleComponent,
  ruleId: string,
  successChainOriginal: Array<any> = [],
): any => {
  const successChain = JSON.parse(JSON.stringify(successChainOriginal));
  // only add rules with the following stereotypes after the first level...
  if ([Stereotype.RuleSection, Stereotype.RuleGroup, Stereotype.RuleComponent].includes(ruleObj.stereotype))
    successChain.unshift({
      id: ruleObj.id,
      name: getLabel(ruleObj) || ruleObj.type,
    });
  let found = false;
  let successChains = [];
  if (ruleObj.childrenReferences) {
    for (const ref of ruleObj.childrenReferences) {
      if (ref === ruleId) {
        found = true;
        const rule = getByRuleId(rootRules, ref);
        if (rule) {
          successChain.unshift({
            id: ref,
            name: getLabel(rule!) || rule.type,
          });
          successChains.push(successChain);
        }
      }
    }
  }
  if (found) {
    return successChains;
  } else {
    for (const child of ruleObj.children) {
      const result = getRuleUsageChainRecursive(rootRules, child, ruleId, successChain);
      if (result) {
        successChains = successChains.concat(result);
      }
    }
  }
  if (successChains.length > 0) {
    return successChains;
  } else {
    return false;
  }
};

export const ruleLocationText = (chain: IUsageChain[]) => {
  return chain.reverse().reduce((prev, curr, i) => `${prev} ${i === 0 ? curr.name : `> ${curr.name}`}`, ``);
};

export const getRuleSectionIds = (rootRules: IRuleComponent) => {
  return (
    rootRules?.children
      .filter((rc: IRuleComponent) => rc.stereotype === Stereotype.RuleSection)
      .map((rc: IRuleComponent) => rc.id) ?? []
  );
};
