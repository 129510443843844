import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { brandBlue, brandGrey, brandLightGrey, brandMedGrey1 } from "./colors";

export const defaultTheme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "light",
      primary: {
        main: brandBlue.toString(),
        // light: brandRed.toString(),
        // dark: brandRed.toString(),
      },
      secondary: {
        main: "#4a4a4a",
        // light: brandBlack.toString(),
        // dark: brandBlack.toString(),
      },
      grey: { 900: brandGrey.toString(), 500: brandMedGrey1.toString(), 100: brandLightGrey.toString() },
    },
    typography: {
      fontFamily: "Source Sans Pro",
      h6: {
        fontWeight: 600,
      },
    },
    overrides: {
      MuiMenu: {
        paper: {
          width: "253px",
        },
      },

      MuiSvgIcon: {
        root: {
          fill: brandGrey.toString(),
        },
      },
      MuiButton: {
        text: {
          textTransform: "none",
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 0,
        },
      },
      ...{
        MuiAutocomplete: {
          inputRoot: {
            '&&[class*="MuiOutlinedInput-root"]': {
              padding: 0,
            },
          },
        } as any,
      },
      MuiAccordionSummary: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          // border: "1px solid green",
        },
        content: {
          paddingTop: 0,
          paddingBottom: 0,
          margin: 0,
          // border: "1px solid red",
        },
      },
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: brandBlue.toString(),
          },
          "&&& $input": {
            padding: "10px 8px !important",
          },
          // Use as required (pasted here to save time searching up syntax)
          // "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          //   borderColor: brandMedGrey1.toString(),
          //   // Reset on touch devices, it doesn't add specificity
          //   "@media (hover: none)": {
          //     borderColor: brandBlue.toString(),
          //   },
          // },
          // "&$focused $notchedOutline": {
          //   borderColor: brandMedGrey1.toString(),
          //   borderWidth: 1,
          // },
        },
      },

      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      MuiGrid: {
        root: {
          width: "100%",
          margin: "0px !important",
        },
      },
    },
  }),
);
