import React, { useCallback } from "react";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import { PaddedLabel, SelectMenuProps } from "../common/forms";
import { RuleFormActions } from "../common/RuleFormActions";
import { IRuleComponent, Operator, IState, Stereotype, SubjectRuleSectionType, RuleComponentType } from "store/types";
import { EditorGrid } from "../common/ruleComponent";
import { useSelector } from "react-redux";
import { commonStyles } from "theme/styles";
import { AutocompleteMultiSelect } from "components/autocomplete/AutocompleteMultiSelect";
import { CommonTag, getTagLabel } from "lib/tags";
import uniq from "lodash/uniq";
import { featureToggles } from "config/featureToggles";
import { LabelWithHint } from "components/help-hint/HelpHint";
import { IGroupEditorFormState } from "./RuleGroupEditor";
import { RulePreview } from "../common/rulePreview";
import { PreviewRequest } from "../common/types";
import { getLabel } from "lib/parameters";

export interface IProps {
  rule?: IRuleComponent | null;
  parentSection?: IRuleComponent | null;
  level: number;
}

export const RuleGroupEditorFields = (propss: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const { rule, parentSection, level } = propss;

  const parentSectionType = parentSection?.type;
  const useNonAllowedDefaults =
    SubjectRuleSectionType.NonAllowedSubjects === parentSectionType && !!featureToggles.nonAllowedDefaults;
  const templates = useSelector((state: IState) => state.templates);
  const groupTemplate = templates.find((t) => t.stereotype === Stereotype.RuleGroup);
  const groupTemplateNames = groupTemplate?.formats || [];
  const formats = groupTemplate?.formats || [];

  const formik = useFormikContext<IGroupEditorFormState>();
  const samples = formats.find((f) => f.name === formik.values.selectedOrDefaultFormat)?.samples || [];
  const disableSubmit = !formik.isValid || !formik.dirty;

  const onTagsChange = useCallback(
    (newTagNames: string[]) => {
      formik.setFieldValue("tags", newTagNames);
    },
    [formik],
  );

  const previewReq: PreviewRequest = {
    ruleProps: {
      stereotype: Stereotype.RuleGroup,
      ruleType: RuleComponentType.LogicConstruct,
      parameters: formik.values,
    },
    isValid: formik.isValid,
  };

  let availableTags = featureToggles.exclusiveGroupTag
    ? uniq([
        CommonTag.HANDBOOK_ONLY,
        CommonTag.CPT_ONLY,
        CommonTag.EXCLUSIVE_GROUP,
        CommonTag.HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK,
        ...formik.initialValues.tags,
      ])
    : uniq([
        CommonTag.HANDBOOK_ONLY,
        CommonTag.CPT_ONLY,
        CommonTag.HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK,
        ...formik.initialValues.tags,
      ]);

  // Only allow HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK in the top level couse rules section, for the first
  if (
    level !== 1 ||
    !parentSection ||
    !getLabel(parentSection)?.match(/course\s+rule/gi) ||
    parentSection.children.findIndex((c) => c.id === rule?.id) !== 0
  ) {
    availableTags = availableTags.filter((t) => t !== CommonTag.HIDE_TOTAL_COURSE_POINTS_IN_HANDBOOK);
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <EditorGrid container spacing={2}>
        <Grid item xs={12} sm={7} md={8}>
          <PaddedLabel htmlFor="label-text" sx={styles.label}>
            <LabelWithHint
              label={"Label"}
              helpText={
                "Use this label to guide students with assisting text or headings. The Handbook will only publish this field depending on the Published Format selected, but My Course Planner will always publish this field so ensure it’s meaningful."
              }
            />
          </PaddedLabel>
          <FormControl fullWidth={true} variant="outlined">
            <Field
              component={TextField}
              name="label"
              label=""
              fullWidth={true}
              variant="outlined"
              inputProps={{
                "aria-label": "Label",
                id: "label-text",
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} md={4}>
          <PaddedLabel htmlFor="group-operator-select" sx={styles.label}>
            <LabelWithHint
              label={"Operand *"}
              helpText={"Select how the rules within this rule group are all linked."}
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="operator"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps}
              inputProps={{ id: "group-operator-select" }}
              disabled={useNonAllowedDefaults}
            >
              {Object.values(Operator).map(
                (o) =>
                  featureToggles.hideOperatorXOR &&
                  !featureToggles.hideOperatorNOT &&
                  o !== "XOR" && (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ),
              )}
              {Object.values(Operator).map(
                (o) =>
                  !featureToggles.hideOperatorXOR &&
                  featureToggles.hideOperatorNOT &&
                  o !== "NOT" && (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ),
              )}
              {Object.values(Operator).map(
                (o) =>
                  featureToggles.hideOperatorNOT &&
                  featureToggles.hideOperatorXOR &&
                  o !== "XOR" &&
                  o !== "NOT" && (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ),
              )}
              {Object.values(Operator).map(
                (o) =>
                  !featureToggles.hideOperatorXOR &&
                  !featureToggles.hideOperatorNOT && (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ),
              )}
            </Field>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedLabel htmlFor="group-format-select" sx={styles.label}>
            <LabelWithHint
              label={"Published format"}
              helpText={
                "Choose the way the rules within this group are presented in CAPS, the Handbook and My Course Planner. Your formatting selection also determines whether to display the label and how."
              }
            />
          </PaddedLabel>
          <FormControl fullWidth={true}>
            <Field
              component={Select}
              name="selectedOrDefaultFormat"
              fullWidth={true}
              variant="outlined"
              MenuProps={SelectMenuProps}
              inputProps={{ id: "group-format-select" }}
            >
              {groupTemplateNames.map((o) => (
                <MenuItem key={o.name} value={o.name}>
                  {o.name}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>

        {(samples.length > 0 || !!featureToggles.previewRuleBeforeSave) && (
          <Grid item xs={12}>
            <RulePreview
              samples={samples}
              previewReq={previewReq}
              selectedOrDefaultFormat={formik.values.selectedOrDefaultFormat}
              stereotype={Stereotype.RuleGroup}
              ruleType={RuleComponentType.LogicConstruct}
              ruleId={rule?.id}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <PaddedLabel htmlFor="group-tags-select" sx={styles.label}>
            Tags
          </PaddedLabel>
          <AutocompleteMultiSelect
            id="group-tags-select"
            options={availableTags}
            formValue={formik.values.tags}
            formatLabel={getTagLabel}
            onChange={onTagsChange}
            placeholder="tags"
          />
        </Grid>

        <Grid item xs={12}>
          <RuleFormActions ruleId={rule?.id} submitDisabled={disableSubmit} submitting={formik.isSubmitting} />
        </Grid>
      </EditorGrid>
    </form>
  );
};
