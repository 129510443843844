import React, { useCallback, useMemo, useState } from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "formik-mui";
import { FormControl, Grid, Button, useTheme } from "@mui/material";
import { PaddedLabel, ScopeContainer } from "../../common/forms";
import { RuleFormActions } from "../../common/RuleFormActions";
import { addSharedSubjectSet, IRulesUpdateObj, updateCAPSObject } from "actions/capsObject";
import { dynamicSetQueryAsFlatObj, getLabel } from "lib/parameters";
import { validateValuePresence } from "lib/validation";
import { commonStyles } from "theme/styles";
import { IRuleComponent, SubjectSetType, RuleComponentType, IState, ICourse } from "store/types";
import AddIcon from "@mui/icons-material/Add";
import { DSSSearchModal } from "./DSSSearchModal";
import { DynamicSubjectSetQuery } from "../../component/DynamicSubjectSetQuery";
import isArray from "lodash/isArray";
import { BannerComponent } from "containers/rules/common/banner";
import { SharedSubjectSetInlineWarning } from "../SharedSubjectSetWarningModal";
import { LabelWithHint } from "components/help-hint/HelpHint";

interface IFormState {
  label: string;
  query: any;
}

const validateFormState = (values: IFormState) => {
  const errors: any = {};
  validateValuePresence(errors, values, "label");
  const anyArrayValue = Object.keys(values.query).find((k) => isArray(values.query[k]) && values.query[k].length > 0);
  // debugger;
  if (!anyArrayValue && values.query.breadthCourseCode === undefined) {
    errors["query"] = "Query may not be empty";
  }

  return errors;
};

interface IProps {
  rule?: IRuleComponent;
  ruleBeingUsed?: (ruleId: string, showDialog?: boolean) => boolean;
}

export const DSSEditor = (props: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);
  const dispatch = useDispatch();
  const [finderOpen, setFinderOpen] = useState(false);
  const flipFinder = useCallback(() => setFinderOpen((old) => !old), []);
  const { rule, ruleBeingUsed } = props;
  const subjectSetUsageChains = useSelector((s: IState) => s.editorState.subjectSetUsageChains);
  const allComponentReferences = useSelector((s: IState) => (s.capsObject as ICourse)?.componentReferences ?? []);
  const componentReferences = useMemo(
    () => allComponentReferences.filter((cr) => cr.subjectSetId === rule?.id),
    [allComponentReferences, rule?.id],
  );
  const showWarning = (!!subjectSetUsageChains && subjectSetUsageChains.length > 0) || componentReferences.length > 0;

  const initialQuery = dynamicSetQueryAsFlatObj(rule);
  const initialState: IFormState = {
    label: (rule && getLabel(rule)) || "",
    query: initialQuery,
  };

  const callOnSubmit = useCallback(
    (values: IFormState, _helpers: FormikHelpers<IFormState>) => {
      if (!rule) {
        dispatch(addSharedSubjectSet({ label: values.label, ...values.query, ssType: SubjectSetType.Dynamic }));
      } else {
        const update: IRulesUpdateObj = {
          parameters: { label: values.label, ...values.query, ssType: SubjectSetType.Dynamic },
          ruleType: RuleComponentType.SubjectSet,
          ruleId: rule.id,
        };
        if (!update.parameters.breadthCourseCode) {
          delete update.parameters.breadthCourseCode;
        }
        dispatch(updateCAPSObject(update));
      }
    },
    [dispatch, rule],
  );

  return (
    <Formik initialValues={initialState} validate={validateFormState} onSubmit={callOnSubmit}>
      {(props) => {
        const disableSubmit = !props.isValid || !props.dirty;
        const query = props.values.query;
        const queryIsEmpty = Object.keys(query).length === 0;
        const changeQuery = (newQuery: any) => {
          if (initialQuery !== newQuery) {
            props.setFieldValue("query", newQuery);
          }
          flipFinder();
        };

        return (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              {showWarning && (
                <Grid item xs={12}>
                  <BannerComponent
                    heading={"Warning!"}
                    body={
                      <SharedSubjectSetInlineWarning
                        subjectSetUsageChains={subjectSetUsageChains ?? []}
                        componentReferences={componentReferences}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <PaddedLabel htmlFor="sset-label" sx={styles.label}>
                  <LabelWithHint
                    label="Subject List Label"
                    helpText="This label can be referenced in the Published Formats of rules. Make sure you consider how the list will be used when creating a label."
                  />
                </PaddedLabel>
                <FormControl fullWidth={true} variant="outlined">
                  <Field
                    component={TextField}
                    name="label"
                    label=""
                    fullWidth={true}
                    variant="outlined"
                    inputProps={{
                      "aria-label": "Subject Set Label",
                      id: "sset-label",
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <ScopeContainer>
                  <strong>Subject scope</strong>

                  <Button variant="text" color="primary" startIcon={<AddIcon />} onClick={flipFinder}>
                    {queryIsEmpty ? "Add" : "Edit"} scope
                  </Button>
                </ScopeContainer>
                <DynamicSubjectSetQuery query={query} />
                {finderOpen && <DSSSearchModal query={query} close={flipFinder} changeQuery={changeQuery} />}
              </Grid>

              <Grid item xs={12}>
                <RuleFormActions
                  ruleId={rule?.id}
                  submitDisabled={disableSubmit}
                  submitting={props.isSubmitting}
                  ruleBeingUsed={ruleBeingUsed}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
