import { featureToggles } from "config/featureToggles";
import { get } from "lib/fetch";
import { formatLevel } from "lib/level";
import pickBy from "lodash/pickBy";
import store from "../store";
export const EXECUTE_SUBJECT_QUERY = "subjectQueryResult/EXECUTE_SUBJECT_QUERY";

export const executeSubjectQuery = (query: any) => {
  const {
    tokenValidated,
    capsObject: { effectiveYear },
  } = store.getState();
  const dontIgnoreKeys = featureToggles.search ? ["discontinue"] : []; // ignore discontinue so that we can search for "both discontinued and not" subjects where value is ""
  const filteredQuery: any = {
    ...pickBy(query, (v, k) => String(v).trim() || v.length > 0 || dontIgnoreKeys.includes(k)),
    searchYear: parseInt(effectiveYear) || new Date().getFullYear(),
  };
  const allKeys = Object.keys(filteredQuery);
  const ignoreKeys = ["searchYear"];
  const optimisedKeys = allKeys.filter((k) => !ignoreKeys.includes(k));

  const now = Date.now();

  // Don't search if there's no point
  if (
    optimisedKeys.length === 0 ||
    filteredQuery.breadthCourseCode ||
    (optimisedKeys.length === 1 && optimisedKeys[0] === "breadthCourseCode")
  ) {
    return {
      type: EXECUTE_SUBJECT_QUERY,
      payload: Promise.resolve({
        searchTimestamp: now,
        result: [],
      }),
    };
  }

  // Ensure the query is expected by the search - API expects readable values :(
  if (filteredQuery.level) {
    filteredQuery.level = filteredQuery.level.map(formatLevel);
  }

  const queryURLComponent = allKeys
    .filter((k) => k !== "breadthCourseCode" || filteredQuery[k])
    .map((k) => `${k}=${encodeURIComponent(JSON.stringify(filteredQuery[k]))}`)
    .join("&");

  const urlQueryParams = `sessionId=${tokenValidated.sessionId}&${queryURLComponent}`;
  return {
    type: EXECUTE_SUBJECT_QUERY,
    payload: get(`/v1/subject/?${urlQueryParams}`).then((result) => ({ result, searchTimestamp: now })),
  };
};
